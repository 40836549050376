import { get, post } from "@/services/api";
import { getLocalStorage } from "@/services/utils";
import { getSessionsByPractitionerRole } from "../Appointments/appointmentsModule";

export const getGroups = options => {
  return async (dispatch, getState) => {
    const state = getState();
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let businessFunction = state.user.selectedUserRole?.businessFunction;
    let orgId = state.user.selectedUserRole?.organizationId;
    let url = `practitioner-role/groups/find-all-by-org/${orgId}`;
    if (businessFunction === "Clinical Care") {
      url = `practitioner-role/groups/find-all-by-practitioner-role/${practitionerRoleId}`;
    }
    let data = [];
    dispatch({ type: "TOGGLE_GROUPS_LOADER", show: true });
    try {
      let response = await get(url);
      data = response.data?.length > 0 ? response.data : [];
    } catch (error) {
      console.log(error);
    }
    dispatch({ type: "ADD_GROUPS_DATA", data });
    dispatch({ type: "TOGGLE_GROUPS_LOADER", show: false });
  };
};

export const addGroup = options => {
  return async (dispatch, getState) => {
    const state = getState();
    const organizationId = state.user.selectedUserRole?.organizationId;
    let payload = {
      organizationId,
      ...options.payload,
    };
    let url = "practitioner-role/groups/create";
    let groupId = null;
    try {
      let response = await post(url, payload);
      if (response?.data) {
        // dispatch({ type: "ADD_GROUP", data: response.data });
        groupId = response.data.id;
      }
    } catch (error) {}
    return groupId;
  };
};

export const assignPractitioners = async options => {
  let groupId = options.groupId;
  let practitioners = options.practitioners;
  let practitionerRoles = [];
  practitioners?.forEach(i => {
    practitionerRoles = [...practitionerRoles, ...i.roles];
  });
  let url = "practitioner-role/groups/assign-practitioner-role";
  let payloads = practitionerRoles?.map(role => {
    return {
      groupId,
      practitionerRoleId: role.id,
    };
  });
  try {
    let promises = payloads.map(payload => post(url, payload));
    let responses = await Promise.allSettled(promises);
    console.log("practitioners responses", responses);
  } catch (error) {
    console.log("practitioners error", error);
  }
};

export const assignPatients = async options => {
  let groupId = options.groupId;
  let patients = options.patients;
  let url = "practitioner-role/groups/create-group-patient-membership";
  let payloads = patients?.map(patient => {
    return {
      groupId,
      patientId: patient.patientId,
    };
  });
  try {
    let promises = payloads.map(payload => post(url, payload));
    let responses = await Promise.allSettled(promises);
    console.log("patients responses", responses);
  } catch (error) {
    console.log("practitioners error", error);
  }
};

const initState = {
  groups: {
    data: [],
    loading: false,
  },
};

const ACTION_HANDLERS = {
  ["TOGGLE_GROUPS_LOADER"]: (state, action) => {
    return {
      ...state,
      groups: {
        ...state.groups,
        loading: action.show,
      },
    };
  },
  ["ADD_GROUPS_DATA"]: (state, action) => {
    return {
      ...state,
      groups: {
        ...state.groups,
        data: action.data,
      },
    };
  },
  ["ADD_GROUP"]: (state, action) => {
    return {
      ...state,
      groups: {
        ...state.groups,
        data: state.groups.data?.length > 0 ? [action.data, ...state.groups.data] : [action.data],
      },
    };
  },
};

export default function groups(state = initState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
