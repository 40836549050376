import Profile from "./Profile";
import { connect } from "react-redux";
import { signout } from "@/store/modules/userModule";
import { selectUserRole } from "@/store/modules/userModule";

const mapStateToProps = state => {
  return {
    userData: state.user.userData,
    selectedUserRole: state.user.selectedUserRole,
    userRoles: state.user.userRoles,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signout: options => dispatch(signout(options)),
    selectUserRole: selectedRole => dispatch(selectUserRole(selectedRole)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
