import React from "react";
import { RichTextEditor } from "@/components";
import "./DSM5.scss";

function DSM5(props) {
  const { data } = props;
  return (
    <div className="dsm5-widget">
      <div className="widget-header">
        <div className="widget-title">Diagnostic Impressions</div>
      </div>
      <div className="risks-list">
        {!!data && (
          <RichTextEditor
            value={data}
            readOnly={true}
          />
        )}
      </div>
      {/* <div className="dsm-diagnosis-container">
        {data?.syndromes?.length > 0 && (
          <div className={`diagnosis-syndrome`}>
            <div className="syndrome-list">
              {data.syndromes.map((syndrome, index) => {
                return (
                  <div
                    className="syndrome-pill"
                    key={index}
                  >
                    {syndrome}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="diagnosis-desc">{data?.description}</div>
      </div> */}
      {/* {data?.dsm5Predictions?.length > 0 && (
        <div className="symdrome-list-container">
          <div className="syndrome-list-label">DSM-5 Diagnosis over time</div>
          <div className="syndrome-list">
            {data?.dsm5Predictions.map((prediction, index) => {
              return (
                <div
                  className="syndrome-pill"
                  key={index}
                >
                  {prediction}
                </div>
              );
            })}
          </div>
        </div>
      )} */}
    </div>
  );
}

export default DSM5;
