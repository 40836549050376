import WaitingStatus from "./WaitingStatus";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    patientStatus: state.appointments.sessions.patientStatus,
    groupStatus: state.appointments.sessions.groupStatus,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitingStatus);
