import ShareMeetingLink from "./ShareMeetingLink";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareMeetingLink);
