import React, { useState, useEffect } from "react";
import { Form, Modal, Dropdown } from "react-bootstrap";
import { PrimaryButton } from "@/components";
import { businessFunctions, roleBusinessFunctionMapping } from "@/services/dummyData";
import "./AddUserModal.scss";

function AddUserModal(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [behaviorHealthRole, setBehaviorHealthRole] = useState(null);
  const [selectedBusinessFunction, setSelectedBusinessFunction] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
  }, [props.modalOpen]);
  const addUser = async () => {
    if (firstName && email && behaviorHealthRole?.role && !loading) {
      setLoading(true);
      await props.addUser({
        firstName,
        lastName,
        email,
        behaviorHealthRole: behaviorHealthRole.role,
      });
      setLoading(false);
      props.toggleModal(false);
    }
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="add-user-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-user-modal-body">
        <Form className="add-user-form custom-form">
          <div className="input-row">
            <Form.Group
              className="custom-input-field"
              controlId="user-first-name-input"
            >
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Jane"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                disabled={!!loading}
              />
            </Form.Group>
            <Form.Group
              className="custom-input-field"
              controlId="user-last-name-input"
            >
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Smith"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                disabled={!!loading}
              />
            </Form.Group>
          </div>
          <Form.Group
            className="custom-input-field"
            controlId="user-email-input"
          >
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled={!!loading}
            />
          </Form.Group>
        </Form>
        <div className="custom-input-field dropdown-input-field">
          <Form.Label className="select-bf-label">Select Business Function:</Form.Label>
          <Dropdown className="select-bf-dropdown">
            <Dropdown.Toggle>
              {!!selectedBusinessFunction
                ? selectedBusinessFunction.businessFunction
                : "Select Business Function"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {businessFunctions.length > 0 &&
                businessFunctions.map(bf => {
                  return (
                    <Dropdown.Item
                      as={"button"}
                      onClick={() => setSelectedBusinessFunction(bf)}
                      key={bf.businessFunction}
                    >
                      {bf.businessFunction}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {!!selectedBusinessFunction && (
          <div className="custom-input-field dropdown-input-field">
            <Form.Label className="select-bf-label">Select Role:</Form.Label>
            <Dropdown className="select-bf-dropdown">
              <Dropdown.Toggle>
                {!!behaviorHealthRole ? behaviorHealthRole.displayName : "Select Role"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {selectedBusinessFunction.roles.length > 0 &&
                  selectedBusinessFunction.roles.map(role => {
                    return (
                      <Dropdown.Item
                        as={"button"}
                        onClick={() => setBehaviorHealthRole(role)}
                        key={role.role}
                      >
                        {role.displayName}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          onClick={addUser}
          disabled={!firstName || !behaviorHealthRole?.role || !email || loading}
        >
          Add User
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

export default AddUserModal;
