import React, { useEffect } from "react";
import { Avatar, SecondaryButtonWithIcon } from "@/components";
import { deleteCookie } from "@/services/utils";
import UserIcon from "@/assets/facilitator-user.png";
import SignOutIcon from "@/assets/sign-out.svg";
import { roleBusinessFunctionMapping } from "@/services/dummyData";
import "./Profile.scss";

function Profile(props) {
  const { userData } = props;
  const signout = () => {
    props.signout();
  };
  const businessFunction = props.selectedUserRole?.behaviorHealthRole
    ? roleBusinessFunctionMapping(props.selectedUserRole.behaviorHealthRole)
    : "";
  useEffect(() => {
    document.title = "Profile";
  }, []);
  return (
    <div className="profile-page-container">
      <div className="profile-page">
        <div className="profile-page-label">
          Profile
          <div className="org-detail-container">
            <div className="org-detail">
              {props.selectedUserRole?.organizationName} |{" "}
              {businessFunction === "Clinical Care"
                ? "Provider"
                : businessFunction === "Care Operations"
                ? "Care Coordinator"
                : props.selectedUserRole?.behaviorHealthRole}
            </div>
            {!(props.userRoles?.length === 1 && props.userRoles[0].roles?.length === 1) && (
              <div
                className="change-org-link"
                onClick={() => props.selectUserRole(null)}
              >
                Change Org/Role
              </div>
            )}
          </div>
        </div>
        <div className="profile-grid">
          <div className="profile-left">
            {/* <img
              src={UserIcon}
              alt=""
              className="facilitator-profile-image"
            /> */}
            <Avatar
              imageSrc={userData?.preSignedUrlForProfileImage}
              name={`${userData?.firstName}${userData?.lastName ? " " : ""}${userData?.lastName}`}
              className="facilitator-profile-image"
            />
          </div>
          <div className="profile-right">
            <div>
              <div className="user-name">
                {userData?.firstName} {userData?.lastName}
              </div>
              <div className="profile-info-container">
                <div className="profile-info">
                  <div className="profile-info-label">Email:</div>
                  <div className="profile-info-value">{userData?.email || "-"}</div>
                </div>
                <div className="profile-info">
                  <div className="profile-info-label">Contact:</div>
                  <div className="profile-info-value">{userData?.phoneNumber || "-"}</div>
                </div>
                <div className="profile-info">
                  <div className="profile-info-label">About:</div>
                  <div className="profile-info-value">{userData?.profileDescription || "-"}</div>
                </div>
              </div>
            </div>
            <div className="signout-container">
              <SecondaryButtonWithIcon
                icon={SignOutIcon}
                onClick={signout}
              >
                Sign Out
              </SecondaryButtonWithIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
