import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import * as Sentry from "@sentry/react";
import "./index.css";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

if (process.env.NODE_ENV !== "development") {
  if (APP_ENV === "PROD") {
    Sentry.init({
      dsn: "https://66965b0bbea279d2d4090b8c3bd463ac@o4507034879066112.ingest.us.sentry.io/4507882854088704",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.httpClientIntegration({
          // This array can contain tuples of `[begin, end]` (both inclusive),
          // single status codes, or a combination of both.
          // default: [[500, 599]]
          failedRequestStatusCodes: [[400, 500, 501, 502, 503]],

          // This array can contain Regexes, strings, or a combination of both.
          // default: [/.*/]
          failedRequestTargets: [
            /^https:\/\/(ehr|app|backend|transcript|insession|dyte-transcripts-ingestion)\.(dev\.|prod\.)?soulsidehealth\.com/,
          ],
        }),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        /^https:\/\/(ehr|app|backend|transcript|insession|dyte-transcripts-ingestion)\.(dev\.|prod\.)?soulsidehealth\.com/,
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>,
);
