import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { RichTextEditor } from "@/components/RichTextEditor";
import { useParams } from "react-router-dom";
import "./Recommendation.scss";
import { PrimaryButton } from "@/components";
import { toast } from "react-toastify";
import { getLocalStorage } from "@/services/utils";
import { getOldSessionTranscriptData } from "@/store/modules/userModule";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import {
  generateTreatmentPlan,
  getProviderSessionIds,
} from "@/Routes/SessionDetails/sessionDetailsModule";
import { Loader } from "@/components";
import TreatmentPlan from "@/Routes/SessionDetails/components/PatientNotes/components/TreatmentPlan";

function Recommendation(props) {
  const { data } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { patientId } = useParams();
  const savedtreatmentData = getLocalStorage("saved-treatment-plans")?.[patientId] || null;
  const [treatmentData, setTreatmentData] = useState(savedtreatmentData?.data || null);
  const [lastUpdatedOn, setLastUpdatedOn] = useState(
    savedtreatmentData?.lastUpdatedOn
      ? moment(savedtreatmentData.lastUpdatedOn)
          .tz(props.preferredTimezone?.name || "America/Los_Angeles")
          .format("MMM DD, YYYY")
      : moment()
          .tz(props.preferredTimezone?.name || "America/Los_Angeles")
          .format("MMM DD, YYYY")
  );
  useEffect(() => {
    if (props.sessions?.length > 0 && !treatmentData) {
      getTreatmentData();
    }
  }, [props.sessions]);
  const getTreatmentData = async () => {
    let sessions =
      props.sessions?.filter(session => {
        return session.sessionCategory === "INDIVIDUAL" && session.patientId === patientId;
      }) || [];
    sessions = sessions.sort(
      (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
    );
    if (sessions?.length > 0) {
      setLoading(true);
      let transcriptData = [];
      let selectedSession = null;
      let payload = {};
      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        // Shifted to dyte for in person sessions from 9th September 2024
        let sessionDate = new Date(session.startTime);
        let updateDate = new Date("2024-09-09T06:00:00.000+05:30");
        let inPersonOldSession = sessionDate < updateDate && session.modeOfDelivery === "IN_PERSON";
        if (inPersonOldSession) {
          transcriptData = await getOldSessionTranscriptData(session);
        } else {
          transcriptData = await dispatch(
            getProviderSessionIds({
              sessionCategory: session?.sessionCategory === "GROUP" ? "group" : "individual",
              sessionId: session.id,
              triggerTime: new Date(),
              fromTasks: true,
            })
          );
        }
        if (transcriptData?.length > 0) {
          selectedSession = session;
          let transcriptText = "";
          transcriptData.forEach(transcript => {
            let memberName = transcript.memberName;
            transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
          });
          payload = {
            transcript: transcriptText,
          };
          break;
        }
      }
      if (selectedSession) {
        await dispatch(generateTreatmentPlan({ session: selectedSession, patientId, payload }));
        const savedtreatmentData = getLocalStorage("saved-treatment-plans")?.[patientId] || null;
        if (savedtreatmentData) {
          setTreatmentData(savedtreatmentData?.data || null);
          setLastUpdatedOn(
            savedtreatmentData?.lastUpdatedOn
              ? moment(savedtreatmentData.lastUpdatedOn)
                  .tz(props.preferredTimezone?.name || "America/Los_Angeles")
                  .format("MMM DD, YYYY")
              : moment()
                  .tz(props.preferredTimezone?.name || "America/Los_Angeles")
                  .format("MMM DD, YYYY")
          );
        }
      }
      setLoading(false);
    }

    setLoading(false);
  };
  return (
    <div className="recommendation-widget">
      <div className="widget-header">
        <div className="widget-title">Treatment Plan</div>
        <div className="last-updated">Last upated on: {lastUpdatedOn}</div>
      </div>
      <Loader loading={loading}>
        {treatmentData ? (
          <TreatmentPlan
            fromPatientDetails={true}
            patientId={patientId}
          />
        ) : (
          <div>No treatment plan found</div>
        )}
      </Loader>
    </div>
  );
}

export default Recommendation;
