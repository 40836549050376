import { get } from "@/services/api";
import { addLocalStorage, getLocalStorage, deleteCookie } from "@/services/utils";
import { roleBusinessFunctionMapping } from "@/services/dummyData";
import { getProviderSessionIds } from "@/Routes/SessionDetails/sessionDetailsModule";
import Papa from "papaparse";
import moment from "moment-timezone";

export const getUserData = options => {
  return async dispatch => {
    let url = "practitioner-role/profile/info";
    dispatch({ type: "TOGGLE_USER_LOADING", show: true });
    try {
      let response = await get(url);
      if (response?.data) {
        let data = response.data;
        dispatch({ type: "ADD_USER_DATA", data });
      }
      dispatch({ type: "TOGGLE_USER_LOADING", show: false });
      if (options?.cb) {
        options.cb();
      }
    } catch (error) {
      dispatch({ type: "TOGGLE_USER_LOADING", show: false });
    }
    await dispatch(getPractiotionerAssignedRoles());
  };
};

export const signout = () => {
  return dispatch => {
    deleteCookie("authtoken");
    deleteCookie("soulside-email");
    dispatch(selectUserRole(null));
    window.location.href = window.location.origin + "/login";
  };
};

export const getPractiotionerAssignedRoles = options => {
  return async dispatch => {
    let url = "practitioner-role/find-all-practitioner-roles";
    try {
      let response = await get(url);
      if (response?.data) {
        let data = response.data;
        data = data.filter(i => !!i.active);
        const groupedData = data.reduce((acc, item) => {
          const {
            organizationId,
            organizationName,
            id,
            practitionerId,
            practitionerFirstName,
            practitionerLastName,
            practitionerEmail,
            behaviorHealthRole,
            createdAt,
          } = item;

          if (!acc[organizationId]) {
            acc[organizationId] = {
              organizationId,
              organizationName,
              roles: [],
            };
          }

          acc[organizationId].roles.push({
            ...item,
          });

          return acc;
        }, {});
        const newData = Object.values(groupedData);
        dispatch({ type: "ADD_USER_ROLES_DATA", data: newData });
        if (newData.length === 1 && newData[0].roles.length === 1) {
          dispatch(selectUserRole(newData[0].roles[0], true));
        }
      }
    } catch (error) {}
  };
};

export const getSelectedUserRoleFromLocal = () => {
  let selectedUserRole = getLocalStorage("selected-practitioner-role");
  return selectedUserRole || null;
};

export const selectUserRole = (selectedRole, dontRefresh = false) => {
  return dispatch => {
    let role = null;
    if (selectedRole) {
      role = {
        ...selectedRole,
        businessFunction: roleBusinessFunctionMapping(selectedRole?.behaviorHealthRole),
      };
    }
    dispatch({ type: "SELECT_USER_ROLE", data: role });
    addLocalStorage("selected-practitioner-role", role);
    if (!!role && !dontRefresh) {
      window.location.href = window.location.href;
    }
  };
};

const timeZoneAbbrMapping = {
  "America/Los_Angeles": "PT",
  "America/Phoenix": "MT",
  "America/Chicago": "CT",
  "America/New_York": "ET",
};
export const timezones = moment.tz
  .zonesForCountry("US", true)
  .filter(
    i =>
      i.name === "America/Los_Angeles" ||
      i.name === "America/Phoenix" ||
      i.name === "America/Chicago" ||
      i.name === "America/New_York"
  )
  .map(i => {
    return {
      ...i,
      offset: i.offset / 60,
      abbr: timeZoneAbbrMapping[i.name] || "",
    };
  });

export const getPreferredTimezoneFromLocal = () => {
  let preferredTimezone = getLocalStorage("preferred-timezone");
  return preferredTimezone || timezones.find(i => i.name === "America/Chicago") || null;
};

export const selectPreferredTimezone = timezone => {
  return dispatch => {
    dispatch({ type: "SELECT_PREFERRED_TIMEZONE", data: timezone });
    addLocalStorage("preferred-timezone", timezone);
  };
};

export const getTasksFromLocal = () => {
  let tasks = getLocalStorage("session-tasks");
  return tasks || [];
};

export const addTask = task => {
  return dispatch => {
    dispatch({ type: "ADD_TASK", data: task });
  };
};

export const removeTask = sessionId => {
  return dispatch => {
    dispatch({ type: "REMOVE_TASK", sessionId });
  };
};

export const getAllTaskStatus = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let tasks = state.user.tasks.data;
    if (!options?.dontShowLoader) {
      dispatch({ type: "TOGGLE_TASKS_LOADING", show: true });
    }
    let readyTasks = [];
    for (let i = 0; i < tasks.length; i++) {
      let session = tasks[i];
      let transcriptData = await dispatch(
        getProviderSessionIds({
          sessionCategory: session?.sessionCategory === "GROUP" ? "group" : "individual",
          sessionId: session.id,
          triggerTime: new Date(),
          fromTasks: true,
        })
      );
      let taskReady = transcriptData?.length > 0;
      if (taskReady) {
        readyTasks.push(tasks[i].id);
      }
    }
    dispatch({ type: "ADD_READY_TASKS", data: readyTasks });
    if (!options?.dontShowLoader) {
      dispatch({ type: "TOGGLE_TASKS_LOADING", show: false });
    }
  };
};

export const getOldSessionTranscriptData = async session => {
  let transcriptData = [];
  let sessionId = session?.id;
  let url =
    session?.modeOfDelivery === "IN_PERSON"
      ? `practitioner-role/meeting-session/in-person/${sessionId}/transcript`
      : session?.sessionCategory === "GROUP"
      ? `practitioner-role/meeting-session/group/${sessionId}/transcript`
      : `practitioner-role/meeting-session/individual-session/${sessionId}/transcript`;
  try {
    let response = await get(url);
    if (response?.data) {
      let transcriptUrl = response.data;
      let pdfPromise = new Promise((resolve, reject) => {
        Papa.parse(transcriptUrl, {
          download: true,
          complete: function (results) {
            let data = results?.data || [];
            let transcriptData = data
              .map(i => {
                return {
                  timestamp: i[0] * 1000,
                  memberId: i[3],
                  memberName: i[4],
                  transcriptText: i[5],
                  memberSessionId: i[2],
                };
              })
              .sort((a, b) => a.timestamp - b.timestamp);
            if (transcriptData?.length > 0) {
              resolve(transcriptData);
            } else {
              reject(transcriptData);
            }
          },
          error: function () {
            reject(transcriptData);
          },
        });
      });
      try {
        transcriptData = await pdfPromise;
      } catch (error) {
        console.log(error);
      }
    }
  } catch (error) {}
  return transcriptData;
};

export const hideSideBar = flag => {
  return dispatch => {
    dispatch({ type: "TOGGLE_HIDE_SIDEBAR", flag });
  };
};
