import React from "react";
import Meeting from "./components/Meeting";
import SessionTimeline from "./components/SessionTimeline";
import { useDyteSelector } from "@dytesdk/react-web-core";
import "./VideoWidget.scss";

function VideoWidget(props) {
  const roomState = useDyteSelector(meeting => meeting.self.roomState);
  const hideTimeline = true;
  return (
    <div className="session-video-container">
      <Meeting
        setSelectedMember={props.setSelectedMember}
        meetingToken={props.meetingToken}
        sessionDetailsData={props.sessionDetailsData}
        showMemberNotes={props.showMemberNotes}
        setShowNotes={props.setShowNotes}
        showNotes={props.showNotes}
        addTask={props.addTask}
        preferredTimezone={props.preferredTimezone}
        mapProviderSessionId={props.mapProviderSessionId}
        hideSideBar={props.hideSideBar}
        initMeeting={props.initMeeting}
        meetingSession={props.meetingSession}
        changeMeetingSession={props.changeMeetingSession}
        sessionType={props.sessionType}
        sideBarHidden={props.sideBarHidden}
      />
      {!hideTimeline && roomState && roomState !== "init" && (
        <SessionTimeline sessionDetailsData={props.sessionDetailsData} />
      )}
    </div>
  );
}

export default VideoWidget;
