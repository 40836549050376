import React, { useState } from "react";
import { Avatar, PrimaryButton } from "@/components";
import PredictionCards from "../PredictionCards";
import DischargeSummary from "../DischargeSummary";
import "./Profile.scss";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

function Profile(props) {
  const { data } = props;
  const [dischargeSummaryOpen, setDischargeSummaryOpen] = useState(false);
  return (
    <div className="profile-widget">
      <div className="profile-header">
        <Avatar name={`${data?.firstName} ${data?.lastName}`} />
        <div>
          <div className="member-name widget-title">
            {data?.firstName} {data?.lastName}
          </div>
          <div className="member-id">ID: {data?.id}</div>
        </div>
      </div>
      <div className="member-details">
        {!!data?.age && (
          <div className="member-detail-item">
            <div className="detail-label">Age:</div>
            <div className="detail-value">{data?.age}</div>
          </div>
        )}
        {data?.location && (
          <div className="member-detail-item">
            <div className="detail-label">Location:</div>
            <div className="detail-value">{data?.location}</div>
          </div>
        )}
        {/* <div className="member-detail-item">
          <div className="detail-label">Location:</div>
          <div className="detail-value">
            {data?.patient_city}, {data?.patient_state}
          </div>
        </div> */}
        <div className="member-detail-item">
          <div className="detail-label">Email:</div>
          <div className="detail-value">{data?.email || props.getDummyEmail(data?.firstName)}</div>
        </div>
        <div className="member-detail-item">
          <div className="detail-label">Phone Number:</div>
          <div className="detail-value">{data?.phoneNumber || props.getDummyPhoneNumber()}</div>
        </div>
      </div>
      {!!props.behaviouralHealthPredictionsData && (
        <PredictionCards data={props.behaviouralHealthPredictionsData} />
      )}
      {APP_ENV === "DEV" && (
        <>
          <div className="discharge-message-container">
            <div className="discharge-message">Ready to Discharge?</div>
            <PrimaryButton onClick={() => setDischargeSummaryOpen(true)}>
              Get Discharge Summary
            </PrimaryButton>
          </div>
          <DischargeSummary
            modalOpen={dischargeSummaryOpen}
            toggleModal={setDischargeSummaryOpen}
            dischargeSummary={props.dischargeSummary}
            generateDischargeSummary={props.generateDischargeSummary}
            sessions={props.sessions}
          />
        </>
      )}
    </div>
  );
}

export default Profile;
