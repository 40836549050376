import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { SecondaryButton, Loader, PrimaryButton } from "@/components";
import { addLocalStorage, getLocalStorage } from "@/services/utils";
import "./TreatmentPlan.scss";
import sanitizeHtml from "sanitize-html";
import ContentEditable from "react-contenteditable";
import moment from "moment-timezone";
import { toast } from "react-toastify";

function TreatmentPlan(props) {
  const generateTreatmentPlan = async () => {
    let sessionId = props.sessionId;
    let groupId = props?.groupId;
    let patientId = props?.patientId;
    let memberData = props.sessionData;
    let memberId = memberData?.patientId;
    let memberName = memberData?.patientFirstName;
    let memberEmail = memberData?.patientEmail || "";
    let sessionType = props.sessionType;
    let notesId = props.notesId;
    if (sessionId) {
      let transcriptData = props.transcriptData || [];
      let transcriptText = "";
      transcriptData.forEach(transcript => {
        let memberName = transcript.memberName;
        if (
          ["provider", "patient"].includes(memberName?.toLowerCase()) &&
          props.sessionCategory === "individual"
        ) {
          if (memberName?.toLowerCase() === "provider" && providerName) {
            memberName = providerName;
          }
          if (memberName?.toLowerCase() === "patient" && patientName) {
            memberName = patientName;
          }
        }
        transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
      });
      let payload = {
        transcript: transcriptText,
      };
      await props.generateTreatmentPlan({
        sessionId,
        groupId,
        patientId,
        payload,
        notesId,
        session: props.sessionData,
      });
    }
  };
  const savedtreatmentData = getLocalStorage("saved-treatment-plans")?.[props.patientId] || null;
  const [treatmentData, setTreatmentData] = useState(savedtreatmentData?.data || null);
  useEffect(() => {
    if (!props.loading) {
      const savedtreatmentData =
        getLocalStorage("saved-treatment-plans")?.[props.patientId] || null;
      setTreatmentData(savedtreatmentData?.data || null);
    }
  }, [props.loading]);
  const lastUpdatedOn = savedtreatmentData?.lastUpdatedOn
    ? moment(savedtreatmentData.lastUpdatedOn)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMM DD, YYYY")
    : moment()
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMM DD, YYYY");
  const onTreatmentPlanChange = (
    key,
    index,
    value,
    goalKey = "",
    goalValue = "",
    goalOutcome = ""
  ) => {
    if (key !== "Revised Goals") {
      setTreatmentData(prevData => {
        return {
          ...prevData,
          [key]:
            prevData?.[key]?.length > 0
              ? prevData[key].map((i, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return i;
                })
              : [value],
        };
      });
    } else {
      setTreatmentData(prevData => {
        return {
          ...prevData,
          [key]:
            prevData?.[key]?.length > 0
              ? prevData[key].map((i, idx) => {
                  if (idx === index) {
                    return {
                      ...i,
                      [goalKey]: value,
                    };
                  }
                  return i;
                })
              : [value],
        };
      });
    }
  };
  const addNewTreatmentPlan = key => {
    if (key !== "Revised Goals") {
      setTreatmentData(prevData => {
        return {
          ...prevData,
          [key]: prevData?.[key]?.length > 0 ? [...prevData[key], ""] : [""],
        };
      });
    } else {
      setTreatmentData(prevData => {
        return {
          ...prevData,
          [key]:
            prevData?.[key]?.length > 0
              ? [...prevData[key], { Goal: "", ["Measurable Outcome"]: "" }]
              : [{ Goal: "", ["Measurable Outcome"]: "" }],
        };
      });
    }
  };
  const sanitizeData = () => {
    let data = { ...treatmentData };
    Object.keys(data).forEach(key => {
      if (key !== "Revised Goals") {
        data[key] = data[key].filter(i => !!i.trim());
      } else {
        data[key] = data[key].filter(i => {
          if (!!i["Goal"].trim() || !!i["Measurable Outcome"]) {
            return true;
          }
          return false;
        });
      }
    });
    setTreatmentData(data);
    return data;
  };
  const saveChanges = () => {
    let savedTreatmentPlans = getLocalStorage("saved-treatment-plans") || {};
    let sanitizedData = sanitizeData();
    savedTreatmentPlans = {
      ...savedTreatmentPlans,
      [props.patientId]: {
        data: sanitizedData,
        lastUpdatedOn: new Date().toISOString(),
      },
    };
    addLocalStorage("saved-treatment-plans", savedTreatmentPlans);
    toast.success("Changes Saved");
  };
  return (
    <ProgressLoader loading={props.loading}>
      <div className="bh-predictions-container">
        {treatmentData ? (
          <div className="treatment-plan-container">
            {!props.fromPatientDetails && (
              <div className="treatment-plan-header">
                <div className="treatment-plan-last-updated">Last updated on: {lastUpdatedOn}</div>
                <div className="action-btns">
                  <SecondaryButton onClick={generateTreatmentPlan}>
                    Regenerate Treatment Plan
                  </SecondaryButton>
                  <PrimaryButton onClick={saveChanges}>Save Changes</PrimaryButton>
                </div>
              </div>
            )}
            {treatmentData["Progress"]?.length > 0 && (
              <div className="treatment-plan-section">
                <div className="treatment-plan-section-title">Progress</div>
                <ul className="treatment-plan-section-content-list">
                  {treatmentData["Progress"].map((i, index) => {
                    return (
                      <li
                        className="treatment-plan-section-content-list-item"
                        key={index}
                        // contentEditable={true}
                      >
                        <Editable
                          content={i}
                          onChangeContent={value => onTreatmentPlanChange("Progress", index, value)}
                          notEditable={props.fromPatientDetails}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {treatmentData["Revised Goals"]?.length > 0 && (
              <div className="treatment-plan-section">
                <div className="treatment-plan-section-title">Revised Goals</div>
                <ul className="treatment-plan-section-content-list">
                  {treatmentData["Revised Goals"].map((i, index) => {
                    return (
                      <li
                        className="treatment-plan-section-content-list-item"
                        key={index}
                      >
                        <div className="treatment-plan-goal">
                          <div className="goal-item">
                            <span className="goal-label">Goal:</span>{" "}
                            <span>
                              <Editable
                                content={i["Goal"]}
                                onChangeContent={value =>
                                  onTreatmentPlanChange(
                                    "Revised Goals",
                                    index,
                                    value,
                                    "Goal",
                                    value
                                  )
                                }
                                notEditable={props.fromPatientDetails}
                              />
                            </span>
                          </div>
                          <div className="goal-item">
                            <span className="goal-label">Measurable Outcome:</span>{" "}
                            <span>
                              <Editable
                                content={i["Measurable Outcome"]}
                                onChangeContent={value =>
                                  onTreatmentPlanChange(
                                    "Revised Goals",
                                    index,
                                    value,
                                    "Measurable Outcome",
                                    value
                                  )
                                }
                                notEditable={props.fromPatientDetails}
                              />
                            </span>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                {!props.fromPatientDetails && (
                  <div className="add-new-item-container">
                    <div
                      className="add-new-item-link"
                      onClick={() => addNewTreatmentPlan("Revised Goals")}
                    >
                      Add New Goal
                    </div>
                  </div>
                )}
              </div>
            )}
            {treatmentData["Revised Treatment Plan"]?.length > 0 && (
              <div className="treatment-plan-section">
                <div className="treatment-plan-section-title">Revised Treatment Plan</div>
                <ul className="treatment-plan-section-content-list">
                  {treatmentData["Revised Treatment Plan"].map((i, index) => {
                    return (
                      <li
                        className="treatment-plan-section-content-list-item"
                        key={index}
                        // contentEditable={true}
                      >
                        <Editable
                          content={i}
                          onChangeContent={value =>
                            onTreatmentPlanChange("Revised Treatment Plan", index, value)
                          }
                          notEditable={props.fromPatientDetails}
                        />
                      </li>
                    );
                  })}
                </ul>
                {!props.fromPatientDetails && (
                  <div className="add-new-item-container">
                    <div
                      className="add-new-item-link"
                      onClick={() => addNewTreatmentPlan("Revised Treatment Plan")}
                    >
                      Add New Plan
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="generate-soap-notes-container">
            <PrimaryButton onClick={generateTreatmentPlan}>Generate Treatment Plan</PrimaryButton>
          </div>
        )}
      </div>
    </ProgressLoader>
  );
}

export default TreatmentPlan;

const ProgressLoader = props => {
  if (props.loading) {
    return (
      <div className="progress-loader">
        <ProgressBar
          animated
          now={100}
        />
        <div className="loading-msg">Generating Treatment Plan</div>
      </div>
    );
  } else {
    return props.children || <></>;
  }
};

const Editable = props => {
  const [content, setContent] = React.useState(props.content);

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  const onContentChange = React.useCallback(evt => {
    const sanitizeConf = {
      allowedTags: ["b", "i", "a", "p"],
      allowedAttributes: { a: ["href"] },
    };
    let value = sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf);
    setContent(sanitizeHtml(value));
    if (props.onChangeContent) {
      props.onChangeContent(sanitizeHtml(value));
    }
  }, []);
  if (props.notEditable) {
    return props.content;
  }
  return (
    <ContentEditable
      onChange={onContentChange}
      onBlur={onContentChange}
      html={content}
    />
  );
};
