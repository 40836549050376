import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { PrimaryButton } from "@/components";
import "./AddPatientModal.scss";

function AddPatientModal(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
  }, [props.modalOpen]);
  const addPatient = async () => {
    if (firstName && !loading) {
      setLoading(true);
      await props.addPatient({ firstName, lastName, email, phoneNumber });
      setLoading(false);
      props.toggleModal(false);
    }
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="add-patient-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-patient-modal-body">
        <Form className="add-patient-form custom-form">
          <div className="input-row">
            <Form.Group
              className="custom-input-field"
              controlId="patient-first-name-input"
            >
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Jane"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                disabled={!!loading}
              />
            </Form.Group>
            <Form.Group
              className="custom-input-field"
              controlId="patient-last-name-input"
            >
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Smith"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                disabled={!!loading}
              />
            </Form.Group>
          </div>
          <div className="input-row">
            <Form.Group
              className="custom-input-field"
              controlId="patient-email-input"
            >
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={!!loading}
              />
            </Form.Group>
            <Form.Group
              className="custom-input-field"
              controlId="patient-phone-input"
            >
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="2015550123"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                disabled={!!loading}
              />
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          onClick={addPatient}
          disabled={!firstName || loading}
        >
          Add Patient
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPatientModal;
