import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { rawPost } from "../../services/api";
import { saveCookie } from "../../services/utils";

const useLogin = props => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Login";
  }, []);

  const validateForm = () => {
    let valid = true;
    if (validateEmail()) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email");
      valid = false;
    }
    if (!email || !password) {
      valid = false;
    }
    return valid;
  };
  const isButtonDisabled = () => {
    let disabled = false;
    if (!email || !password || emailError || passwordError) {
      disabled = true;
    }
    return disabled;
  };
  const validateEmail = () => {
    return email
      ? String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      : true;
  };
  const onLogin = async () => {
    let url = `auth/practitioner/authenticate`;
    let payload = {
      email: email?.toLowerCase()?.trim() || "",
      password,
    };
    setLoading(true);
    setLoginError("");
    try {
      let response = await rawPost(url, payload);
      if (response?.data?.jwt) {
        saveCookie("authtoken", response.data.jwt);
        saveCookie("soulside-email", email);
        navigate("/", { replace: true });
      } else {
        setLoginError("Invalid Credentials");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLoginError("Invalid Credentials");
    }
  };
  return {
    email,
    setEmail,
    password,
    setPassword,
    hidePassword,
    setHidePassword,
    emailError,
    passwordError,
    validateForm,
    isButtonDisabled,
    loading,
    onLogin,
    loginError,
  };
};

export default useLogin;
