import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDyteClient, DyteProvider } from "@dytesdk/react-web-core";
import { Loader, SecondaryButton } from "@/components";
import VideoWidget from "./components/VideoWidget";
import SessionMemberNotes from "../SessionMemberNotes";
import SessionAppointments from "../SessionAppointments";
import NotesIcon from "@/assets/tasks/notes.svg";
import AppointmentsIcon from "@/assets/nav-icons/appointments.svg";
import { ReactSVG } from "react-svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { sendLiveTranscript } from "@/Routes/Appointments/appointmentsModule";
import "./VirtualSession.scss";

function VirtualSession(props) {
  const [meeting, initMeeting] = useDyteClient();
  const { sessionCategory, patientId, sessionId } = useParams();
  const [selectedMember, setSelectedMember] = useState(null);
  const [rightContent, setRightContent] = useState(true);
  const [showNotes, setShowNotes] = useState(true);
  const [showAppointments, setShowAppointments] = useState(false);
  useEffect(() => {
    return () => {
      endSession();
      props.hideSideBar(false);
    };
  }, []);
  useEffect(() => {
    if (
      props.meetingToken.data?.[patientId]?.token &&
      props.meetingToken.data?.[patientId]?.sessionCategory === sessionCategory
    ) {
      initMeeting({
        authToken: props.meetingToken.data[patientId].token,
        defaults: {
          audio: true,
          video: false,
        },
      }).then(m => {
        try {
          if (props.sessionType === "in-person") {
            // m.joinRoom();
          }
          m.recording.on("recordingUpdate", () => {
            console.log("recording disabled");
            throw "dummyerror";
          });
          m?.ai?.on("transcript", transcriptData => {
            if (!transcriptData?.isPartialTranscript) {
              let joinedParticipants = m?.participants?.joined?.toArray() || [];
              const selfParticipant = m?.self;
              let allParticipants = [selfParticipant, ...joinedParticipants];
              let transcriptUser = allParticipants.find(i => i.userId === transcriptData?.userId);
              let transcriptDataUpdated = {
                ...transcriptData,
                customParticipantId: transcriptUser.customParticipantId,
                isProvider:
                  transcriptUser.customParticipantId === selfParticipant?.customParticipantId,
              };
              sendLiveTranscript({ payload: transcriptDataUpdated, patientId });
              // console.log("transcriptDataUpdated", transcriptData);
            }
          });
        } catch (error) {
          console.log(error);
        }
      });
    } else {
      props.getMeetingToken({ patientId, sessionCategory, groupId: patientId });
    }
  }, [props.meetingToken.data?.[patientId]?.token]);
  const endSession = async () => {
    try {
      let roomState = meeting?.self?.roomState || "";
      if (roomState !== "init" && roomState !== "ended") {
        if (roomState === "left") {
          if (props.meetingToken.data[patientId].token) {
            let m = await initMeeting({
              authToken: props.meetingToken.data[patientId].token,
              defaults: {
                audio: false,
                video: false,
              },
            });
            if (m) {
              m?.recording?.on("recordingUpdate", () => {
                console.log("recording disabled");
                throw "dummyerror";
              });
              await m.joinRoom();
              await m?.participants?.kickAll();
            }
          }
        } else {
          await meeting?.participants?.kickAll();
        }
      }
    } catch (error) {
      console.log(error);
    }
    // navigate(`/session-details/${sessionCategory}/virtual/${sessionId}/${patientId}`);
  };
  const roomState = meeting?.self?.roomState || "";
  const sidePanelExpanded = !!rightContent || roomState === "init";
  return (
    <div className="session-dashboard-container">
      <Loader loading={props.meetingToken.loading || props.sessionDetails.loading}>
        <DyteProvider
          value={meeting}
          fallback={<Loader loading={true} />}
        >
          <div className="session-wrapper">
            <VideoWidget
              meeting={meeting}
              initMeeting={initMeeting}
              setSelectedMember={setSelectedMember}
              meetingToken={props.meetingToken.data?.[patientId]?.token || ""}
              sessionDetailsData={props.sessionDetails.data}
              showMemberNotes={sessionCategory !== "group"}
              setShowNotes={flag => setRightContent(!!flag)}
              showNotes={!!rightContent}
              addTask={props.addTask}
              preferredTimezone={props.preferredTimezone}
              mapProviderSessionId={props.mapProviderSessionId}
              hideSideBar={props.hideSideBar}
              meetingSession={props.meetingSession}
              changeMeetingSession={props.changeMeetingSession}
              sessionType={props.sessionType}
              sideBarHidden={props.sideBarHidden}
            />
          </div>
          {(roomState === "init" || roomState === "joined") && (
            <div className={`session-page-right ${!!sidePanelExpanded ? "expanded" : "collapsed"}`}>
              {!!sidePanelExpanded && roomState != "init" && (
                <div
                  className="collapse-btn"
                  onClick={() => setRightContent(false)}
                >
                  <div className="collapse-icon"></div>
                </div>
              )}
              {!sidePanelExpanded && (
                <div className="session-page-right-collapsed-container">
                  {sessionCategory !== "group" && (
                    <OverlayTrigger
                      overlay={<Tooltip>Notes</Tooltip>}
                      placement="left"
                    >
                      <div
                        className="session-page-right-action"
                        onClick={() => setRightContent(true)}
                      >
                        <ReactSVG
                          src={NotesIcon}
                          className="session-page-right-action-icon"
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    overlay={<Tooltip>Appointments</Tooltip>}
                    placement="left"
                  >
                    <div
                      className="session-page-right-action"
                      onClick={() => setRightContent(true)}
                    >
                      <ReactSVG
                        src={AppointmentsIcon}
                        className="session-page-right-action-icon"
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              )}
              {!!sidePanelExpanded && sessionCategory !== "group" && (
                <SessionMemberNotes
                  memberNotes={props.memberNotes}
                  hideNotes={() => setRightContent(false)}
                  preferredTimezone={props.preferredTimezone}
                  sessionDetailsData={props.sessionDetails.data}
                  sessionType={props.sessionType}
                />
              )}
              {!!sidePanelExpanded && (
                <SessionAppointments
                  endSession={endSession}
                  sessionEnded={meeting?.self?.roomState === "ended"}
                  currentSessionStatus={"Live"}
                  sessionType={props.sessionType}
                />
              )}
            </div>
          )}
        </DyteProvider>
      </Loader>
    </div>
  );
}

export default VirtualSession;
