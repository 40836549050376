import {
  getSelectedUserRoleFromLocal,
  getPreferredTimezoneFromLocal,
  getTasksFromLocal,
} from "../modules/userModule";

import { addLocalStorage } from "@/services/utils";

const initState = {
  userLoading: true,
  userData: null,
  userRoles: [],
  selectedUserRole: getSelectedUserRoleFromLocal(),
  preferredTimezone: getPreferredTimezoneFromLocal(),
  tasks: {
    data: getTasksFromLocal(),
    tasksReady: [],
    loading: false,
  },
};

const ACTION_HANDLERS = {
  ["TOGGLE_USER_LOADING"]: (state, action) => {
    return {
      ...state,
      userLoading: action.show,
    };
  },
  ["ADD_USER_DATA"]: (state, action) => {
    return {
      ...state,
      userData: action.data,
    };
  },
  ["ADD_USER_ROLES_DATA"]: (state, action) => {
    return {
      ...state,
      userRoles: action.data,
    };
  },
  ["SELECT_USER_ROLE"]: (state, action) => {
    return {
      ...state,
      selectedUserRole: action.data,
    };
  },
  ["SELECT_PREFERRED_TIMEZONE"]: (state, action) => {
    return {
      ...state,
      preferredTimezone: action.data,
    };
  },
  ["TOGGLE_TASKS_LOADING"]: (state, action) => {
    return {
      ...state,
      tasks: { ...state.tasks, loading: action.show },
    };
  },
  ["ADD_TASK"]: (state, action) => {
    let newTasks = [...state.tasks.data];
    let alreadyAdded = false;
    newTasks.forEach(task => {
      if (task.id === action.data.id) {
        alreadyAdded = true;
      }
    });
    if (!alreadyAdded) {
      newTasks = [...state.tasks.data, action.data];
    }
    addLocalStorage("session-tasks", newTasks);
    return {
      ...state,
      tasks: {
        ...state.tasks,
        data: newTasks,
      },
    };
  },
  ["REMOVE_TASK"]: (state, action) => {
    let newTasks = state.tasks.data.filter(i => i.id !== action.sessionId);
    addLocalStorage("session-tasks", newTasks);
    return {
      ...state,
      tasks: {
        ...state.tasks,
        data: newTasks,
      },
    };
  },
  ["ADD_READY_TASKS"]: (state, action) => {
    return {
      ...state,
      tasks: {
        ...state.tasks,
        tasksReady: action.data,
      },
    };
  },
};

export default function userReducer(state = initState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
