import React, { useEffect, useState } from "react";
import { Avatar, Loader, PrimaryButton, Search, SecondaryButton } from "@/components";
import AddPatientModal from "./components/AddPatientModal";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import PredictionCards from "../PatientDetails/components/PredictionCards";
import PhoneIcon from "@/assets/phone.svg";
import EmailIcon from "@/assets/email.svg";
import "./Patients.scss";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

function Patients(props) {
  const [searchKey, setSearchKey] = useState("");
  const [addPatientModaOpen, setAddPatientModalOpen] = useState(false);
  let patientsData = [...props.patients.data];
  patientsData = searchKey
    ? patientsData.filter(i => {
        let patientName = `${i.firstName} ${i.lastName}`;
        return !!patientName.toLowerCase().includes(searchKey);
      })
    : patientsData;
  // const patientInitials = [...new Set(patientsData.map(i => i.name?.[0]?.toUpperCase() || ""))];
  useEffect(() => {
    //Hiding patient chart as we need to work on structuring the data in prod
    if (APP_ENV === "PROD") {
      if (!props.patients.loading && props.patients.data.length === 0) {
        props.getPatients();
      }
    } else {
      if (!props.patients.loading && (props.patients.data.length === 0 || !props.alertsSaved)) {
        props.getPatients({ fromPatientsPage: true });
      }
    }
  }, []);
  useEffect(() => {
    document.title = "Patients";
  }, []);
  return (
    <div className="patients-wrapper">
      <Loader loading={props.patients.loading}>
        <div className="patients-header">
          <Search
            placeholder={"Search for a patient"}
            value={searchKey}
            onSearch={val => setSearchKey(val)}
            clearSearch={() => setSearchKey("")}
            noDebounce={true}
          />
          <div className="patients-header-actions">
            <PrimaryButton
              className="add-patient-btn"
              onClick={() => setAddPatientModalOpen(true)}
            >
              Add Patient
            </PrimaryButton>
          </div>
        </div>
        <div className="patients-list">
          {/* {patientInitials.map(initial => {
            return ( */}
          <div
            className="patient-contact-group"
            // key={initial}
          >
            {/* <div className="contact-group-label">{initial}</div> */}
            <div className="contact-group-list">
              {patientsData
                // .filter(i => i.name?.[0]?.toUpperCase() === initial)
                .map(patient => {
                  return (
                    <div
                      className="patient-item"
                      key={patient.id}
                    >
                      <div className="patient-details-wrapper">
                        <Avatar name={`${patient.firstName} ${patient.lastName}`} />
                        <div className="patient-details-container">
                          {/* Hiding patient chart as we need to work on structuring the data */}
                          {APP_ENV === "PROD" ? (
                            <div className="patient-name">
                              {patient.firstName} {patient.lastName}
                            </div>
                          ) : (
                            <Link
                              className="patient-name with-link"
                              to={`/patients/${patient.id}`}
                            >
                              {patient.firstName} {patient.lastName}
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="patient-contact-container">
                        <div className="patient-contact-item">
                          <ReactSVG
                            src={PhoneIcon}
                            className="patient-contact-icon"
                          />
                          <span className="patient-contact-value">
                            {patient.phoneNumber || props.getDummyPhoneNumber()}
                          </span>
                        </div>
                        <div className="patient-contact-item">
                          <ReactSVG
                            src={EmailIcon}
                            className="patient-contact-icon"
                          />
                          <span className="patient-contact-value">
                            {patient.email || props.getDummyEmail(patient.firstName)}
                          </span>
                        </div>
                      </div>
                      {patient.alerts && <PredictionCards data={patient.alerts} />}
                    </div>
                  );
                })}
            </div>
          </div>
          {/* );
          })} */}
        </div>
        <AddPatientModal
          modalOpen={addPatientModaOpen}
          toggleModal={setAddPatientModalOpen}
          addPatient={props.addPatient}
        />
      </Loader>
    </div>
  );
}

export default Patients;
