import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Dropdown, Overlay, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { Loader, MemberAvatar, PrimaryButton } from "@/components";
import { post } from "@/services/api";
import { addLocalStorage, getLocalStorage } from "@/services/utils";
import moment from "moment-timezone";
import InPersonIcon from "@/assets/sofa.svg";
import VirtualIcon from "@/assets/video-recorder.svg";
import PhoneSpeaker from "@/assets/phone-speaker.svg";
import IndividualIcon from "@/assets/individual.svg";
import GroupIcon from "@/assets/group.svg";
import Calendar from "react-calendar";
import { roleBusinessFunctionMapping } from "@/services/dummyData";
import "react-calendar/dist/Calendar.css";
import "./ScheduleSessionModal.scss";

const DATE_FORMAT = "MMM DD, YYYY";

function ScheduleSessionModal(props) {
  const navigate = useNavigate();
  const [sessionCategory, setSessionCategory] = useState("individual"); //individual, group
  const [sessionType, setSessionType] = useState("virtual"); //in-person,virtual, external-speaker-phone
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [durationInMinutes, setDurationInMinutes] = useState(30);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState("AM"); //AM, PM
  const calendarRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  // const patientsList = [{ name: "Anonymous Patient", patient_id: "anonymous" }, ...patientsData];
  let sessionStartTime = null;
  if (
    selectedDate &&
    selectedHour &&
    (selectedMinute || selectedMinute === 0) &&
    selectedTimeOfDay
  ) {
    let schedulingHour = selectedHour;
    if (selectedTimeOfDay === "AM") {
      if (schedulingHour === 12) {
        schedulingHour = 0;
      }
    } else {
      if (schedulingHour !== 12) {
        schedulingHour = schedulingHour + 12;
      }
    }
    let scheduledDate = moment(selectedDate).format("YYYY-MM-DD");
    sessionStartTime = moment(
      `${scheduledDate}T${String(schedulingHour).length === 1 ? "0" : ""}${schedulingHour}:${
        String(selectedMinute).length === 1 ? "0" : ""
      }${selectedMinute}:00`,
      "YYYY-MM-DDTHH:mm:ss",
      props.preferredTimezone?.name || "America/Los_Angeles"
    )
      .tz(props.preferredTimezone?.name || "America/Los_Angeles", true)
      .format();
  }
  const patientsList = props.patients.data;
  useEffect(() => {
    if (patientsList?.length === 0 && !props.patients.loading) {
      props.getPatients();
    }
  }, []);
  const groupsList = props.groups.data;
  useEffect(() => {
    if (groupsList?.length === 0 && !props.groups.loading) {
      props.getGroups();
    }
  }, []);
  const orgUsersData = props.orgUsers.data || [];
  const providersList = [];
  if (orgUsersData?.length > 0) {
    orgUsersData.forEach(user => {
      user.roles.forEach(role => {
        if (roleBusinessFunctionMapping(role.behaviorHealthRole) === "Clinical Care") {
          providersList.push({
            ...role,
            practitionerEmail: user.practitionerEmail,
            practitionerFirstName: user.practitionerFirstName,
            practitionerId: user.practitionerId,
            practitionerLastName: user.practitionerLastName,
          });
        }
      });
    });
  }
  useEffect(() => {
    let selectedUserBusinessFunction = props.selectedUserRole?.businessFunction;
    if (
      selectedUserBusinessFunction &&
      selectedUserBusinessFunction !== "Clinical Care" &&
      orgUsersData?.length === 0 &&
      !props.orgUsers.loading
    ) {
      props.getOrgUsers();
    }
  }, []);
  useEffect(() => {
    if (props.sessionType) {
      setSessionType(props.sessionType);
    }
  }, [props.sessionType]);
  useEffect(() => {
    setSessionCategory("individual");
    setSessionType("virtual");
    setSelectedPatient(null);
    setSelectedGroup(null);
    setDurationInMinutes(30);
    setSelectedDate(null);
    setSelectedHour("");
    setSelectedTimeOfDay("AM");
    setShowCalendar(false);
    setLoading(false);
  }, [props.modalOpen]);
  const onSelectDate = date => {
    setSelectedDate(date);
    setShowCalendar(false);
  };
  const scheduleSession = async () => {
    let sessionData = null;
    let url = "practitioner-role/individual-session/create";
    let selectedUserBusinessFunction = props.selectedUserRole?.businessFunction;
    let patientName = `${selectedPatient?.firstName} ${selectedPatient?.lastName}`;
    let groupName = selectedGroup?.groupName;
    let sessionName =
      sessionCategory === "individual"
        ? `${
            sessionType === "in-person"
              ? "In-Office session with"
              : sessionType === "external-speaker-phone"
              ? "Phone session with"
              : "Telehealth session with"
          } ${patientName}`
        : `${
            sessionType === "in-person"
              ? "In-Office session for"
              : sessionType === "external-speaker-phone"
              ? "Phone session for"
              : "Telehealth session for"
          } ${groupName}`;
    let offsetStartDateTime = sessionStartTime;
    let payload = {
      practitionerRoleId:
        selectedUserBusinessFunction === "Clinical Care"
          ? props.practitionerRoleId
          : selectedProvider?.id,
      sessionName,
      offsetStartDateTime,
      durationInMinutes,
      sessionCategory: sessionCategory === "group" ? "GROUP" : "INDIVIDUAL",
      modeOfDelivery:
        sessionType === "in-person" || sessionType === "external-speaker-phone"
          ? "IN_PERSON"
          : "VIRTUAL",
    };
    if (sessionCategory === "group") {
      url = "practitioner-role/sessions/create-on-demand";
      payload.groupId = selectedGroup?.id;
    } else {
      payload.patientId = selectedPatient?.id;
    }
    setLoading(true);
    try {
      let response = await post(url, payload);
      if (response?.data) {
        sessionData = response.data;
      }
    } catch (error) {}
    setLoading(false);
    if (sessionData) {
      props.getSessionsByPractitionerRole();
    }
    props.toggleModal(false);
    return sessionData;
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="schedule-session-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Schedule Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="start-session-modal-body">
          <div className="session-type-container">
            <div className="session-type-label">Session Category:</div>
            <div className="session-type">
              <Form.Check
                type="radio"
                label={
                  <div className="session-type-option-label">
                    <img
                      className="session-type-option-icon"
                      src={IndividualIcon}
                    />
                    Individual
                  </div>
                }
                name={"individual"}
                id={`session-category-individual`}
                checked={sessionCategory === "individual"}
                onChange={() => setSessionCategory("individual")}
                className="session-type-option-radio-button"
              />
            </div>
            <div className="session-type">
              <Form.Check
                type="radio"
                label={
                  <div className="session-type-option-label">
                    <img
                      className="session-type-option-icon"
                      src={GroupIcon}
                    />
                    Group
                  </div>
                }
                name={"group"}
                id={`session-category-group`}
                checked={sessionCategory === "group"}
                onChange={() => setSessionCategory("group")}
                className="session-type-option-radio-button"
              />
            </div>
          </div>
          <div className="session-type-container">
            <div className="session-type-label">Session Type:</div>
            <div className="session-type">
              <Form.Check
                type="radio"
                label={
                  <div className="session-type-option-label">
                    <img
                      className="session-type-option-icon"
                      src={VirtualIcon}
                    />
                    Telehealth
                  </div>
                }
                name={"virtual"}
                id={`session-type-virtual`}
                checked={sessionType === "virtual"}
                onChange={() => setSessionType("virtual")}
                className="session-type-option-radio-button"
              />
            </div>
            <div className="session-type">
              <Form.Check
                type="radio"
                label={
                  <div className="session-type-option-label">
                    <img
                      className="session-type-option-icon"
                      src={InPersonIcon}
                    />
                    In-Office
                  </div>
                }
                name={"in-person"}
                id={`session-type-in-person`}
                checked={sessionType === "in-person"}
                onChange={() => setSessionType("in-person")}
                className="session-type-option-radio-button"
              />
            </div>
            {/* <div className="session-type">
              <Form.Check
                type="radio"
                label={
                  <div className="session-type-option-label">
                    <img
                      className="session-type-option-icon"
                      src={PhoneSpeaker}
                    />
                    Phone Speaker
                  </div>
                }
                name={"external-speaker-phone"}
                id={`session-type-external-speaker-phone`}
                checked={sessionType === "external-speaker-phone"}
                onChange={() => setSessionType("external-speaker-phone")}
                className="session-type-option-radio-button"
              />
            </div> */}
          </div>
          <div className="input-row">
            {sessionCategory === "individual" && (
              <div className="select-patient-container">
                <Form.Label className="select-patient-label">Select Patient:</Form.Label>
                <Dropdown className="select-patient-dropdown">
                  <Dropdown.Toggle>
                    {!!selectedPatient
                      ? `${selectedPatient?.firstName} ${selectedPatient?.lastName}`
                      : "Select Patient"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Loader loading={false}>
                      {patientsList.length > 0 ? (
                        patientsList.map(patient => {
                          return (
                            <Dropdown.Item
                              as={"button"}
                              onClick={() => setSelectedPatient(patient)}
                              key={patient.id}
                            >
                              <MemberAvatar
                                name={`${patient?.firstName} ${patient?.lastName}`}
                                size={"small"}
                                showFullName={true}
                              />
                            </Dropdown.Item>
                          );
                        })
                      ) : (
                        <Dropdown.Item
                          as={"button"}
                          className="no-items-found"
                        >
                          No Patients Found
                        </Dropdown.Item>
                      )}
                    </Loader>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {sessionCategory === "group" && (
              <div className="select-patient-container">
                <Form.Label className="select-patient-label">Select Group:</Form.Label>
                <Dropdown className="select-patient-dropdown">
                  <Dropdown.Toggle>
                    {!!selectedGroup ? `${selectedGroup?.groupName}` : "Select Group"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Loader loading={false}>
                      {groupsList.length > 0 ? (
                        groupsList.map(group => {
                          return (
                            <Dropdown.Item
                              as={"button"}
                              onClick={() => setSelectedGroup(group)}
                              key={group.id}
                            >
                              {group.groupName}
                            </Dropdown.Item>
                          );
                        })
                      ) : (
                        <Dropdown.Item
                          as={"button"}
                          className="no-items-found"
                        >
                          No Groups Found
                        </Dropdown.Item>
                      )}
                    </Loader>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            <div className="select-patient-container">
              <Form.Label className="select-patient-label">Duration of Session:</Form.Label>
              <Dropdown className="select-patient-dropdown">
                <Dropdown.Toggle>
                  {!!durationInMinutes ? `${durationInMinutes} mins` : "Select Duration"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Loader loading={false}>
                    {[15, 30, 45, 60].map((duration, index) => {
                      return (
                        <Dropdown.Item
                          // as={"button"}
                          onClick={() => setDurationInMinutes(duration)}
                          key={index}
                        >
                          {duration} mins
                        </Dropdown.Item>
                      );
                    })}
                  </Loader>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {props.selectedUserRole?.businessFunction !== "Clinical Care" && (
            <div className="select-patient-container select-provider-container">
              <Form.Label className="select-patient-label">Select Provider:</Form.Label>
              <Dropdown className="select-patient-dropdown">
                <Dropdown.Toggle>
                  {!!selectedProvider
                    ? `${selectedProvider?.practitionerFirstName} ${selectedProvider?.practitionerLastName}`
                    : "Select Provider"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Loader loading={false}>
                    {providersList.length > 0 ? (
                      providersList.map(practitioner => {
                        return (
                          <Dropdown.Item
                            as={"button"}
                            onClick={() => setSelectedProvider(practitioner)}
                            key={practitioner.id}
                            className="select-provider-dropdown-item"
                          >
                            <div className="select-provider-dropdown-item-top">
                              <MemberAvatar
                                name={`${practitioner?.practitionerFirstName} ${practitioner?.practitionerLastName}`}
                                size={"small"}
                                showFullName={true}
                              />
                              <div className="practitioner-roles">
                                <div className="role">{practitioner.practitionerEmail}</div>
                              </div>
                            </div>
                            <div className="practitioner-roles">
                              <div className="role">{practitioner.behaviorHealthRole}</div>
                            </div>
                          </Dropdown.Item>
                        );
                      })
                    ) : (
                      <Dropdown.Item
                        as={"button"}
                        className="no-items-found"
                      >
                        No Providers Found
                      </Dropdown.Item>
                    )}
                  </Loader>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <div className="select-patient-container session-time-container">
            <Form.Label className="select-patient-label">Session Start Time:</Form.Label>
            <div className="session-time-inputs">
              <div className="session-date-wrapper">
                <div
                  className="session-date-container"
                  ref={calendarRef}
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <div className="session-date">
                    {selectedDate ? moment(selectedDate).format(DATE_FORMAT) : "Select Date"}
                  </div>
                </div>
                <Overlay
                  target={calendarRef.current}
                  show={showCalendar}
                  placement="bottom"
                >
                  {props => (
                    <Tooltip
                      id="calendar-overlay"
                      className="calendar-overlay"
                      {...props}
                    >
                      <Calendar
                        onChange={onSelectDate}
                        value={selectedDate}
                        minDate={new Date()}
                      />
                    </Tooltip>
                  )}
                </Overlay>
              </div>
              <div className="session-time-separator">@</div>
              <Dropdown className="select-patient-dropdown">
                <Dropdown.Toggle>
                  {!!selectedHour
                    ? `${String(selectedHour).length === 1 ? "0" : ""}${selectedHour}`
                    : "HH"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((hour, index) => {
                    return (
                      <Dropdown.Item
                        onClick={() => setSelectedHour(hour)}
                        key={index}
                      >
                        {String(hour).length === 1 ? "0" : ""}
                        {hour}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <div className="session-time-separator">:</div>
              <Dropdown className="select-patient-dropdown">
                <Dropdown.Toggle>
                  {!!selectedMinute || selectedMinute === 0
                    ? `${String(selectedMinute).length === 1 ? "0" : ""}${selectedMinute}`
                    : "MM"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[0, 15, 30, 45, 60].map((minute, index) => {
                    return (
                      <Dropdown.Item
                        onClick={() => setSelectedMinute(minute)}
                        key={index}
                      >
                        {String(minute).length === 1 ? "0" : ""}
                        {minute}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="select-patient-dropdown">
                <Dropdown.Toggle>{!!selectedTimeOfDay ? selectedTimeOfDay : "AM"}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {["AM", "PM"].map((i, index) => {
                    return (
                      <Dropdown.Item
                        onClick={() => setSelectedTimeOfDay(i)}
                        key={index}
                      >
                        {i}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <div className="session-time-separator">{props.preferredTimezone?.abbr || "PT"}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          onClick={scheduleSession}
          disabled={
            loading ||
            (sessionCategory === "group" ? !selectedGroup?.id : !selectedPatient?.id) ||
            !durationInMinutes ||
            !sessionStartTime ||
            (props.selectedUserRole?.businessFunction !== "Clinical Care"
              ? !selectedProvider?.id
              : false)
          }
        >
          Submit
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ScheduleSessionModal;
