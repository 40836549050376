import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { RichTextEditor } from "@/components/RichTextEditor";
import "./Recommendation.scss";
import { PrimaryButton } from "@/components";
import { toast } from "react-toastify";

function Recommendation(props) {
  const { data } = props;
  const [triageActionSelected, setTriageActionSelected] = useState(false);
  const [triageActionsAcknowledged, setTriageActionsAcknowledged] = useState(false);
  const acknowledgeActions = () => {
    toast.success("Triage Actions Acknowledged");
    setTriageActionsAcknowledged(true);
  };
  return (
    <div className="recommendation-widget">
      <div className="widget-header">
        <div className="widget-title">Recommendations</div>
      </div>
      <ul className="recommendation-list">
        {!!data && (
          <RichTextEditor
            value={data}
            readOnly={true}
          />
        )}
      </ul>
      {/* <div className="recommendation-container">
        <div className="recommendation-header">
          <div className="recommendation-label">Suggested Triage Actions</div>
        </div>
        <div className="recommendation-list">
          <div className="recommendation-item">
            <Form.Group
              className="custom-input-field checkbox-input recommendation-checkbox"
              controlId="recommendation-input-1"
              onClick={() =>
                setTriageActionSelected(triageActionsAcknowledged || !triageActionSelected)
              }
            >
              <div className={`custom-checkbox ${triageActionSelected ? "checked" : ""}`}>
                <span className="checkbox-icon"></span>
              </div>
              <Form.Label>Assign: Psychiatry session support for medication changes</Form.Label>
            </Form.Group>
          </div>
        </div>
        {!triageActionsAcknowledged && (
          <div className="recommendations-cta">
            <PrimaryButton
              disabled={!triageActionSelected}
              onClick={acknowledgeActions}
            >
              Acknowledge Actions
            </PrimaryButton>
          </div>
        )}
      </div> */}
    </div>
  );
}

export default Recommendation;
