import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import { getCookie } from "@/services/utils";

import Logo from "../../assets/soulside-logo.svg";

import "./AuthLayout.scss";

function AuthLayout(props) {
  const naviagte = useNavigate();
  useEffect(() => {
    let authtoken = getCookie("authtoken");
    if (authtoken) {
      naviagte("/", { replace: true });
    }
  }, []);
  return (
    <div className="auth-layout">
      <Container className="auth-layout-container">
        <Navbar>
          <Navbar.Brand>
            <img
              src={Logo}
              alt="Soulside"
              className="navbar-logo"
            />
          </Navbar.Brand>
        </Navbar>
        <Outlet />
      </Container>
    </div>
  );
}

export default AuthLayout;
