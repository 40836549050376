import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import "./ShareMeetingLink.scss";
import { MemberAvatar, PrimaryButton } from "@/components";
import { Modal } from "react-bootstrap";
import ShareIcon from "@/assets/share.svg";
import CopyIcon from "@/assets/copy.svg";
import moment from "moment";
import { SendText } from "@/components/SendText";
import { toast } from "react-toastify";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

function ShareMeetingLink(props) {
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [sendTextModalOpen, setSendTextModalOpen] = useState(false);
  const copyMeetingLink = () => {
    navigator.clipboard
      .writeText(meetingLink)
      .then(() => {
        toast.success("Meeting link copied to clipboard");
      })
      .catch(err => {
        console.error("Unable to copy text to clipboard", err);
      });
  };
  const meetingLink = `https://app${
    APP_ENV === "DEV" ? ".dev" : ""
  }.soulsidehealth.com/virtual-session/${props.patientId}`;
  if (props.sessionCategory === "individual") {
    let providerName = `${props.sessionDetailsData?.practitionerFirstName || ""}${
      props.sessionDetailsData?.practitionerLastName ? " " : ""
    }${props.sessionDetailsData?.practitionerLastName || ""}`;
    let patientName = props.sessionDetailsData?.patientFirstName || "";
    let orgName = props.sessionDetailsData?.organizationName || "";
    if (orgName.includes("Serenity")) {
      orgName = "Serenity Health Clinic";
    }
    let sessionDate = props.sessionDetailsData?.startTime
      ? moment(props.sessionDetailsData.startTime)
          .tz(props.preferredTimezone?.name || "America/Los_Angeles")
          .format("Do MMMM")
      : "";
    let sessionTime = props.sessionDetailsData?.startTime
      ? `${moment(props.sessionDetailsData.startTime)
          .tz(props.preferredTimezone?.name || "America/Los_Angeles")
          .format("h:mm A")} ${props.preferredTimezone?.abbr || "PT"}`
      : "";
    let message = `From: ${orgName} (Dr. ${providerName})\n\nHi ${patientName},\n\nYour appointment ${
      orgName ? `at ${orgName} ` : ""
    }with Dr. ${providerName} is scheduled for ${sessionDate} at ${sessionTime}.\n\nYou can join your appointment with the following link:\n${meetingLink}`;
    return (
      <div className="share-meeting-link-container">
        <div className="share-meeting-link-label">
          <div className="share-meeting-link-label-text">
            Share the meeting link with the patient
          </div>
          <div
            className="share-btn"
            onClick={() => setSendTextModalOpen(true)}
          >
            <ReactSVG
              src={ShareIcon}
              className="share-icon"
            />
            Share by SMS
          </div>
          <div
            className="share-btn no-display-copy-btn"
            onClick={() => copyMeetingLink(true)}
          >
            <ReactSVG
              src={CopyIcon}
              className="share-icon"
            />
            Copy Link
          </div>
        </div>
        <MeetingLinkCopy meetingLink={meetingLink} />
        <SendText
          modalOpen={sendTextModalOpen}
          toggleModal={setSendTextModalOpen}
          phoneNumber={props.sessionDetailsData?.patientPhoneNumber}
          message={message}
        />
      </div>
    );
  }
  return (
    <div className="share-meeting-group-container">
      <div className="share-meeting-group-msg">Share meeting link with the patients</div>
      <div
        className="share-btn"
        onClick={() => setLinkModalOpen(true)}
      >
        <ReactSVG
          src={ShareIcon}
          className="share-icon"
        />
        Share
      </div>
      <ShareMeetingModal
        sessionDetailsData={props.sessionDetailsData}
        toggleModal={setLinkModalOpen}
        modalOpen={linkModalOpen}
        groupId={props.groupId}
      />
    </div>
  );
}

export default ShareMeetingLink;

const MeetingLinkCopy = ({ meetingLink }) => {
  const [meetingLinkCopied, setMeetingLinkCopied] = useState(false);
  const copyMeetingLink = () => {
    navigator.clipboard
      .writeText(meetingLink)
      .then(() => {
        setMeetingLinkCopied(true);
        setTimeout(() => {
          setMeetingLinkCopied(false);
        }, 1000);
      })
      .catch(err => {
        console.error("Unable to copy text to clipboard", err);
      });
  };
  const noop = e => {
    e.preventDefault();
    return false;
  };
  return (
    <div className={`meeting-link-container ${meetingLinkCopied ? "copied" : ""}`}>
      <div
        className="meeting-link"
        contentEditable="true"
        onCut={noop}
        onCopy={noop}
        onPaste={noop}
        onKeyDown={noop}
      >
        {meetingLink}
      </div>
      <div
        className="copy-btn"
        onClick={copyMeetingLink}
      >
        {meetingLinkCopied ? "Copied" : "Copy"}
      </div>
    </div>
  );
};

const ShareMeetingModal = props => {
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="share-meeting-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Share Meeting Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.sessionDetailsData?.sessionPatientMemberDtos?.length > 0 ? (
          <div className="session-link-members-list">
            {props.sessionDetailsData?.sessionPatientMemberDtos.map((patient, index) => {
              let meetingLink = `https://app${
                APP_ENV === "DEV" ? ".dev" : ""
              }.soulsidehealth.com/virtual-session/group/${props.groupId}/${patient.patientId}`;
              return (
                <div
                  className="patient-meeting-link-container"
                  key={patient.patientId}
                >
                  <MemberAvatar
                    name={`${patient.patientFirstName} ${patient.patientLastName}`}
                    // size={"md"}
                    showFullName={true}
                  />
                  <MeetingLinkCopy meetingLink={meetingLink} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="session-link-no-member">No Patients Found</div>
        )}
      </Modal.Body>
    </Modal>
  );
};
