import { get, post } from "@/services/api";
import { toast } from "react-toastify";

export function groupByPractitioner(data) {
  const groupedData = {};
  data.forEach(item => {
    const {
      practitionerId,
      practitionerFirstName,
      practitionerLastName,
      practitionerEmail,
      ...roleDetails
    } = item;
    if (!groupedData[practitionerId]) {
      groupedData[practitionerId] = {
        practitionerId,
        practitionerFirstName,
        practitionerLastName,
        practitionerEmail,
        roles: [],
      };
    }
    groupedData[practitionerId].roles.push(roleDetails);
  });
  return Object.values(groupedData);
}

export const getOrgUsers = options => {
  return async (dispatch, getState) => {
    const state = getState();
    const orgId = state.user.selectedUserRole?.organizationId;
    let url = `practitioner-role/find-all-practitioner-roles-by-org/${orgId}`;
    let orgUsers = [];
    dispatch({ type: "TOGGLE_ORG_USERS_LOADER", show: true });
    try {
      let response = await get(url);
      let data = response.data?.length > 0 ? response.data : [];
      orgUsers = groupByPractitioner(data);
    } catch (error) {
      console.log(error);
    }
    dispatch({ type: "ADD_ORG_USERS_DATA", data: orgUsers });
    dispatch({ type: "TOGGLE_ORG_USERS_LOADER", show: false });
  };
};

export const addOrgUser = options => {
  return async (dispatch, getState) => {
    const state = getState();
    const organizationId = state.user.selectedUserRole?.organizationId;
    let payload = {
      organizationId,
      firstName: options?.firstName,
      lastName: options?.lastName,
      email: options?.email?.toLowerCase()?.trim() || "",
      behaviorHealthRole: options?.behaviorHealthRole,
    };
    let url = "practitioner-role/invite-new-practitioner-role-to-org";
    try {
      let response = await post(url, payload);
      if (response?.data) {
        dispatch({ type: "ADD_ORG_USER", data: response.data });
        toast.success("User Invited");
      }
    } catch (error) {}
  };
};

const initState = {
  users: {
    data: [],
    loading: false,
  },
};

const ACTION_HANDLERS = {
  ["TOGGLE_ORG_USERS_LOADER"]: (state, action) => {
    return {
      ...state,
      users: {
        ...state.users,
        loading: action.show,
      },
    };
  },
  ["ADD_ORG_USERS_DATA"]: (state, action) => {
    return {
      ...state,
      users: {
        ...state.users,
        data: action.data,
      },
    };
  },
  ["ADD_ORG_USER"]: (state, action) => {
    let usersData = state.users?.data?.length > 0 ? [...state.users.data] : [];
    let userFound = false;
    let newUserRole = {
      id: action.data?.id,
      active: action.data?.active,
      organizationId: action.data?.organizationId,
      organizationName: action.data?.organizationName,
      behaviorHealthRole: action.data?.behaviorHealthRole,
      createdAt: action.data?.createdAt,
    };
    let newUser = {
      practitionerEmail: action.data?.practitionerEmail,
      practitionerFirstName: action.data?.practitionerFirstName,
      practitionerId: action.data?.practitionerId,
      practitionerLastName: action.data?.practitionerLastName,
      roles: [newUserRole],
    };
    usersData = usersData.map(user => {
      if (user.practitionerEmail === action.data?.practitionerEmail) {
        userFound = true;
        return {
          ...user,
          roles: user.roles?.length > 0 ? [...user.roles, newUserRole] : [newUserRole],
        };
      }
      return user;
    });
    if (!userFound) {
      usersData = [...usersData, newUser];
    }
    return {
      ...state,
      users: {
        ...state.users,
        data: usersData,
      },
    };
  },
};

export default function orgUsers(state = initState, action) {
  let handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
