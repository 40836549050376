import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import StarIcon from "@/assets/star.svg";
import PromptArrowIcon from "@/assets/prompt-arrow.svg";
import "./CrisisSupport.scss";

export function CrisisSupport(props) {
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="group-guidelines-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Crisis Support{" "}
          <img
            src={StarIcon}
            alt=""
            className="star-icon"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="group-guidelines-desc">
          Please encourage a member in crisis to utilize these resources. They are available 24/7,
          nationwide.
        </div>
        <div className="guidelines-list">
          <div className="guideline-item">
            <img
              src={PromptArrowIcon}
              alt=""
              className="arrow-icon"
            />
            <div className="guideline-text">
              The Lifeline: Call or text <a href="tel:988">988</a> or chat{" "}
              <a
                href="https://988lifeline.org/"
                target="_blank"
              >
                988lifeline.org
              </a>{" "}
              for free, immediate crisis assistance.
            </div>
          </div>
          <div className="guideline-item">
            <img
              src={PromptArrowIcon}
              alt=""
              className="arrow-icon"
            />
            <div className="guideline-text">
              The Crisis Text Line: Text HOME to <a href="sms:741741">741741</a> from anywhere in
              the United States, anytime.
            </div>
          </div>
          <div className="guideline-item">
            <img
              src={PromptArrowIcon}
              alt=""
              className="arrow-icon"
            />
            <div className="guideline-text">
              Local response: If the member feeling unsafe and experiencing a serious mental health
              crisis, instruct member to call <a href="tel:911">911</a> for immediate local,
              in-person assistance.
            </div>
          </div>
          <div className="guideline-item">
            <img
              src={PromptArrowIcon}
              alt=""
              className="arrow-icon"
            />
            <div className="guideline-text">
              Friendly reminder: In case of an emergency, please note that 911 services are only
              effective if you're in the same physical location as the person in need. If the person
              is unable or unwilling to contact 911 and it is necessary, you can help by identifying
              their location and finding the appropriate local in-person crisis assistance.
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-text">
          Please drop an email to{" "}
          <a
            href="mailto:care@getsoulside.com"
            target="_blank"
          >
            care@getsoulside.com
          </a>{" "}
          to inform our team that you have referred a member to additional mental health resources.
          Also, remember to document in the member chart about this interaction, including the
          member’s response. Thank you!
        </div>
      </Modal.Footer>
    </Modal>
  );
}
