import StartSessionModal from "./StartSessionModal";
import { connect } from "react-redux";
import { getPatients, addPatient } from "@/Routes/Patients/patientsModule";
import { getGroups } from "@/Routes/Groups/groupsModule";

const mapStateToProps = state => {
  return {
    patients: state.patients.patients,
    groups: state.groups.groups,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPatients: options => dispatch(getPatients(options)),
    addPatient: options => dispatch(addPatient(options)),
    getGroups: options => dispatch(getGroups(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartSessionModal);
