import SessionDetails from "./SessionDetails";
import { connect } from "react-redux";
import {
  getMemberNotes,
  updateMemberNotes,
  getSessionTranscript,
  getSessionMemberNotes,
  generateSoapNotes,
  saveSoapNotes,
  generateBhPredictions,
  saveBhPredictions,
  saveSpeakerMapping,
  getSessionDetails,
  getProviderSessionIds,
  generateTreatmentPlan,
} from "./sessionDetailsModule";

import { getGroups } from "../Groups/groupsModule";

import { getPatients } from "../Patients/patientsModule";

import { removeTask } from "@/store/modules/userModule";

const mapStateToProps = state => {
  return {
    memberNotes: state.sessionDetails.memberNotes,
    soapNotes: state.sessionDetails.soapNotes,
    bhPredictions: state.sessionDetails.bhPredictions,
    sessionTranscript: state.sessionDetails.sessionTranscript,
    session: state.sessionDetails.session,
    treatmentPlan: state.sessionDetails.treatmentPlan,
    patients: state.patients.patients,
    groups: state.groups.groups,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMemberNotes: options => dispatch(getMemberNotes(options)),
    updateMemberNotes: options => dispatch(updateMemberNotes(options)),
    getSessionTranscript: options => dispatch(getSessionTranscript(options)),
    getSessionMemberNotes: options => dispatch(getSessionMemberNotes(options)),
    generateSoapNotes: options => dispatch(generateSoapNotes(options)),
    saveSoapNotes: options => dispatch(saveSoapNotes(options)),
    generateBhPredictions: options => dispatch(generateBhPredictions(options)),
    saveBhPredictions: options => dispatch(saveBhPredictions(options)),
    getPatients: options => dispatch(getPatients(options)),
    saveSpeakerMapping: options => dispatch(saveSpeakerMapping(options)),
    getGroups: options => dispatch(getGroups(options)),
    getSessionDetails: options => dispatch(getSessionDetails(options)),
    getProviderSessionIds: options => dispatch(getProviderSessionIds(options)),
    generateTreatmentPlan: options => dispatch(generateTreatmentPlan(options)),
    removeTask: sessionId => dispatch(removeTask(sessionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionDetails);
