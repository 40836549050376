import React, { useState, useEffect } from "react";
import { ProgressBar, Dropdown, Form } from "react-bootstrap";
import { Loader, PrimaryButton, RichTextEditor, SecondaryButton } from "@/components";
import { toast } from "react-toastify";
import { getLocalStorage } from "@/services/utils";
import "./SoapNotes.scss";
import SoapNotesTemplate from "./components/SoapNotesTemplate";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

function SoapNotes(props) {
  const [notesData, setNotesData] = useState("");
  const [notesTemplate, setNotesTemplate] = useState(
    props.sessionCategory === "group" || APP_ENV === "DEV" ? "default" : "assessment"
  ); //default, assessment
  const [generateSoapLoading, setGenerateSoapLoading] = useState(false);
  const [soapNotesJson, setSoapNotesJson] = useState(null);
  useEffect(() => {
    setSoapNotesJson(props.soapNotesJson || null);
  }, [props.soapNotesJson]);
  useEffect(() => {
    setNotesData(props.data || "");
  }, [props.data]);
  const providerName = `${props.sessionData?.practitionerFirstName || ""}${
    props.sessionData?.practitionerLastName ? " " : ""
  }${props.sessionData?.practitionerLastName || ""}`;
  const patientName = `${props.sessionData?.patientFirstName || ""}${
    props.sessionData?.patientLastName ? " " : ""
  }${props.sessionData?.patientLastName || ""}`;
  const generateSoapNotes = async (regenrate = false) => {
    let sessionId = props.sessionId;
    let notesId = props.notesId || null;
    let groupId = props?.groupId;
    let patientId = props?.patientId;
    let sessionCategory = props.sessionCategory;
    if (sessionId) {
      let transcriptData = props.transcriptData || [];
      let transcriptText = "";
      transcriptData.forEach(transcript => {
        let memberName = transcript.memberName;
        if (
          ["provider", "patient"].includes(memberName?.toLowerCase()) &&
          props.sessionCategory === "individual"
        ) {
          if (memberName?.toLowerCase() === "provider" && providerName) {
            memberName = providerName;
          }
          if (memberName?.toLowerCase() === "patient" && patientName) {
            memberName = patientName;
          }
        }
        transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
      });
      let payload = {
        transcript: transcriptText,
        sessionCategory,
      };
      setGenerateSoapLoading(true);
      await props.generateSoapNotes({
        sessionId,
        groupId,
        patientId,
        payload,
        regenrate,
        sessionCategory,
        notesId,
        notesTemplate,
        soapNote: notesData,
        soapNotesJson: soapNotesJson,
      });
      setGenerateSoapLoading(false);
    }
  };
  const saveNotes = () => {
    let sessionId = props.sessionId;
    let groupId = props?.groupId;
    let patientId = props?.patientId;
    let notesId = props.notesId || null;
    props.removeTask(sessionId);
    props.saveSoapNotes({
      groupId,
      patientId,
      sessionId,
      soapNote: notesData,
      notesId,
      soapNotesJson: soapNotesJson,
    });
  };
  const changeSoapJsonData = data => {
    setSoapNotesJson(data);
  };
  let LoaderComponent = generateSoapLoading ? ProgressLoader : Loader;
  return (
    <LoaderComponent loading={props.loading}>
      <div className="soap-notes-container">
        {(props.data && notesTemplate === "default") ||
        (!!soapNotesJson && notesTemplate === "assessment") ? (
          <>
            <div className="soap-notes-header">
              <div className="select-template-container">
                {props.sessionCategory === "individual" && (
                  <>
                    <Form.Label className="select-template-label">Notes Template:</Form.Label>
                    <Dropdown className="select-template-dropdown">
                      <Dropdown.Toggle>
                        {notesTemplate === "default" ? `Default` : "Assessment"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as={"button"}
                          onClick={() => setNotesTemplate("default")}
                        >
                          Default
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={"button"}
                          onClick={() => setNotesTemplate("assessment")}
                        >
                          Assessment
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}
              </div>
              <div className="save-notes-container">
                <SecondaryButton onClick={() => generateSoapNotes(true)}>
                  Regenerate Notes
                </SecondaryButton>
                <PrimaryButton onClick={saveNotes}>Save & Approve Notes</PrimaryButton>
              </div>
            </div>

            {(props.sessionCategory === "group" ||
              (props.sessionCategory === "individual" && notesTemplate === "default")) && (
              <RichTextEditor
                value={notesData}
                setValue={setNotesData}
              />
            )}
            {props.sessionCategory === "individual" && notesTemplate === "assessment" && (
              <SoapNotesTemplate
                data={soapNotesJson}
                changeSoapJsonData={changeSoapJsonData}
              />
            )}
          </>
        ) : (
          <div className="generate-soap-notes-container">
            {props.sessionCategory === "individual" && (
              <div className="select-template-container">
                <Form.Label className="select-template-label">Notes Template:</Form.Label>
                <Dropdown className="select-template-dropdown">
                  <Dropdown.Toggle>
                    {notesTemplate === "default" ? `Default` : "Assessment"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={"button"}
                      onClick={() => setNotesTemplate("default")}
                    >
                      Default
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={"button"}
                      onClick={() => setNotesTemplate("assessment")}
                    >
                      Assessment
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            <PrimaryButton onClick={() => generateSoapNotes()}>Generate Notes</PrimaryButton>
          </div>
        )}
      </div>
    </LoaderComponent>
  );
}

export default SoapNotes;

const ProgressLoader = props => {
  return (
    <div className="progress-loader">
      <ProgressBar
        animated
        now={100}
      />
      <div className="loading-msg">Generating SOAP Notes</div>
    </div>
  );
};
