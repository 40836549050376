import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { PrimaryButton } from "@/components";
import PhoneInput, { isValidPhoneNumber, formatPhoneNumber } from "react-phone-number-input";
import parsePhoneNumber from "libphonenumber-js";
import { post } from "@/services/api";
import "react-phone-number-input/style.css";
import "./SendText.scss";
import { toast } from "react-toastify";

export function SendText(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [invalidPhoneNumner, setInvalidPhoneNumber] = useState(false);
  const validatePhoneNumber = value => {
    let valid = !!value && isValidPhoneNumber(value);
    return !!valid;
  };
  const onBlur = () => {
    setInvalidPhoneNumber(!validatePhoneNumber(phoneNumber));
  };
  useEffect(() => {
    if (!props.modalOpen) {
      setPhoneNumber("");
      // setInvalidPhoneNumber(false);
    } else {
      if (props.phoneNumber) {
        // let phoneNumberFromProp = parsePhoneNumber(props.phoneNumber, "US"); // Assuming the number is from the US
        // phoneNumberFromProp = phoneNumberFromProp ? phoneNumberFromProp.format("E.164") : null;
        setPhoneNumber(props.phoneNumber);
        // setInvalidPhoneNumber(!validatePhoneNumber(phoneNumberFromProp));
      }
      if (props.message) {
        setMessage(props.message);
      }
    }
    setLoading(false);
  }, [props.modalOpen]);
  const sendMessage = async () => {
    if (message) {
      let url = "practitioner-role/patient/send-text-to-phone";
      let payload = {
        contactPhone: phoneNumber,
        mode: "AUTO",
        text: message,
      };
      setLoading(true);
      try {
        let response = await post(url, payload);
        if (response?.data) {
          toast.success("SMS sent");
          props.toggleModal(false);
        } else {
          toast.error("Error sending SMS, please verify the phone number");
        }
      } catch (error) {
        toast.error("Error sending SMS, please verify the phone number");
      }
      setLoading(false);
    }
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="send-text-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title || "Send session link to patient"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="send-text-modal-body">
        <Form className="custom-form">
          <Form.Group
            className="custom-input-field"
            controlId="patient-phone-number-input"
          >
            <Form.Label>Patient Phone Number</Form.Label>
            <Form.Control
              // as={PhoneInput}
              type={"tel"}
              placeholder={"(201) 555-0123"}
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              disabled={!!loading}
            />
          </Form.Group>
          <Form.Group
            className="custom-input-field"
            controlId="patient-text-message-input"
          >
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              placeholder=""
              value={message}
              onChange={e => setMessage(e.target.value)}
              disabled={!!loading}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          onClick={sendMessage}
          disabled={!message || loading}
        >
          Send SMS
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}
