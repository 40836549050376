import React, { useState, useEffect } from "react";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import ClinicLogo from "@/assets/clinic.svg";
import { roleBusinessFunctionMapping } from "@/services/dummyData";
import "./OrgRoleLayout.scss";

function OrgRoleLayout(props) {
  const userRoles = props.userRoles;
  return (
    <div className="org-role-layout-container">
      <div className="dashboard-welcome-container">
        <div className="dashboard-welcome-msg">Hello {props.userData?.firstName}!</div>
        <TodayDateTime />
      </div>
      <div className="select-org-container">
        <div className="select-org-label">Select your organization</div>
        {userRoles?.length > 0 ? (
          <Accordion
            defaultActiveKey={userRoles[0].organizationId}
            className="orgs-list"
          >
            {userRoles.map(userRole => {
              return (
                <Accordion.Item
                  eventKey={userRole.organizationId}
                  key={userRole.organizationId}
                  className="org-item"
                >
                  <Accordion.Header className="org-item-name">
                    <img
                      src={ClinicLogo}
                      alt=""
                      className="org-icon"
                    />
                    {userRole.organizationName}
                  </Accordion.Header>
                  <Accordion.Body className="org-item-content">
                    <div className="roles-list">
                      {userRole.roles.map(role => {
                        let businessFunction = roleBusinessFunctionMapping(role.behaviorHealthRole);
                        return (
                          <div
                            className="role-item"
                            key={role.id}
                            onClick={() => props.selectUserRole(role)}
                          >
                            {businessFunction === "Clinical Care"
                              ? "Provider"
                              : businessFunction === "Care Operations"
                              ? "Care Coordinator"
                              : role.behaviorHealthRole}
                          </div>
                        );
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        ) : (
          <div className="no-org-found">
            You don't have any organization assigned to your account, please contact Organization
            Admin to assign you to the clinic.
          </div>
        )}
      </div>
    </div>
  );
}

export default OrgRoleLayout;

const TodayDateTime = () => {
  const [currentTime, setCurrentTime] = useState(moment());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);
  return (
    <div className="current-date-time">
      {moment(currentTime).format("dddd DD MMM YYYY, hh:mm A")}
    </div>
  );
};
