import React from "react";
import "./PredictionCards.scss";

function PredictionCards(props) {
  let { data } = props;
  return (
    <div className="prediction-cards-widget">
      <div className="widget-header">
        <div className="widget-title">Alerts</div>
      </div>
      <div className="prediction-cards-list">
        <div className={`prediction-card ${data?.emotionalDistress?.cardColor}`}>
          {data?.emotionalDistress?.intensityText} Emotional Distress
        </div>
        <div className={`prediction-card ${data?.depression?.cardColor}`}>
          {data?.depression?.intensityText} Depression Symptoms
        </div>
        <div className={`prediction-card ${data?.anxiety?.cardColor}`}>
          {data?.anxiety?.intensityText} Anxiety Symptoms
        </div>
        <div className={`prediction-card ${data?.suicideRisk?.cardColor}`}>
          Suicide Risk {data?.suicideRisk?.intensityText || "Not Present"}
        </div>
      </div>
    </div>
  );
}

export default PredictionCards;
