import React, { useState, useEffect } from "react";
import { Avatar, Loader, PrimaryButton, Search, SecondaryButton } from "@/components";
import AddUserModal from "./components/AddPatientModal";
import "./Users.scss";

function Users(props) {
  const [searchKey, setSearchKey] = useState("");
  const [addUserModaOpen, setUserModalOpen] = useState(false);
  let orgUsersData = [...props.orgUsers.data];
  orgUsersData = searchKey
    ? orgUsersData.filter(i => {
        let userName = `${i.practitionerFirstName} ${i.practitionerLastName}`;
        return !!userName.toLowerCase().includes(searchKey);
      })
    : orgUsersData;
  useEffect(() => {
    props.getOrgUsers();
  }, []);
  useEffect(() => {
    document.title = "Users";
  }, []);
  return (
    <div className="org-users-wrapper">
      <Loader loading={props.orgUsers.loading}>
        <div className="org-users-header">
          <Search
            placeholder={"Search User"}
            value={searchKey}
            onSearch={val => setSearchKey(val)}
            clearSearch={() => setSearchKey("")}
            noDebounce={true}
          />
          <div className="org-users-header-actions">
            <PrimaryButton
              className="add-user-btn"
              onClick={() => setUserModalOpen(true)}
            >
              Add User
            </PrimaryButton>
          </div>
        </div>
        <div className="org-users-list-container">
          <div className="org-users-list-header">
            {/* <div className="org-users-list-header-item">ID</div> */}
            <div className="org-users-list-header-item">First Name</div>
            <div className="org-users-list-header-item">Last Name</div>
            <div className="org-users-list-header-item">Email</div>
            <div className="org-users-list-header-item">Roles</div>
          </div>
          <div className="org-users-list">
            {orgUsersData.map(user => {
              return (
                <div
                  className="org-user-item"
                  key={user.practitionerId}
                >
                  {/* <div className="user-item-value">{user.practitionerId}</div> */}
                  <div className="user-item-value">{user.practitionerFirstName}</div>
                  <div className="user-item-value">{user.practitionerLastName}</div>
                  <div className="user-item-value">{user.practitionerEmail}</div>
                  <div className="user-item-value org-user-roles-list">
                    {user.roles.map(role => {
                      return <div className="org-user-role">{role.behaviorHealthRole}</div>;
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <AddUserModal
          modalOpen={addUserModaOpen}
          toggleModal={setUserModalOpen}
          addUser={props.addOrgUser}
        />
      </Loader>
    </div>
  );
}

export default Users;
