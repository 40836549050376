import Users from "./Users";
import { connect } from "react-redux";
import { getOrgUsers, addOrgUser } from "./usersModule";

const mapStateToProps = state => {
  return {
    orgUsers: state.orgUsers.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrgUsers: options => dispatch(getOrgUsers(options)),
    addOrgUser: options => dispatch(addOrgUser(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
