import Home from "./Home";
import { connect } from "react-redux";
import { selectUserRole, selectPreferredTimezone } from "@/store/modules/userModule";
import { getSessionsByPractitionerRole } from "../Appointments/appointmentsModule";

const mapStateToProps = state => {
  return {
    userData: state.user.userData,
    selectedUserRole: state.user.selectedUserRole,
    userRoles: state.user.userRoles,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectUserRole: selectedRole => dispatch(selectUserRole(selectedRole)),
    selectPreferredTimezone: timezone => dispatch(selectPreferredTimezone(timezone)),
    getSessionsByPractitionerRole: options => dispatch(getSessionsByPractitionerRole(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
