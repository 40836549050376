import Tasks from "./Tasks";
import { connect } from "react-redux";
import { getAllTaskStatus } from "@/store/modules/userModule";

const mapStateToProps = state => {
  return {
    tasks: state.user.tasks,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllTaskStatus: options => dispatch(getAllTaskStatus(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
