import React, { useState, useEffect, useCallback } from "react";
import { Form, Modal, Dropdown } from "react-bootstrap";
import { MemberAvatar, PrimaryButton, Loader, SecondaryButton } from "@/components";
import InPersonIcon from "@/assets/sofa.svg";
import VirtualIcon from "@/assets/video-recorder.svg";
import DeleteIcon from "@/assets/delete-icon.svg";
import { assignPractitioners, assignPatients } from "../../groupsModule";
import { groupByPractitioner } from "@/Routes/Users/usersModule";
import moment from "moment-timezone";
import "./AddGroupModal.scss";

const dayMapping = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

function AddGroupModal(props) {
  const [activeStep, setActiveStep] = useState("group"); //group, provider, patient
  const [groupName, setGroupName] = useState("");
  const [modeOfDelivery, setModeOfDelivery] = useState("in-person"); //in-person,virtual
  const [autoScheduling, setAutoScheduling] = useState(false);
  const [autoSchedulingDay, setAutoSchedulingDay] = useState("MONDAY");
  const [autoSchedulingHour, setAutoSchedulingHour] = useState("");
  const [autoSchedulingMinute, setAutoSchedulingMinute] = useState("");
  const [autoSchedulingTimeOfDay, setAutoSchedulingTimeOfDay] = useState("AM"); //AM, PM
  const [autoSchedulingSessionMinutes, setAutoSchedulingSessionMinutes] = useState(30);
  const [patientSessionRecurrenceType, setPatientSessionRecurrenceType] = useState("FLEXIBLE");
  const [groupOpennessType, setGroupOpennessType] = useState("OPEN"); //OPEN, CLOSED
  const [patientsList, setPatientsList] = useState([]);
  const [practitionersList, setPractitionersList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setInitData();
  }, [props.modalOpen, props.isEdit, props.data]);
  const setInitData = useCallback(() => {
    let isEdit = props.isEdit;
    let modalOpen = props.modalOpen;
    let data = props.data || null;
    let usePropData = isEdit && modalOpen && data;
    setGroupName(usePropData ? data?.groupName || "" : "");
    setModeOfDelivery(
      usePropData ? (data?.modeOfDelivery === "VIRTUAL" ? "virtual" : "in-person") : "in-person"
    );
    setAutoScheduling(usePropData ? data?.schedulingPreferences === "AUTO_SCHEDULING" : false);
    setAutoSchedulingDay(usePropData ? data?.dayOfWeek || "" : "MONDAY");
    setAutoSchedulingHour(
      usePropData
        ? data?.startHour || data?.startHour === 0
          ? data.startHour === 0
            ? 12
            : data.startHour > 12
            ? data.startHour - 12
            : data.startHour
          : ""
        : ""
    );
    setAutoSchedulingMinute(
      usePropData ? (data?.startMinute || data?.startMinute === 0 ? data?.startMinute : "") : ""
    );
    setAutoSchedulingTimeOfDay(
      usePropData ? (data?.startHour ? (data.startHour >= 12 ? "PM" : "AM") : "AM") : "AM"
    );
    setAutoSchedulingSessionMinutes(usePropData ? data?.sessionDurationInMinutes || "" : 30);
    setPatientSessionRecurrenceType(
      usePropData ? data?.patientSessionRecurrenceType || "FLEXIBLE" : "FLEXIBLE"
    );
    setGroupOpennessType(usePropData ? data?.groupOpennessType || "OPEN" : "OPEN");
    setPatientsList(
      usePropData
        ? data?.groupPatientMembershipDtos?.map(i => {
            return {
              ...i,
              alreadyAdded: true,
            };
          }) || []
        : []
    );
    setPractitionersList(
      usePropData
        ? data?.groupPractitionerRoleDtos
          ? groupByPractitioner(data.groupPractitionerRoleDtos)?.map(i => {
              return {
                ...i,
                alreadyAdded: true,
              };
            })
          : []
        : []
    );
    setActiveStep(usePropData ? "provider" : "group");
  }, [props.modalOpen, props.isEdit, props.data]);
  const patientsData = props.patients.data;
  useEffect(() => {
    if (patientsData?.length === 0 && !props.patients.loading) {
      props.getPatients();
    }
  }, []);
  const orgUsersData = props.orgUsers.data;
  useEffect(() => {
    if (orgUsersData?.length === 0 && !props.orgUsers.loading) {
      props.getOrgUsers();
    }
  }, []);
  const isFormComplete = () => {
    let completed = false;
    if (
      groupName &&
      modeOfDelivery &&
      (!autoScheduling ||
        (autoScheduling &&
          autoSchedulingDay &&
          autoSchedulingHour &&
          (autoSchedulingMinute || autoSchedulingMinute === 0) &&
          autoSchedulingTimeOfDay &&
          autoSchedulingSessionMinutes)) &&
      patientSessionRecurrenceType &&
      groupOpennessType
    ) {
      completed = true;
    }
    return completed;
  };
  const addPatient = patient => {
    if (patient?.id) {
      setPatientsList(prevData => {
        let patientIds = prevData.map(i => i.patientId);
        let patientId = patient.id;
        if (patientIds.includes(patientId)) {
          return prevData;
        }
        return [
          ...prevData,
          {
            ...patient,
            patientId,
          },
        ];
      });
    }
  };
  const removePatient = patientId => {
    setPatientsList(prevData => {
      let data = prevData.filter(i => i.patientId !== patientId);
      return data;
    });
  };
  const removePractitioner = practitionerId => {
    setPractitionersList(prevData => {
      let data = prevData.filter(i => i.practitionerId !== practitionerId);
      return data;
    });
  };
  const addPractitioner = practitioner => {
    if (practitioner?.practitionerId) {
      setPractitionersList(prevData => {
        let practitionerIds = prevData.map(i => i.practitionerId);
        let practitionerId = practitioner.practitionerId;
        if (practitionerIds.includes(practitionerId)) {
          return prevData;
        }
        return [
          ...prevData,
          {
            ...practitioner,
            practitionerId,
          },
        ];
      });
    }
  };
  const addGroup = async () => {
    if (isFormComplete()) {
      setLoading(true);
      let dayOfWeek = null;
      let startHour = null;
      let startMinute = null;
      if (autoScheduling) {
        let schedulingHour = autoSchedulingHour;
        if (autoSchedulingHour && autoSchedulingTimeOfDay) {
          if (autoSchedulingTimeOfDay === "AM") {
            if (schedulingHour === 12) {
              schedulingHour = 0;
            }
          } else {
            if (schedulingHour !== 12) {
              schedulingHour = schedulingHour + 12;
            }
          }
        }
        const selectedTime = moment
          .tz(props.preferredTimezone?.name || "America/New_York")
          .day(dayMapping[autoSchedulingDay])
          .hour(schedulingHour)
          .minute(autoSchedulingMinute);
        const selectedTimeInPt = selectedTime.clone().tz("America/Los_Angeles");
        dayOfWeek = Object.keys(dayMapping).find(day => dayMapping[day] === selectedTimeInPt.day());
        startHour = selectedTimeInPt.hours();
        startMinute = selectedTimeInPt.minutes();
      }
      let payload = {
        groupName: groupName,
        startHour: !!autoScheduling ? startHour : null,
        startMinute: !!autoScheduling ? startMinute : null,
        dayOfWeek: !!autoScheduling ? dayOfWeek : null,
        sessionDurationInMinutes: !!autoScheduling ? autoSchedulingSessionMinutes : null,
        modeOfDelivery: modeOfDelivery === "in-person" ? "IN_PERSON" : "VIRTUAL",
        groupOpennessType: groupOpennessType,
        schedulingPreferences: !!autoScheduling ? "AUTO_SCHEDULING" : "NO_AUTO_SCHEDULING",
        patientSessionRecurrenceType: patientSessionRecurrenceType,
      };
      let groupId = null;
      if (!props.isEdit) {
        groupId = await props.addGroup({
          payload,
        });
      } else {
        groupId = props.data?.id;
      }
      if (groupId && practitionersList?.length > 0) {
        let filteredData = practitionersList.filter(i => !i.alreadyAdded);
        if (filteredData?.length > 0) {
          await assignPractitioners({ practitioners: practitionersList, groupId });
        }
      }
      if (groupId && patientsList?.length > 0 && patientSessionRecurrenceType === "FLEXIBLE") {
        let filteredData = patientsList.filter(i => !i.alreadyAdded);
        if (filteredData?.length > 0) {
          await assignPatients({ patients: patientsList, groupId });
        }
      }
      setLoading(false);
      props.getGroups();
      props.toggleModal(false);
    }
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="add-group-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{!props.isEdit ? "Add Group" : "Edit Group"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-group-modal-body">
        {activeStep === "group" && (
          <Form className="add-group-form custom-form">
            <Form.Group
              className="custom-input-field"
              controlId="group-name-input"
            >
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Group Name"
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
                disabled={!!loading || props.isEdit}
              />
            </Form.Group>
            {/* <div className="session-type-container">
              <div className="session-type-label">Session Type:</div>
              <div className="session-type">
                <Form.Check
                  type="radio"
                  label={
                    <div className="session-type-option-label">
                      <img
                        className="session-type-option-icon"
                        src={InPersonIcon}
                      />
                      In-Office
                    </div>
                  }
                  name={"in-person"}
                  id={`session-type-in-person`}
                  checked={modeOfDelivery === "in-person"}
                  onChange={() => setModeOfDelivery("in-person")}
                  className="session-type-option-radio-button"
                />
              </div>
              <div className="session-type">
                <Form.Check
                  type="radio"
                  label={
                    <div className="session-type-option-label">
                      <img
                        className="session-type-option-icon"
                        src={VirtualIcon}
                      />
                      Online
                    </div>
                  }
                  name={"virtual"}
                  id={`session-type-virtual`}
                  checked={modeOfDelivery === "virtual"}
                  onChange={() => setModeOfDelivery("virtual")}
                  className="session-type-option-radio-button"
                />
              </div>
            </div> */}
            <Form.Group
              className="custom-input-field auto-scheduling-input"
              controlId="autoscheduling-input"
            >
              <Form.Label>Auto-Schedule Sessions:</Form.Label>
              <Form.Check
                type="switch"
                id="auto-schedule-switch"
                label={!!autoScheduling ? "On" : "Off"}
                checked={!!autoScheduling}
                onChange={() => setAutoScheduling(!autoScheduling)}
                disabled={!!loading || props.isEdit}
              />
            </Form.Group>
            {!!autoScheduling && (
              <>
                <Form.Group className="custom-input-field auto-scheduling-options-container">
                  <Form.Label>Every</Form.Label>
                  <Dropdown className="custom-dropdown">
                    <Dropdown.Toggle disabled={!!loading || props.isEdit}>
                      {!!autoSchedulingDay ? autoSchedulingDay : "Select Day"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[
                        "SUNDAY",
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY",
                        "SATURDAY",
                      ].map((day, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => setAutoSchedulingDay(day)}
                            key={index}
                          >
                            {day}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Label>@</Form.Label>
                  <Dropdown className="custom-dropdown">
                    <Dropdown.Toggle disabled={!!loading || props.isEdit}>
                      {!!autoSchedulingHour
                        ? `${
                            String(autoSchedulingHour).length === 1 ? "0" : ""
                          }${autoSchedulingHour}`
                        : "HH"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((hour, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => setAutoSchedulingHour(hour)}
                            key={index}
                          >
                            {String(hour).length === 1 ? "0" : ""}
                            {hour}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Label>:</Form.Label>
                  <Dropdown className="custom-dropdown">
                    <Dropdown.Toggle disabled={!!loading || props.isEdit}>
                      {!!autoSchedulingMinute || autoSchedulingMinute === 0
                        ? `${
                            String(autoSchedulingMinute).length === 1 ? "0" : ""
                          }${autoSchedulingMinute}`
                        : "MM"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[0, 15, 30, 45, 60].map((minute, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => setAutoSchedulingMinute(minute)}
                            key={index}
                          >
                            {String(minute).length === 1 ? "0" : ""}
                            {minute}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="custom-dropdown">
                    <Dropdown.Toggle disabled={!!loading || props.isEdit}>
                      {!!autoSchedulingTimeOfDay ? autoSchedulingTimeOfDay : "AM"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {["AM", "PM"].map((i, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => setAutoSchedulingTimeOfDay(i)}
                            key={index}
                          >
                            {i}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Label>{props.preferredTimezone?.abbr || "PT"}</Form.Label>
                </Form.Group>
                <Form.Group className="custom-input-field">
                  <Form.Label>Session Duration</Form.Label>
                  <Dropdown className="custom-dropdown">
                    <Dropdown.Toggle disabled={!!loading || props.isEdit}>
                      {!!autoSchedulingSessionMinutes
                        ? `${autoSchedulingSessionMinutes} mins`
                        : "Select Duration"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[15, 30, 45, 60].map((mins, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => setAutoSchedulingSessionMinutes(mins)}
                            key={index}
                          >
                            {mins} mins
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </>
            )}
          </Form>
        )}
        {activeStep === "provider" && (
          <Form className="add-group-form custom-form">
            <Form.Group className="custom-input-field select-patients-container">
              <Form.Label>
                Practitioners{" "}
                <span className="no-of-patients">{practitionersList.length} practitioners</span>
              </Form.Label>
              <Dropdown className="custom-dropdown">
                <Dropdown.Toggle>Add Practitioner</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Loader loading={props.orgUsers.loading}>
                    {orgUsersData.length > 0 &&
                      orgUsersData.map(practitioner => {
                        return (
                          <Dropdown.Item
                            onClick={() => addPractitioner(practitioner)}
                            key={practitioner.practitionerId}
                            className="select-provider-dropdown-item"
                          >
                            <div className="select-provider-dropdown-item-top">
                              <MemberAvatar
                                name={`${practitioner?.practitionerFirstName || ""} ${
                                  practitioner?.practitionerLastName || ""
                                }`}
                                size={"small"}
                                showFullName={true}
                              />
                              <div className="practitioner-roles">
                                <div className="role">{practitioner.practitionerEmail}</div>
                              </div>
                            </div>
                            <div className="practitioner-roles">
                              {practitioner.roles.map(role => {
                                return (
                                  <div
                                    className="role"
                                    key={role.id}
                                  >
                                    {role.behaviorHealthRole}
                                  </div>
                                );
                              })}
                            </div>
                          </Dropdown.Item>
                        );
                      })}
                  </Loader>
                </Dropdown.Menu>
              </Dropdown>
              {practitionersList.length > 0 && (
                <ul className="patients-list">
                  {practitionersList.map((practitioner, index) => {
                    return (
                      <li
                        className="patient-item"
                        key={practitioner.practitionerId}
                      >
                        <div className="practitioner-index">{index + 1}.</div>
                        <div className="practitioner-details">
                          <MemberAvatar
                            name={`${
                              practitioner.practitionerFirstName || practitioner.firstName || ""
                            } ${practitioner.practitionerLastName || practitioner.lastName || ""}`}
                            showFullName={true}
                            size={"small"}
                          />
                          <div className="practitioner-roles">
                            {practitioner.roles.map(role => {
                              return (
                                <div
                                  className="role"
                                  key={role.id}
                                >
                                  {role.behaviorHealthRole}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {!practitioner.alreadyAdded && (
                          <div
                            className="remove-patient"
                            onClick={() => removePractitioner(practitioner.practitionerId)}
                          >
                            <img
                              src={DeleteIcon}
                              alt=""
                            />
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </Form.Group>
          </Form>
        )}
        {activeStep === "patient" && (
          <Form className="add-group-form custom-form">
            {/* <div className="session-type-container">
              <div className="session-type-label">Patient Recurrence Type:</div>
              <div className="session-type">
                <Form.Check
                  type="radio"
                  label={<div className="session-type-option-label">Flexible</div>}
                  name={"FLEXIBLE"}
                  id={`session-type-FLEXIBLE`}
                  checked={patientSessionRecurrenceType === "FLEXIBLE"}
                  onChange={() => setPatientSessionRecurrenceType("FLEXIBLE")}
                  className="session-type-option-radio-button"
                />
              </div>
              <div className="session-type">
                <Form.Check
                  type="radio"
                  label={<div className="session-type-option-label">AD HOC</div>}
                  name={"AD_HOC"}
                  id={`session-type-AD_HOC`}
                  checked={patientSessionRecurrenceType === "AD_HOC"}
                  onChange={() => setPatientSessionRecurrenceType("AD_HOC")}
                  className="session-type-option-radio-button"
                />
              </div>
              <div className="session-type">
                <Form.Check
                  type="radio"
                  label={<div className="session-type-option-label">Drop-in</div>}
                  name={"DROP_IN"}
                  id={`session-type-DROP_IN`}
                  checked={patientSessionRecurrenceType === "DROP_IN"}
                  onChange={() => setPatientSessionRecurrenceType("DROP_IN")}
                  className="session-type-option-radio-button"
                />
              </div>
            </div> */}
            {patientSessionRecurrenceType === "FLEXIBLE" && (
              <Form.Group className="custom-input-field select-patients-container">
                <Form.Label>
                  Patients <span className="no-of-patients">{patientsList.length} patients</span>
                </Form.Label>
                <Dropdown className="custom-dropdown">
                  <Dropdown.Toggle>Add Patient</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Loader loading={props.patients.loading}>
                      {patientsData.length > 0 &&
                        patientsData.map((patient, index) => {
                          return (
                            <Dropdown.Item
                              onClick={() => addPatient(patient)}
                              key={patient.id || patient.patientId || index}
                            >
                              <MemberAvatar
                                name={`${patient?.firstName || ""} ${patient?.lastName || ""}`}
                                size={"small"}
                                showFullName={true}
                              />
                            </Dropdown.Item>
                          );
                        })}
                    </Loader>
                  </Dropdown.Menu>
                </Dropdown>
                {patientsList.length > 0 && (
                  <ul className="patients-list">
                    {patientsList.map((patient, index) => {
                      return (
                        <li
                          className="patient-item"
                          key={patient.patientId}
                        >
                          <div className="patientId-index">{index + 1}.</div>
                          <MemberAvatar
                            name={`${patient.patientFirstName || patient.firstName || ""} ${
                              patient.patientLastName || patient.lastName || ""
                            }`}
                            showFullName={true}
                            size={"small"}
                          />
                          {!patient.alreadyAdded && (
                            <div
                              className="remove-patient"
                              onClick={() => removePatient(patient.patientId)}
                            >
                              <img
                                src={DeleteIcon}
                                alt=""
                              />
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </Form.Group>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {activeStep === "group" && (
          <PrimaryButton
            onClick={() => setActiveStep("provider")}
            disabled={!isFormComplete()}
          >
            Next
          </PrimaryButton>
        )}
        {activeStep === "provider" && (
          <>
            <SecondaryButton onClick={() => setActiveStep("group")}>Prev</SecondaryButton>
            <PrimaryButton onClick={() => setActiveStep("patient")}>Next</PrimaryButton>
          </>
        )}
        {activeStep === "patient" && (
          <>
            <SecondaryButton onClick={() => setActiveStep("provider")}>Prev</SecondaryButton>
            <PrimaryButton
              onClick={addGroup}
              disabled={loading}
            >
              Submit
            </PrimaryButton>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default AddGroupModal;
