import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { rawPost, rawGet } from "../../services/api";
import { saveCookie } from "@/services/utils";

const useSignup = props => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signupError, setSignupError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Signup";
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlEmail = urlParams.get("email");
    const urlToken = urlParams.get("token");
    setEmail(urlEmail);
    setToken(urlToken);
    if (!urlEmail || !urlToken) {
      setSignupError("Invalid URL, please check the verification link from the email");
    }
  }, []);

  const validateForm = () => {
    let valid = true;
    if (confirmPassword && password && confirmPassword !== password) {
      setPasswordError(`Password doesn't match`);
      valid = false;
    } else {
      setPasswordError("");
    }
    if (validateEmail()) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email");
      valid = false;
    }
    if (!email || !password || !confirmPassword || !termsAccepted) {
      valid = false;
    }
    return valid;
  };
  const isButtonDisabled = () => {
    let disabled = false;
    if (
      !email ||
      !password ||
      !confirmPassword ||
      !termsAccepted ||
      !token ||
      emailError ||
      passwordError
    ) {
      disabled = true;
    }
    return disabled;
  };
  const validateEmail = () => {
    return email
      ? String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      : true;
  };
  const onSignup = async () => {
    let url = `registration/practitioner/accept-invite-and-signup`;
    let payload = {
      email,
      password,
      confirmationToken: token,
    };
    setLoading(true);
    setSignupError("");
    try {
      let response = await rawPost(url, payload);
      setLoading(false);
      navigate("/login", { replace: true });
    } catch (error) {
      console.log(error);
      setSignupError("Account already exists. Please sign in to access your account.");
      setLoading(false);
    }
  };
  return {
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    termsAccepted,
    setTermsAccepted,
    hidePassword,
    setHidePassword,
    emailError,
    passwordError,
    validateForm,
    isButtonDisabled,
    loading,
    onSignup,
    signupError,
  };
};

export default useSignup;
