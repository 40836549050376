import Button from "react-bootstrap/Button";
import "./Buttons.scss";
import { useState } from "react";

const PrimaryButton = props => {
  return (
    <Button
      variant="primary"
      className={`primary-btn basic-btn-structure ${props.className || ""}`}
      disabled={!!props.disabled}
      onClick={props.onClick || (() => {})}
      id={props.id}
    >
      {props.children}
    </Button>
  );
};

const SecondaryButton = props => {
  return (
    <Button
      variant="secondary"
      className={`secondary-btn basic-btn-structure ${props.className || ""}`}
      disabled={!!props.disabled}
      onClick={props.onClick || (() => {})}
      id={props.id}
    >
      {props.children}
    </Button>
  );
};

const SecondaryButtonWithIcon = props => {
  const [hovered, setHovered] = useState(false);
  return (
    <Button
      variant="secondary"
      className={`secondary-btn with-icon basic-btn-structure ${props.className || ""}`}
      disabled={!!props.disabled}
      onClick={props.onClick || (() => {})}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {props.icon && (
        <img
          src={hovered && props.hoverIcon ? props.hoverIcon : props.icon}
          className={`button-icon ${props.hoverIcon ? "hover-icon" : ""}`}
          alt=""
        />
      )}
      {props.children}
    </Button>
  );
};

export { PrimaryButton, SecondaryButton, SecondaryButtonWithIcon };
