import React from "react";
import { Modal } from "react-bootstrap";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import "./AlertModal.scss";

function AlertModal(props) {
  return (
    <Modal
      show={props.modalOpen}
      onHide={props.onCancel}
      className="alert-modal"
    >
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.desc}</Modal.Body>
      <Modal.Footer>
        <SecondaryButton onClick={props.onConfirm}>
          {props.confirmBtnText || "Confirm"}
        </SecondaryButton>
        <PrimaryButton onClick={props.onCancel}>{props.cancelBtnText || "Cancel"}</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

export { AlertModal };
