const initState = {
  appLoading: false,
  hideSideBar: false,
};

const ACTION_HANDLERS = {
  ["TOGGLE_APP_LOADING"]: (state, action) => {
    return {
      ...state,
      appLoading: action.show,
    };
  },
  ["TOGGLE_HIDE_SIDEBAR"]: (state, action) => {
    return {
      ...state,
      hideSideBar: action.flag,
    };
  },
};

export default function appReducer(state = initState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
