import React, { useState, useEffect } from "react";
import "./Appointments.scss";
import Sessions from "./components/Sessions";
import { PrimaryButton } from "@/components";
import EHRLogo from "@/assets/ehr-logo.png";
import CalendarDaysIcon from "@/assets/calendar-days.svg";

function Appointments(props) {
  const practitionerRoleId = props.selectedUserRole?.id;
  useEffect(() => {
    props.getSessionsByPractitionerRole();
  }, []);
  useEffect(() => {
    document.title = "Appointments";
  }, []);
  useEffect(() => {
    console.log("patientMentalWellnessData", props.sessions.patientMentalWellnessData);
  }, [props.sessions?.patientMentalWellnessData]);
  useEffect(() => {
    console.log("groupMentalWellnessData", props.sessions.groupMentalWellnessData);
  }, [props.sessions?.groupMentalWellnessData]);
  return (
    <div className="appointments-page">
      <Sessions
        sessions={props.sessions}
        practitionerRoleId={practitionerRoleId}
        selectedUserRole={props.selectedUserRole}
        getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
        preferredTimezone={props.preferredTimezone}
      />
      <div className="ehr-not-integrated-container">
        <div className="ehr-logo-container">
          <img
            src={EHRLogo}
            alt=""
            className="ehr-logo"
          />
          <span>+</span>
          <img
            src={CalendarDaysIcon}
            alt=""
            className="ehr-logo"
          />
        </div>
        <div className="ehr-integration-title">Connect your EHR Platform</div>
        <div className="ehr-integration-message">
          Sync all your appointments by connecting your EHR Platform.
        </div>
        {props.selectedUserRole?.businessFunction === "Clinical Care" && false && (
          <>
            <div className="ehr-container-separator">OR</div>
            <PrimaryButton onClick={() => props.setShowStartSessionModal(true)}>
              Start an Instant Session
            </PrimaryButton>
          </>
        )}
      </div>
    </div>
  );
}

export default Appointments;
