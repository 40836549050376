import Groups from "./Groups";
import { connect } from "react-redux";
import { getGroups, addGroup } from "./groupsModule";

const mapStateToProps = state => {
  return {
    groups: state.groups.groups,
    selectedUserRole: state.user.selectedUserRole,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGroups: options => dispatch(getGroups(options)),
    addGroup: options => dispatch(addGroup(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
