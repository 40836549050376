import React, { useState, useEffect, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactQuill from "react-quill";
import CopyIcon from "@/assets/copy.svg";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.scss";

function copyToClip(str) {
  function listener(e) {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
}

export const RichTextEditor = props => {
  const [textCopied, setTextCopied] = useState(false);
  let modules = {
    clipboard: {
      matchVisual: true,
    },
  };
  if (props.readOnly) {
    modules = {
      ...modules,
      toolbar: "",
    };
  }
  const quill = useRef();
  const copyData = () => {
    console.log(quill.current);
    let text = quill.current.value;
    copyToClip(text);
    setTextCopied(true);
    setTimeout(() => {
      setTextCopied(false);
    }, 3000);
  };
  return (
    <div className="rich-text-editor-container">
      <ReactQuill
        ref={quill}
        theme="snow"
        value={props.value}
        onChange={props.setValue}
        readOnly={props.readOnly}
        modules={modules}
        className={`rich-text-editor ${props.readOnly ? "read-only" : ""}`}
      />
      {!props.readOnly && !props.hideCopy && (
        // <OverlayTrigger
        //   placement="left"
        //   overlay={
        //     <Tooltip>
        //       <span>{!!textCopied ? "Copied" : "Copy"}</span>
        //     </Tooltip>
        //   }
        // >
        <div
          className="copy-btn"
          onClick={copyData}
        >
          {textCopied ? "Notes Copied" : "Copy Notes"}
        </div>
        // </OverlayTrigger>
      )}
    </div>
  );
};
