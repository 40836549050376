import React, { useState } from "react";
import "./Avatar.scss";

const Avatar = props => {
  const { imageSrc, name = "User", placeholderImg } = props;
  const [firstName = "", lastName = ""] = name.split(" ");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const renderAvatarPlaceholder = () => {
    return (
      <div className={`user-avatar ${props.className || ""}`}>
        {!!placeholderImg ? (
          <img
            src={placeholderImg}
            alt={""}
            className="avatar-image"
          />
        ) : (
          <span className="avatar-text">
            {firstName[0] || ""}
            {lastName[0] || ""}
          </span>
        )}
      </div>
    );
  };
  if (hasError || !imageSrc) {
    return renderAvatarPlaceholder();
  }
  return (
    <div className={`user-avatar ${props.className || ""}`}>
      {!imageLoaded && renderAvatarPlaceholder()}
      <img
        src={imageSrc}
        alt={""}
        className="avatar-image"
        style={!imageLoaded ? { width: 0 } : {}}
        onError={() => {
          setHasError(true);
        }}
        onLoad={() => {
          setImageLoaded(true);
        }}
      />
    </div>
  );
};

const MemberAvatar = props => {
  return (
    <div className={`member-avatar-wrapper ${props.size || ""}`}>
      <div className="member-avatar">
        <Avatar
          imageSrc={props.memberPhoto}
          name={props.name}
          placeholderImg={props.placeholderImg}
        />
        {props.isNew && <span className="new-tag">New</span>}
      </div>
      <div className="member-name">
        {props.name ? (props.showFullName ? props.name : props.name.split(" ")[0]) : ""}
        {props.location && <div className="member-location">{props.location}</div>}
      </div>
    </div>
  );
};

export { Avatar, MemberAvatar };
