import React, { useState } from "react";
import { MemberNotes, Loader } from "@/components";
import { useDyteSelector } from "@dytesdk/react-web-core";
import SideBarCloseImg from "@/assets/side-bar-close.svg";
import "./SessionMemberNotes.scss";

function SessionMemberNotes(props) {
  const [hideNotes, setHideNotes] = useState(false);
  const memberNotesData = props.memberNotes;
  return (
    <div className={`session-member-notes-container ${hideNotes ? "hide-bar" : ""}`}>
      <Loader loading={props?.memberNotes?.loading}>
        <div className="session-member-note-wrapper">
          <MemberNotes
            data={{
              ...memberNotesData,
              name: `${memberNotesData.firstName || ""}${memberNotesData.lastName ? " " : ""}${
                memberNotesData.lastName || ""
              }`,
            }}
            sessionDetailsData={props.sessionDetailsData}
            preferredTimezone={props.preferredTimezone}
            hideSuggestionTag={true}
            sessionType={props.sessionType}
          />
        </div>
      </Loader>
    </div>
  );
}

export default SessionMemberNotes;
