import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import useLogin from "./useLogin";
import { PrimaryButton } from "../../components";

import PasswordIcon from "../../assets/password.svg";
import PasswordHideIcon from "../../assets/password-hide.svg";

import "./Login.scss";

const Login = props => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    hidePassword,
    setHidePassword,
    emailError,
    passwordError,
    validateForm,
    isButtonDisabled,
    loading,
    onLogin,
    loginError,
  } = useLogin(props);
  return (
    <div className="login-page">
      <div className="form-wrapper">
        <div className="form-container">
          <div className="form-title">Sign in to continue</div>
          <Form className="custom-form">
            <Form.Group
              className="custom-input-field"
              controlId="register-email-input"
            >
              <Form.Control
                type="email"
                placeholder="Email ID"
                // id="register-email-input"
                value={email}
                onChange={e => setEmail(e.target.value)}
                isInvalid={!!emailError}
                onBlur={validateForm}
                disabled={!!loading}
              />
              {emailError && (
                <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              className="custom-input-field with-password"
              controlId="register-password-input"
            >
              <Form.Control
                type={hidePassword ? "password" : "text"}
                placeholder="Password"
                // id="register-password-input"
                value={password}
                onChange={e => setPassword(e.target.value)}
                onBlur={validateForm}
                disabled={!!loading}
              />
              <img
                src={hidePassword ? PasswordHideIcon : PasswordIcon}
                alt=""
                className="password-hide-icon"
                onClick={() => setHidePassword(hidePassword => !hidePassword)}
              />
            </Form.Group>
            {loginError && <div className="login-error">{loginError}</div>}
            <PrimaryButton
              className="form-submit-btn"
              disabled={!!isButtonDisabled() || !!loading}
              onClick={onLogin}
            >
              {!loading ? "Let’s Dive Back In" : "Logging in..."}
            </PrimaryButton>
          </Form>
          <div className="sign-in-prompt">
            <Link
              to={`/reset-password${email ? `?email=${window.encodeURIComponent(email)}` : ""}`}
              className="colored-link"
            >
              Forgotten your password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
