import { combineReducers } from "redux";
import app from "./appReducer";
import user from "./userReducer";
import appointments from "@/Routes/Appointments/appointmentsModule";
import session from "@/Routes/Session/sessionModule";
import sessionDetails from "@/Routes/SessionDetails/sessionDetailsModule";
import patientDetails from "@/Routes/PatientDetails/patientDetailsModule";
import patients from "@/Routes/Patients/patientsModule";
import orgUsers from "@/Routes/Users/usersModule";
import groups from "@/Routes/Groups/groupsModule";

export default combineReducers({
  app,
  user,
  appointments,
  session,
  sessionDetails,
  patients,
  patientDetails,
  orgUsers,
  groups,
});
