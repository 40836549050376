import React from "react";
import MemberInsights from "./components/MemberInsights";
import PeerConnection from "./components/PeerConnection";
import "./GroupMemberInsights.scss";

function GroupMemberInsights(props) {
  return (
    <div className="group-member-insights-container">
      {/* <div className="demo-deta-disclaimer">*Demo data</div> */}
      <div className="group-member-insights-content">
        <MemberInsights
          sessionId={props.sessionId}
          soapNotesJson={props.soapNotesJson}
        />
        <PeerConnection
          sessionId={props.sessionId}
          soapNotesJson={props.soapNotesJson}
        />
      </div>
    </div>
  );
}

export default GroupMemberInsights;
