import { get, post, rawPost } from "@/services/api";
import { getLocalStorage } from "@/services/utils";
import { getSessionsByPractitionerRole } from "../Appointments/appointmentsModule";
import { getProviderSessionIds } from "../SessionDetails/sessionDetailsModule";
import moment from "moment-timezone";
import { getOldSessionTranscriptData } from "@/store/modules/userModule";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const getPatientCompletedSessions = patientId => {
  return async (dispatch, getState) => {
    let state = getState();
    let allSessions = state.appointments.sessions?.data || [];
    if (allSessions.length === 0) {
      allSessions = await dispatch(getSessionsByPractitionerRole());
    }
    let completedSessions = allSessions.filter(
      session =>
        !!session.endTime &&
        new Date(session.endTime) < new Date() &&
        session.sessionCategory === "INDIVIDUAL"
    );
    completedSessions = completedSessions.sort(
      (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
    );
    completedSessions = completedSessions.filter(i => i.patientId === patientId);
    return completedSessions;
  };
};

// /session-member-notes/patient/{patientId}/find
export const getSessionSoapNotes = async options => {
  let sessionSoapNotes = [];
  let url = `practitioner-role/session-member-notes/session/${options?.sessionId}/find`;
  try {
    let response = await get(url);
    if (response?.data?.length > 0) {
      sessionSoapNotes = response.data;
    }
  } catch (error) {}
  return sessionSoapNotes;
};

export const getLatestSoapNotes = options => {
  return async dispatch => {
    let patientId = options?.patientId;
    let latestSoapNote = null;
    let latestBhPredictions = null;
    let soapNotesSession = null;
    let bhPredictionsSession = null;
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_SOAP_NOTES_LOADING", show: true });
    let previousSessions = await dispatch(getPatientCompletedSessions(patientId));
    for (let i = 0; i < previousSessions.length; i++) {
      let sessionId = previousSessions[i].id;
      let sessionSoapNotes = await getSessionSoapNotes({ ...options, sessionId });
      let userSoapNote = sessionSoapNotes.find(i => i.patientId === patientId);
      if (userSoapNote?.soapNote) {
        latestSoapNote = userSoapNote.soapNote;
        soapNotesSession = previousSessions[i];
      }
      if (userSoapNote?.behaviouralHealthPredictions) {
        latestBhPredictions = userSoapNote.behaviouralHealthPredictions;
        bhPredictionsSession = previousSessions[i];
      }
      if (latestSoapNote && latestBhPredictions) {
        soapNotesSession = previousSessions[i];
        bhPredictionsSession = previousSessions[i];
        console.log("session found", sessionId, patientId);
        break;
      }
      console.log("not found", sessionId);
    }
    dispatch({
      type: "ADD_PATIENT_DETAILS_SOAP_NOTES_DATA",
      data: latestSoapNote,
      session: soapNotesSession,
    });
    dispatch({
      type: "ADD_PATIENT_DETAILS_BH_PREDICTIONS_DATA",
      data: latestBhPredictions,
      session: bhPredictionsSession,
    });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_SOAP_NOTES_LOADING", show: false });
  };
};

export const getPatientPrivateNotes = options => {
  return async dispatch => {
    let userId = options?.patientId;
    let groupId = options?.groupId;
    let url = `member-notes/${groupId}/find`;
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_PRIVATE_NOTES_LOADING", show: true });
    let userNotes = "";
    try {
      let response = await get(url);
      if (response?.data?.length > 0) {
        let data = response.data.find(i => i.userId === userId);
        userNotes = data?.cumulativeNotes || "";
      }
    } catch (error) {}
    dispatch({ type: "ADD_PATIENT_DETAILS_PRIVATE_NOTES_DATA", data: userNotes });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_PRIVATE_NOTES_LOADING", show: false });
  };
};

export const generateDischargeSummary = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let preferredTimezone = state.user.preferredTimezone;
    let sessions = options?.sessions;
    let dischargeSummaryPayload = { transcriptAndOtherInfoDtos: [] };
    let dischargeSummaryData = null;
    let promises = [];
    for (let i = 0; i < sessions.length; i++) {
      let session = sessions[i];
      let sessionDate = new Date(session.startTime);
      let updateDate = new Date("2024-09-09T06:00:00.000+05:30");
      let inPersonOldSession = sessionDate < updateDate && session.modeOfDelivery === "IN_PERSON";
      let promise = inPersonOldSession
        ? getOldSessionTranscriptData(session)
        : dispatch(
            getProviderSessionIds({
              sessionCategory: session?.sessionCategory === "GROUP" ? "group" : "individual",
              sessionId: session.id,
              triggerTime: new Date(),
              fromTasks: true,
            })
          );
      promises.push(promise);
    }
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING", show: true });
    let responses = await Promise.allSettled(promises);
    if (responses?.length > 0) {
      responses.forEach((response, index) => {
        let transcriptData = response?.value;
        if (transcriptData?.length > 0) {
          let sessionDate = sessions?.[index]?.startTime
            ? moment(sessions[index].startTime)
                .tz(preferredTimezone?.name || "America/Los_Angeles")
                .format("YYYY-MM-DD")
            : "";
          if (sessionDate) {
            let transcriptText = "";
            transcriptData.forEach(transcript => {
              let memberName = transcript.memberName;
              transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
            });
            if (dischargeSummaryPayload.transcriptAndOtherInfoDtos.length < 4) {
              dischargeSummaryPayload.transcriptAndOtherInfoDtos = [
                ...dischargeSummaryPayload.transcriptAndOtherInfoDtos,
                {
                  date: sessionDate,
                  // weekNumber: dischargeSummaryPayload.transcriptAndOtherInfoDtos.length + 1,
                  transcript: transcriptText,
                },
              ];
            }
          }
        }
      });
    }
    if (dischargeSummaryPayload.transcriptAndOtherInfoDtos.length > 0) {
      dischargeSummaryPayload.transcriptAndOtherInfoDtos =
        dischargeSummaryPayload.transcriptAndOtherInfoDtos.reverse();
      dischargeSummaryPayload.transcriptAndOtherInfoDtos =
        dischargeSummaryPayload.transcriptAndOtherInfoDtos.map((i, index) => {
          return { ...i, weekNumber: index + 1 };
        });
      let url = `https://transcript.${
        APP_ENV === "DEV" ? "dev" : "prod"
      }.soulsidehealth.com/api/v1/llm/transcript/generate-discharge-summary-from-transcripts`;
      try {
        let response = await rawPost(url, dischargeSummaryPayload);
        if (response?.data) {
          dischargeSummaryData = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    }
    dispatch({ type: "ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_DATA", data: dischargeSummaryData });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING", show: false });
    return dischargeSummaryData;
  };
};

export const resetPatientDetails = () => {
  return dispatch => {
    dispatch({ type: "RESET_PATIENT_DETAILS" });
  };
};

const initState = {
  soapNotesLoading: false,
  soapNotes: null,
  soapNotesSession: null,
  behaviouralHealthPredictions: null,
  behaviouralHealthPredictionsSession: null,
  privateNotesLoading: false,
  privateNotes: null,
  dischargeSummary: {
    loading: false,
    data: null,
  },
};

const ACTION_HANDLERS = {
  ["TOGGLE_PATIENT_DETAILS_SOAP_NOTES_LOADING"]: (state, action) => {
    return {
      ...state,
      soapNotesLoading: action.show,
    };
  },
  ["ADD_PATIENT_DETAILS_SOAP_NOTES_DATA"]: (state, action) => {
    return {
      ...state,
      soapNotes: action.data,
      soapNotesSession: action.session,
    };
  },
  ["ADD_PATIENT_DETAILS_BH_PREDICTIONS_DATA"]: (state, action) => {
    return {
      ...state,
      behaviouralHealthPredictions: action.data,
      behaviouralHealthPredictionsSession: action.session,
    };
  },
  ["TOGGLE_PATIENT_DETAILS_PRIVATE_NOTES_LOADING"]: (state, action) => {
    return {
      ...state,
      privateNotesLoading: action.show,
    };
  },
  ["ADD_PATIENT_DETAILS_PRIVATE_NOTES_DATA"]: (state, action) => {
    return {
      ...state,
      privateNotes: action.data,
    };
  },
  ["TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING"]: (state, action) => {
    return {
      ...state,
      dischargeSummary: {
        ...state.dischargeSummary,
        loading: action.show,
      },
    };
  },
  ["ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_DATA"]: (state, action) => {
    return {
      ...state,
      dischargeSummary: {
        ...state.dischargeSummary,
        data: action.data,
      },
    };
  },
  ["RESET_PATIENT_DETAILS"]: (state, action) => {
    return initState;
  },
};

export default function patientDetails(state = initState, action) {
  let handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
