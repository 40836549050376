import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getCookie } from "@/services/utils";
import { useNavigate } from "react-router-dom";
import { Loader } from "@/components";
import SideNav from "../SideNav";
import "./AppLayout.scss";
import OrgRoleLayout from "../OrgRoleLayout";
import NoSleep from "nosleep.js";

const noSleep = new NoSleep();

function AppLayout(props) {
  const navigate = useNavigate();
  useEffect(() => {
    let authToken = getCookie("authtoken");
    if (!authToken) {
      navigate("/login", { replace: true });
    } else {
      props.getUserData();
    }
  }, []);
  useEffect(() => {
    noSleep.enable();
    return () => {
      noSleep.disable();
    };
  }, []);
  const selectedUserRole = props.selectedUserRole;
  return (
    <div className="app-layout">
      {!props.hideSideBar && <SideNav />}
      <div className="app-layout-outlet">
        <Loader loading={props.userLoading}>
          {selectedUserRole ? <Outlet /> : <OrgRoleLayout />}
        </Loader>
      </div>
    </div>
  );
}

export default AppLayout;
