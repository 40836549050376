import React, { useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./SymptomTracker.scss";

function SymptomTracker(props) {
  const { data } = props;
  const [activeTab, setActiveTab] = useState("anxiety");
  const symptomData = activeTab === "anxiety" ? data?.anxiety : data?.depression;
  const anxietyScore = data?.anxiety?.score || 0;
  const depressionScore = data?.depression?.score || 0;
  const anxietyIntensity =
    anxietyScore >= 15
      ? "Severe"
      : anxietyScore >= 10
      ? "Moderate"
      : anxietyScore >= 5
      ? "Mild"
      : "Low";
  const depresssionIntensity =
    depressionScore >= 20
      ? "Severe"
      : depressionScore >= 15
      ? "Moderately severe"
      : depressionScore >= 10
      ? "Moderate"
      : depressionScore >= 5
      ? "Mild"
      : "Low";
  return (
    <div className="symptom-tracker-widget">
      <div className="widget-header">
        <div className="widget-title">Symptom Tracker</div>
      </div>
      <div className="widget-content-tabs-list">
        <div
          className={`widget-content-tab ${activeTab === "anxiety" ? "active" : ""}`}
          onClick={() => setActiveTab("anxiety")}
        >
          Anxiety Symptoms
        </div>
        <div
          className={`widget-content-tab ${activeTab === "depression" ? "active" : ""}`}
          onClick={() => setActiveTab("depression")}
        >
          Depression Symptoms
        </div>
      </div>
      <div className="widget-content">
        <div className="symptom-score-chart">
          <GradientSVG
            intensity={
              activeTab === "anxiety"
                ? anxietyIntensity.toLowerCase()
                : depresssionIntensity.toLowerCase()
            }
          />
          <CircularProgressbarWithChildren
            strokeWidth={6}
            value={symptomData?.score || 0}
            className="symptom-circle"
            minValue={0}
            maxValue={activeTab === "anxiety" ? 21 : 27}
            styles={{
              path: { stroke: `url(#symptom-score-gradient)`, height: "100%" },
              trail: {
                stroke: "#F0F3F7",
              },
            }}
          >
            <div className="symptom-circle-text-container">
              <div className="symptom-value">
                {symptomData?.score || 0}/{activeTab === "anxiety" ? 21 : 27}
              </div>
              <div
                className={`symptom-intensity ${
                  activeTab === "anxiety"
                    ? anxietyIntensity.toLowerCase()
                    : depresssionIntensity.toLowerCase()
                }`}
              >
                {activeTab === "anxiety" ? anxietyIntensity : depresssionIntensity}
              </div>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <div className="symptom-parameter-list">
          <div className="parameters-header">
            <div className="header-item">Parameter</div>
            <div className="header-item">Detected</div>
            <div className="header-item">Intensity</div>
          </div>
          <div className="parameter-list">
            {symptomData?.parameters?.length > 0 &&
              symptomData.parameters.map(parameter => {
                return (
                  <div
                    className="parameter-item-container"
                    key={`${activeTab}-${parameter.title}`}
                  >
                    <div className="parameter-item">{parameter.title}</div>
                    <div className="parameter-item">{!!parameter.detected ? "Yes" : "No"}</div>
                    <div
                      className={`parameter-item intensity ${
                        !!parameter.intensity
                          ? parameter.intensity === 1
                            ? "low"
                            : parameter.intensity === 2
                            ? "medium"
                            : "high"
                          : ""
                      }`}
                    >
                      {!!parameter.intensity
                        ? parameter.intensity === 1
                          ? "Low"
                          : parameter.intensity === 2
                          ? "Medium"
                          : "High"
                        : "-"}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SymptomTracker;

function GradientSVG(props) {
  const idCSS = "symptom-score-gradient";
  const gradientTransform = `rotate(90)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS}>
          {props.intensity?.includes("severe") && (
            <>
              <stop stopColor="#FF5F05" />
              <stop
                offset="1"
                stopColor="#FFE600"
              />
            </>
          )}
          {props.intensity?.includes("moderate") && (
            <>
              <stop stopColor="#FF5F05" />
              <stop
                offset="1"
                stopColor="#FFE600"
              />
            </>
          )}
          {props.intensity?.includes("mild") && (
            <>
              <stop stopColor="#FF5F05" />
              <stop
                offset="0.779653"
                stopColor="#FFE600"
              />
              <stop
                offset="1"
                stopColor="#64C833"
              />
            </>
          )}
          {props.intensity?.includes("low") && (
            <>
              <stop
                offset="0.160485"
                stopColor="#FFE600"
              />
              <stop
                offset="1"
                stopColor="#44C13C"
              />
            </>
          )}
        </linearGradient>
      </defs>
    </svg>
  );
}
