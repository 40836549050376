import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import useSignup from "./useSignup";
import { PrimaryButton } from "../../components";

import PasswordIcon from "../../assets/password.svg";
import PasswordHideIcon from "../../assets/password-hide.svg";

import "./Signup.scss";

const Signup = props => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    termsAccepted,
    setTermsAccepted,
    hidePassword,
    setHidePassword,
    emailError,
    passwordError,
    validateForm,
    isButtonDisabled,
    loading,
    onSignup,
    signupError,
  } = useSignup(props);
  return (
    <div className="signup-page">
      <div className="form-wrapper">
        <div className="form-container">
          <div className="form-title">Welcome to Soulside</div>
          <div className="form-sub-title">Set a new password to access your dashboard</div>
          <Form className="custom-form">
            <Form.Group
              className="custom-input-field"
              controlId="register-email-input"
            >
              <Form.Control
                type="email"
                placeholder="Email ID"
                value={email}
                onChange={e => setEmail(e.target.value)}
                isInvalid={!!emailError}
                onBlur={validateForm}
                disabled={true}
                readOnly
              />
              <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="custom-input-field with-password"
              controlId="register-password-input"
            >
              <Form.Control
                type={hidePassword ? "password" : "text"}
                placeholder="Create Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                onBlur={validateForm}
                disabled={!!loading}
              />
              <img
                src={hidePassword ? PasswordHideIcon : PasswordIcon}
                alt=""
                className="password-hide-icon"
                onClick={() => setHidePassword(hidePassword => !hidePassword)}
              />
            </Form.Group>
            <Form.Group
              className="custom-input-field with-password"
              controlId="register-confirm-password-input"
            >
              <Form.Control
                type={hidePassword ? "password" : "text"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                isInvalid={!!passwordError}
                onBlur={validateForm}
                disabled={!!loading}
              />
              <img
                src={hidePassword ? PasswordHideIcon : PasswordIcon}
                alt=""
                className="password-hide-icon"
                onClick={() => setHidePassword(hidePassword => !hidePassword)}
              />
              <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
            </Form.Group>
            {signupError && <div className="signup-error">{signupError}</div>}
            <Form.Group
              className="custom-input-field checkbox-input terms-agree"
              controlId="register-terms-input"
            >
              <div
                className={`custom-checkbox ${termsAccepted ? "checked" : ""}`}
                onClick={() => setTermsAccepted(termsAccepted => !termsAccepted)}
              >
                <span className="checkbox-icon"></span>
              </div>
              <Form.Label>
                I agree to the{" "}
                <a
                  href="https://www.getsoulside.com/terms-of-use"
                  target="_blank"
                  className="colored-link"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="https://www.getsoulside.com/privacy-policy"
                  target="_blank"
                  className="colored-link"
                >
                  Privacy Policy
                </a>
                .
              </Form.Label>
            </Form.Group>
            <PrimaryButton
              className="form-submit-btn"
              disabled={!!isButtonDisabled() || !!loading}
              onClick={onSignup}
            >
              {!loading ? "Register" : "Submitting..."}
            </PrimaryButton>
          </Form>
          <div className="sign-in-prompt">
            Already have an account?{" "}
            <Link
              to={"/login"}
              className="colored-link"
            >
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
