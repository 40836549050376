import MentalWellnessScore from "./MentalWellnessScore";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    patientMentalWellnessData: state.appointments.sessions.patientMentalWellnessData,
    groupMentalWellnessData: state.appointments.sessions.groupMentalWellnessData,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MentalWellnessScore);
