import React from "react";
import { RichTextEditor } from "@/components/RichTextEditor";
import "./PreExistingRisks.scss";

function PreExistingRisks(props) {
  const { data } = props;
  return (
    <div className="pre-existing-risks-widget">
      <div className="widget-header">
        <div className="widget-title">Pre-Existing Risks Identified</div>
      </div>
      <div className="risks-list">
        {!!data && (
          <RichTextEditor
            value={data}
            readOnly={true}
          />
        )}
      </div>
    </div>
  );
}

export default PreExistingRisks;
