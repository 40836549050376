import AppLayout from "./AppLayout";
import { connect } from "react-redux";
import { getUserData } from "@/store/modules/userModule";

const mapStateToProps = state => {
  return {
    userLoading: state.user.userLoading,
    userData: state.user.userData,
    selectedUserRole: state.user.selectedUserRole,
    hideSideBar: state.app.hideSideBar,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserData: options => dispatch(getUserData(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
