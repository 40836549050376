import SideNav from "./SideNav";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    userData: state.user.userData,
    userLoading: state.user.userLoading,
    selectedUserRole: state.user.selectedUserRole,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
