import AuthLayout from "./AuthLayout";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    userRequests: state.user.userRequests,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
