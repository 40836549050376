import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "@/components";
import SessionAppointments from "@/Routes/Session/components/SessionAppointments";
import "./SessionEnded.scss";

function SessionEnded(props) {
  const navigate = useNavigate();
  const { sessionCategory, patientId, sessionId } = useParams();
  const rejoinSession = async () => {
    window.location.href = window.location.href;
  };
  const reviewNotes = () => {
    navigate(`/session-details/${sessionCategory}/${props.sessionType}/${sessionId}/${patientId}`, {
      replace: true,
    });
  };
  return (
    <div className="session-ended-container">
      <div className="session-ended-message">
        Your session with
        {props.sessionDetailsData?.sessionCategory === "GROUP"
          ? ` ${props.sessionDetailsData?.groupName || ""}`
          : ` ${props.sessionDetailsData?.patientFirstName || ""}${
              props.sessionDetailsData?.patientLastName ? " " : ""
            }${props.sessionDetailsData?.patientLastName || ""}`}{" "}
        has ended.
      </div>
      <div className="session-ended-message">Review notes for this session</div>
      <div className="session-ended-actions">
        <PrimaryButton onClick={() => reviewNotes()}>Review Notes</PrimaryButton>
      </div>
      <div className="separator">Or</div>
      <div className="start-next-appointment-msg">Start your next appointment</div>
      <div className="your-appointments-container">
        <SessionAppointments
          endSession={() => console.log("end session")}
          sessionEnded={true}
          currentSessionStatus={"Ended"}
        />
      </div>
    </div>
  );
}

export default SessionEnded;
