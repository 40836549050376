import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { addLocalStorage, getLocalStorage } from "@/services/utils";
import Mic from "@/assets/session/mic.svg";
import MicMute from "@/assets/session/mic-off.svg";
import LeaveIcon from "@/assets/session/leave.svg";
import AudioRecorderIcon from "@/assets/session/audio-recorder.svg";
import CryptoJS from "crypto-js";
import { useNavigate, useParams } from "react-router-dom";
import "./InPersonSession.scss";
import { PrimaryButton, Loader, SecondaryButton } from "@/components";
import NoSleep from "nosleep.js";
import { TodayDateTime } from "@/Routes/Home/Home";
import SessionMemberNotes from "../SessionMemberNotes";
import SessionAppointments from "../SessionAppointments";
import { ReactSVG } from "react-svg";
import { rawGet } from "@/services/api";
import uuid from "react-uuid";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const noSleep = new NoSleep();

function InPersonSession(props) {
  const [audioMuted, setAudioMuted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [rightContent, setRightContent] = useState(true);
  const [sessionEnded, setSessionEnded] = useState(false);
  const { sessionCategory, patientId, sessionId } = useParams();
  const navigate = useNavigate();
  const chunks = useRef([]);
  const recorder = useRef();
  const audioContext = useRef();
  const gumStream = useRef();
  const newSessionStarted = useRef(false);
  const redirectOnStopRecording = useRef(true);
  const recordingOn = useRef(false);
  useEffect(() => {
    // mapProviderSessionId();
    props.hideSideBar(true);
    setSessionEnded(false);
    setAudioMuted(false);
    setShowNotes(false);
    setRightContent(true);
    chunks.current = [];
    recorder.current = null;
    audioContext.current = null;
    gumStream.current = null;
    redirectOnStopRecording.current = true;
    recordingOn.current = false;
    startRecording();
    return () => {
      props.hideSideBar(false);
      redirectOnStopRecording.current = false;
      if (!!recordingOn.current) {
        stopRecording();
      }
    };
  }, [sessionCategory, patientId, sessionId]);
  const mapProviderSessionId = () => {
    let options = {
      providerSessionId: uuid(),
      sessionCategory,
      modeOfDelivery: "in-person",
      sessionId,
    };
    if (sessionCategory === "individual") {
      options = {
        ...options,
        patientId,
      };
    } else {
      options = {
        ...options,
        groupId: patientId,
      };
    }
    props.mapProviderSessionId(options);
  };
  const urlParams = new URLSearchParams(window.location.search);
  const externalSpeakerPhone = urlParams.get("externalSpeakerPhone");
  useEffect(() => {
    if (audioMuted) {
      recorder?.current?.pause?.();
    } else {
      recorder?.current?.resume?.();
    }
  }, [audioMuted]);
  useEffect(() => {
    if (props.sessionDetails?.data && Object.keys(props.sessionDetails.data).length > 0) {
      props.addTask(props.sessionDetails?.data);
    }
  }, [props.sessionDetails?.data]);

  const blobToData = blob => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => resolve(e.target.result);
      reader.readAsArrayBuffer(blob);
    });
  };
  const sendAudioChunks = async chunksList => {
    setLoading(true);
    const uploadId = await fetch(
      `https://transcript.${
        APP_ENV === "DEV" ? "dev" : "prod"
      }.soulsidehealth.com/audio/start/${sessionId}`,
      {
        method: "POST",
      }
    ).then(response => response.text());

    let eTags = [];

    for (let i = 0; i < chunksList.length; i++) {
      const fileData = await blobToData(chunksList[i]);
      const wordArray = CryptoJS.lib.WordArray.create(fileData);
      const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64);
      console.log(`Chunk ${i + 1} MD5 Hash: ${md5Hash}`);
      try {
        let presignedUrl = await fetch(
          `https://transcript.${
            APP_ENV === "DEV" ? "dev" : "prod"
          }.soulsidehealth.com/audio/presigned-url/${sessionId}/${uploadId}/${
            i + 1
          }?md5Hash=${window.encodeURIComponent(md5Hash)}`,
          {
            method: "GET",
          }
        ).then(response => response.text());
        // if (presignedUrl) {
        //   presignedUrl = presignedUrl.text();
        // }
        let formData = new FormData();
        formData.append("data", chunksList[i]);
        try {
          const response = await fetch(presignedUrl, {
            method: "PUT",
            body: chunksList[i],
            headers: {
              "Content-MD5": md5Hash,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });

          if (response.ok) {
            const eTag = response.headers.get("ETag") && JSON.parse(response.headers.get("ETag"));
            eTags.push(eTag);
          }
          if (i === chunksList.length - 1) {
            // await endSession({ sessionId, uploadId, eTags });
          }
        } catch (error) {}
      } catch (error) {}
      await endSession({ sessionId, uploadId, eTags });
    }
  };
  async function endSession({ sessionId, uploadId, eTags }) {
    let url = `https://transcript.${
      APP_ENV === "DEV" ? "dev" : "prod"
    }.soulsidehealth.com/audio/complete/individual/${sessionId}/${uploadId}`;
    if (sessionCategory === "group") {
      url = `https://transcript.${
        APP_ENV === "DEV" ? "dev" : "prod"
      }.soulsidehealth.com/audio/complete/group/${sessionId}/${uploadId}`;
    }
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(eTags),
    })
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.error("Error:", error));
    if (!newSessionStarted.current) {
      setSessionEnded(true);
      props.hideSideBar(false);
    }

    if (!!redirectOnStopRecording.current) {
      // window.location.href = `/session-details/${sessionCategory}/in-person/${sessionId}/${patientId}`;
      // navigate(`/session-details/${sessionCategory}/in-person/${sessionId}/${patientId}`, {
      //   replace: true,
      // });
    }
    setLoading(false);
  }
  function startRecording() {
    noSleep.enable();
    recordingOn.current = true;
    const constraints = { audio: true };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
        gumStream.current = stream;
        const input = audioContext.current.createMediaStreamSource(stream);

        recorder.current = new MediaRecorder(stream, { audioBitsPerSecond: 1536000 });
        recorder.current.ondataavailable = e => {
          console.log("chunk added", chunks.current.length + 1);
          chunks.current.push(e.data);
        };
        recorder.current.onstop = async () => {
          recordingOn.current = false;
          await sendAudioChunks(chunks.current);
          chunks.current = [];
        };
        recorder.current.start();
      })
      .catch(err => {
        noSleep.disable();
        console.error("Error: " + err);
        alert("Could not access your microphone. Please check your permissions.");
      });
  }

  function stopRecording() {
    // if (recorder && recorder.state === "recording") {
    noSleep.disable();
    recorder?.current?.stop?.();
    gumStream?.current?.getAudioTracks()[0].stop();
    // }
  }
  async function leaveSession() {
    // newSessionStarted.current = true;
    await sendAudioChunks(chunks.current);
    // newSessionStarted.current = false;
    chunks.current = [];
  }
  return (
    <div className="in-person-session-container">
      <Loader
        loading={loading}
        loadingMsg={"Please wait while we process the audio to generate the transcript"}
      >
        {!sessionEnded ? (
          <div className="in-person-content-container">
            <div className="session-content-wrapper">
              <div className="session-content-container">
                <div className="audio-recorder-text">Audio Recorder</div>
                <img
                  src={AudioRecorderIcon}
                  alt=""
                  className="audio-recorder-icon"
                />
                <div className="recording-indicator">Recording</div>
                <div className="audio-recorder-time">
                  <Timer />
                </div>
              </div>
            </div>
            <div className="session-controls-wrapper">
              <div></div>
              {/* <TodayDateTime format={"h:mm A"} /> */}
              <div className="session-controls-container">
                <div
                  className="session-control-item"
                  onClick={() => setAudioMuted(!audioMuted)}
                >
                  <ReactSVG
                    src={audioMuted ? MicMute : Mic}
                    className={`session-control-item-icon ${audioMuted ? "muted" : ""}`}
                  />
                  {audioMuted ? "Mic Off" : "Mic On"}
                </div>
                <div
                  className="session-control-item session-leave-btn"
                  onClick={stopRecording}
                >
                  <ReactSVG
                    src={LeaveIcon}
                    className="session-control-item-icon"
                  />
                  End Session
                </div>
              </div>
              <div className="show-notes-btn">
                {/* <SecondaryButton onClick={() => setRightContent(!rightContent)}>
                {rightContent ? "Hide" : "Show"} Appointment/Notes
              </SecondaryButton> */}
              </div>
            </div>
          </div>
        ) : (
          <SessionEnded sessionDetailsData={props.sessionDetails?.data} />
        )}
        {!!rightContent && !sessionEnded && (
          <div className="session-page-right">
            {sessionCategory !== "group" && (
              <SessionMemberNotes
                memberNotes={props.memberNotes}
                hideNotes={() => setRightContent(false)}
                preferredTimezone={props.preferredTimezone}
                sessionDetailsData={props.sessionDetails.data}
              />
            )}
            <SessionAppointments
              endSession={leaveSession}
              sessionEnded={false}
              currentSessionStatus={"Live"}
            />
          </div>
        )}
      </Loader>
    </div>
  );
}

export default InPersonSession;

const Timer = () => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const reset = () => {
    setSeconds(0);
    setIsActive(false);
  };

  const formatTime = totalSeconds => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return <span>{formatTime(seconds)}</span>;
};

function SessionEnded(props) {
  const navigate = useNavigate();
  const { sessionCategory, patientId, sessionId } = useParams();
  const rejoinSession = async () => {
    window.location.href = window.location.href;
  };
  const reviewNotes = () => {
    navigate(`/session-details/${sessionCategory}/in-person/${sessionId}/${patientId}`, {
      replace: true,
    });
  };
  return (
    <div className="in-person-session-ended-container">
      <div className="session-ended-message">
        Your session with
        {props.sessionDetailsData?.sessionCategory === "GROUP"
          ? ` ${props.sessionDetailsData?.groupName || ""}`
          : ` ${props.sessionDetailsData?.patientFirstName || ""}${
              props.sessionDetailsData?.patientLastName ? " " : ""
            }${props.sessionDetailsData?.patientLastName || ""}`}{" "}
        has ended.
      </div>
      <div className="session-ended-message">Review notes for this session</div>
      <div className="session-ended-actions">
        <PrimaryButton onClick={() => reviewNotes()}>Review Notes</PrimaryButton>
      </div>
      <div className="separator">Or</div>
      <div className="start-next-appointment-msg">Start your next appointment</div>
      <div className="your-appointments-container">
        <SessionAppointments
          endSession={() => console.log("end session")}
          sessionEnded={true}
          currentSessionStatus={"Ended"}
        />
      </div>
    </div>
  );
}
