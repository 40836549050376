import React, { useState, useEffect } from "react";
import { Avatar, Loader, PrimaryButton, Search, SecondaryButton, MemberAvatar } from "@/components";
import moment from "moment-timezone";
import AddGroupModal from "./components/AddGroupModal";
import { groupByPractitioner } from "../Users/usersModule";
import GroupsPlaceholderIcon from "@/assets/group-avatar-placeholder.svg";
import InPersonIcon from "@/assets/sofa.svg";
import VirtualIcon from "@/assets/video-recorder.svg";
import "./Groups.scss";

const dayMapping = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

function Groups(props) {
  const [searchKey, setSearchKey] = useState("");
  const [addGroupModaOpen, setGroupModalOpen] = useState(false);
  const [editGroupData, setEditGroupData] = useState(null);
  let groupsData = [...props.groups.data];
  groupsData = searchKey
    ? groupsData.filter(i => {
        let groupName = i.groupName;
        return !!groupName.toLowerCase().includes(searchKey);
      })
    : groupsData;
  useEffect(() => {
    props.getGroups();
  }, []);
  useEffect(() => {
    document.title = "Groups";
  }, []);
  useEffect(() => {
    if (!addGroupModaOpen) {
      setEditGroupData(null);
    }
  }, [addGroupModaOpen]);
  const editGroup = groupData => {
    setEditGroupData(groupData);
    setGroupModalOpen(true);
  };
  return (
    <div className="groups-wrapper">
      <Loader loading={props.groups.loading}>
        <div className="groups-header">
          <Search
            placeholder={"Search Group"}
            value={searchKey}
            onSearch={val => setSearchKey(val)}
            clearSearch={() => setSearchKey("")}
            noDebounce={true}
          />
          {props.selectedUserRole?.businessFunction !== "Clinical Care" && (
            <div className="groups-header-actions">
              <PrimaryButton
                className="add-group-btn"
                onClick={() => setGroupModalOpen(true)}
              >
                Add Group
              </PrimaryButton>
            </div>
          )}
        </div>
        <div className="groups-list">
          {groupsData?.map((group, index) => {
            let groupDay = group.dayOfWeek;
            let groupTime = "";
            if (
              group.schedulingPreferences === "AUTO_SCHEDULING" &&
              groupDay &&
              (group.startHour || group.startHour === 0) &&
              (group.startMinute || group.startMinute === 0)
            ) {
              const groupPtTime = moment
                .tz("America/Los_Angeles")
                .day(dayMapping[groupDay])
                .hour(group.startHour)
                .minute(group.startMinute);
              const groupConvertedTime = groupPtTime
                .clone()
                .tz(props.preferredTimezone?.name || "America/Los_Angeles");
              groupTime = `${groupConvertedTime.format("h:mm a")} ${
                props.preferredTimezone?.abbr || "PT"
              }`;
              groupDay = Object.keys(dayMapping).find(
                day => dayMapping[day] === groupConvertedTime.day()
              );
            }
            const pracitionerData =
              group.groupPractitionerRoleDtos?.length > 0
                ? groupByPractitioner(group.groupPractitionerRoleDtos)
                : [];
            return (
              <div
                className="group-item"
                key={group.id}
              >
                <div className="group-item-top">
                  <div className="group-name-container">
                    <MemberAvatar
                      size={"sm"}
                      showFullName={true}
                      placeholderImg={GroupsPlaceholderIcon}
                    />
                    <div
                      className={`group-name ${
                        props.selectedUserRole?.businessFunction !== "Clinical Care"
                          ? "editable"
                          : ""
                      }`}
                      onClick={
                        props.selectedUserRole?.businessFunction !== "Clinical Care"
                          ? () => editGroup(group)
                          : console.log
                      }
                    >
                      {group.groupName}
                    </div>
                  </div>
                  <div className="group-tags-container">
                    <div className="session-type">
                      <img
                        className="session-type-icon"
                        src={group.modeOfDelivery === "VIRTUAL" ? VirtualIcon : InPersonIcon}
                      />
                      {group.modeOfDelivery === "VIRTUAL" ? "Online" : "In-Office"}
                    </div>
                    {/* <div className="session-type">
                      {group.patientSessionRecurrenceType === "FLEXIBLE"
                        ? "Flexible"
                        : group.patientSessionRecurrenceType === "AD_HOC"
                        ? "Ad Hoc"
                        : group.patientSessionRecurrenceType === "DROP_IN"
                        ? "Drop-in"
                        : ""}
                    </div> */}
                    {/* <div
                      className={`group-category ${
                        group.groupOpennessType === "CLOSED" ? "closed" : "open"
                      }`}
                    >
                      {group.groupOpennessType === "CLOSED" ? "Closed" : "Open"}
                    </div> */}
                  </div>
                </div>
                {group.schedulingPreferences === "AUTO_SCHEDULING" && (
                  <>
                    <div className="group-schedule">
                      {groupDay} @ {groupTime} | {group.sessionDurationInMinutes} mins
                    </div>
                  </>
                )}
                {pracitionerData?.length > 0 && (
                  <div className="group-members-container">
                    <div className="group-members-label">Practitioners:</div>
                    <div className="group-members">
                      {pracitionerData.map(practitioner => {
                        return (
                          <MemberAvatar
                            key={practitioner.practitionerId}
                            memberPhoto={practitioner.preSignedUrlForProfileImage || null}
                            name={`${practitioner.practitionerFirstName || ""}${
                              practitioner.practitionerLastName ? " " : ""
                            }${practitioner.practitionerLastName || ""}`}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                {group.groupPatientMembershipDtos?.length > 0 && (
                  <div className="group-members-container">
                    <div className="group-members-label">Patients:</div>
                    <div className="group-members">
                      {group.groupPatientMembershipDtos.map((member, index) => {
                        return (
                          <MemberAvatar
                            key={member.patientId || index}
                            memberPhoto={member.preSignedUrlForProfileImage || null}
                            name={`${member.patientFirstName || ""}${
                              member.patientLastName ? " " : ""
                            }${member.patientLastName || ""}`}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <AddGroupModal
          modalOpen={addGroupModaOpen}
          toggleModal={setGroupModalOpen}
          data={editGroupData || null}
          isEdit={!!editGroupData}
        />
      </Loader>
    </div>
  );
}

export default Groups;
