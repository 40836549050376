import Patients from "./Patients";
import { connect } from "react-redux";
import { getPatients, addPatient, getDummyPhoneNumber, getDummyEmail } from "./patientsModule";

const mapStateToProps = state => {
  return {
    patients: state.patients.patients,
    alertsSaved: state.patients.alertsSaved,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPatients: options => dispatch(getPatients(options)),
    addPatient: options => dispatch(addPatient(options)),
    getDummyPhoneNumber: () => dispatch(getDummyPhoneNumber()),
    getDummyEmail: name => dispatch(getDummyEmail(name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
