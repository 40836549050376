import React, { useState, useEffect } from "react";
import "./MemberInsights.scss";
import { getLocalStorage } from "@/services/utils";
import { marked } from "marked";
import { Avatar, RichTextEditor } from "@/components";

import { groupDynamics } from "@/services/dummyData";

let storedGroupNotesData = getLocalStorage("groupNotesData");

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const convertMarkdownToHtml = text => {
  try {
    return marked.parse(text);
  } catch (error) {
    return "";
  }
};

// function getMemberData(text) {
//   try {
//     let htmlString = convertMarkdownToHtml(text);
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(htmlString, "text/html");
//     const listItems = doc.querySelectorAll("ol > li");

//     const result = Array.from(listItems).map(li => {
//       const name = li.querySelector("strong").textContent.trim();
//       const ulContent = li.querySelector("ul").innerHTML.trim();

//       const keyInsights = Array.from(li.querySelectorAll("li"))
//         ?.find(
//           li =>
//             li.textContent?.includes("Key Insights:") || li.textContent?.includes("Key Insight:")
//         )
//         ?.textContent?.replace("Key Insights:", "")
//         .textContent?.replace("Key Insight:", "")
//         ?.trim();

//       const behavioralChanges = Array.from(li.querySelectorAll("li"))
//         ?.find(
//           li =>
//             li.textContent?.includes("Behavioral Changes:") ||
//             li.textContent?.includes("Behavioral Change:")
//         )
//         ?.textContent?.replace("Behavioral Changes:", "")
//         .textContent?.replace("Behavioral Change:", "")
//         ?.trim();

//       const personalChallenges = Array.from(li.querySelectorAll("li"))
//         ?.find(
//           li =>
//             li.textContent?.includes("Personal Challenges:") ||
//             li.textContent?.includes("Personal Challenge:")
//         )
//         ?.textContent?.replace("Personal Challenges:", "")
//         .textContent?.replace("Personal Challenge:", "")
//         ?.trim();

//       return {
//         name: name?.replace(":", ""),
//         keyInsights: keyInsights,
//         behavioralChanges: behavioralChanges,
//         personalChallenges: personalChallenges,
//         contentHtmlString: `<ul>${ulContent}</ul>`,
//       };
//     });

//     return result;
//   } catch (error) {
//     return [];
//   }
// }

function getMemberData(text) {
  try {
    let htmlString = convertMarkdownToHtml(text);
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const paragraphs = doc.querySelectorAll("p");

    const result = [];
    let currentPerson = null;

    paragraphs.forEach(p => {
      const strongTag = p.querySelector("strong");
      if (strongTag) {
        const strongText = strongTag.textContent.trim();

        // Check if this is a person's name
        if (
          strongText.match(/:$/) &&
          !strongText.includes("Key Insights") &&
          !strongText.includes("Behavioral Changes") &&
          !strongText.includes("Personal Challenges")
        ) {
          // If there's already a person being processed, push them to the result array
          if (currentPerson) {
            result.push(currentPerson);
          }

          // Start a new person
          currentPerson = {
            name: strongText.replace(":", "").trim(),
            keyInsights: "",
            behavioralChanges: "",
            personalChallenges: "",
            contentHtmlString: "",
          };
        } else if (currentPerson) {
          // Handle the different sections for the current person
          if (strongText.includes("Key Insights:")) {
            currentPerson.keyInsights = p.textContent.replace("Key Insights:", "").trim();
          } else if (strongText.includes("Behavioral Changes:")) {
            currentPerson.behavioralChanges = p.textContent
              .replace("Behavioral Changes:", "")
              .trim();
          } else if (strongText.includes("Personal Challenges:")) {
            currentPerson.personalChallenges = p.textContent
              .replace("Personal Challenges:", "")
              .trim();
          }

          // Accumulate the HTML content
          currentPerson.contentHtmlString += p.outerHTML;
        }
      } else if (currentPerson) {
        // Continue adding paragraphs to the current person's HTML content if no strong tag is found
        currentPerson.contentHtmlString += p.outerHTML;
      }
    });

    // Push the last person to the result array if exists
    if (currentPerson) {
      result.push(currentPerson);
    }

    return result;
  } catch (error) {
    return [];
  }
}

function MemberInsights(props) {
  // const { data } = props;

  const dummyData = groupDynamics.members;
  const groupNotesData = props.soapNotesJson?.groupExtendedNotes || {};
  const data = groupNotesData?.individualContributions
    ? getMemberData(groupNotesData.individualContributions) || []
    : [];

  const [selectedMember, setSelectedMember] = useState(data?.[0]?.name);
  let memberImages = {};
  let memberEmotions = {};
  let memberSessions = {};
  data.forEach((member, index) => {
    // memberImages[member.name] = dummyData[index].memberPhoto;
    memberEmotions[member.name] = dummyData[index].emotions;
    memberSessions[member.name] = dummyData[index].noOfSessions;
  });
  const memberData = data.filter(i => i.name === selectedMember);
  useEffect(() => {
    if (data?.[0]?.name) {
      setSelectedMember(data[0].name);
    }
  }, [data]);
  return (
    <div className="member-insights-widget">
      <div className="member-insights-label">Member Insights</div>
      <div className="member-list">
        {data.map((member, index) => {
          return (
            <div
              className={`member-item ${selectedMember === member.name ? "selected" : ""}`}
              key={member.name}
              onClick={() => setSelectedMember(member.name)}
            >
              <div className="avatar-container">
                <Avatar
                  imageSrc={memberImages[member.name]}
                  name={member.name}
                />
                <span className="avatar-name">{member.name}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="insights-list">
        {memberData.map((member, index) => {
          return (
            <div
              className="insights-item"
              key={member.name}
            >
              <div className="member-top-container">
                <div className="avatar-container">
                  <Avatar
                    imageSrc={memberImages[member.name]}
                    name={member.name}
                  />
                  <span className="avatar-name">{member.name}</span>
                  {APP_ENV === "DEV" && (
                    <span className="no-of-sessions">
                      {memberSessions[member.name]} session
                      {memberSessions[member.name] > 1 ? "s" : ""}
                    </span>
                  )}
                </div>
                {APP_ENV === "DEV" && (
                  <div className="emotions-list">
                    {memberEmotions[member.name].map(emotion => {
                      return (
                        <div
                          className="emotion"
                          key={emotion}
                        >
                          {emotion}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="insight-desc">
                <RichTextEditor
                  value={member.contentHtmlString}
                  readOnly={true}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MemberInsights;
