import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Avatar, RichTextEditor } from "@/components";
import { getLocalStorage } from "@/services/utils";
import { marked } from "marked";
import "./PeerConnection.scss";

import { groupDynamics } from "@/services/dummyData";

let storedGroupNotesData = getLocalStorage("groupNotesData");

const convertMarkdownToHtml = text => {
  return marked.parse(text);
};

function PeerConnection(props) {
  // const { data } = props;
  // const data = groupDynamics.peerConnection;
  // const groupNotesData = storedGroupNotesData?.[props.sessionId] || {};
  const groupNotesData = props.soapNotesJson?.groupExtendedNotes || {};
  const data = groupNotesData?.supportiveInteractions
    ? convertMarkdownToHtml(groupNotesData.supportiveInteractions) ||
      groupNotesData.supportiveInteractions
    : "";
  return (
    <div className="peer-connection-widget">
      <div className="peer-connection-label">Peer Connection</div>
      <div className="peer-connection-list">
        {/* {data.map((connection, index) => {
          return (
            <div
              className="peer-connection-item"
              key={index}
            >
              <div className="avatar-list">
                {connection.members.map(member => {
                  return (
                    <OverlayTrigger
                      overlay={<Tooltip>{member.name}</Tooltip>}
                      key={member.userId}
                    >
                      <div>
                        <Avatar
                          imageSrc={member.memberPhoto}
                          name={member.name}
                        />
                      </div>
                    </OverlayTrigger>
                  );
                })}
              </div>
              <div className="connection-desc">{connection.description}</div>
            </div>
          );
        })} */}
        <RichTextEditor
          value={data}
          readOnly={true}
        />
      </div>
    </div>
  );
}

export default PeerConnection;
