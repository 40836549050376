import React from "react";
import SessionLevels from "./components/SessionLevels";
import MemberDetails from "./components/MemberDetails";
import { RichTextEditor } from "@/components/RichTextEditor";
import { getLocalStorage } from "@/services/utils";
import { marked } from "marked";
import "./GroupDynamics.scss";

let storedGroupNotesData = getLocalStorage("groupNotesData");

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const convertMarkdownToHtml = text => {
  return marked.parse(text);
};

function GroupDynamics(props) {
  // const groupNotesData = storedGroupNotesData?.[props.sessionId] || {};
  const groupNotesData = props.soapNotesJson?.groupExtendedNotes || {};
  const groupDynamicsData = groupNotesData?.groupDynamics
    ? convertMarkdownToHtml(groupNotesData.groupDynamics) || groupNotesData.groupDynamics
    : "";
  return (
    <div className="group-dynamics-container">
      {APP_ENV === "DEV" && <div className="demo-deta-disclaimer">*Demo data</div>}
      <div className="group-dynamics-content">
        {APP_ENV === "DEV" && (
          <>
            <SessionLevels />
            <MemberDetails />
          </>
        )}
        <div className="group-dynamics-widget">
          <div className="widget-header">
            <div className="widget-title">Group Dynamics</div>
          </div>
          <div className="widget-content">
            <RichTextEditor
              value={groupDynamicsData}
              readOnly={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupDynamics;
