import React, { useState, useEffect } from "react";
import "./Search.scss";

let searchTimer = null;

export const Search = props => {
  const [searchKey, setSearchKey] = useState(props.value || "");
  const [inputFocused, setInputFocused] = useState(false);
  useEffect(() => {
    setSearchKey(props.value);
  }, [props.value]);
  useEffect(() => {
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    searchTimer = setTimeout(
      () => {
        props.onSearch(searchKey);
      },
      props.noDebounce ? 0 : 500
    );
  }, [searchKey]);
  useEffect(() => {
    if (props.inputFocus) {
      props.inputFocus(inputFocused);
    }
  }, [inputFocused]);
  const onSearch = e => {
    let value = e.target.value;
    setSearchKey(value);
  };
  const clearSearch = () => {
    onSearch({ target: { value: "" } });
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
      let submit = true;
      props.onSearch(searchKey, submit);
    }
  };
  const toggleInputFocus = flag => {
    setInputFocused(flag);
  };
  return (
    <div className={`search-widget ${inputFocused ? "input-focused" : ""}`}>
      <div className={`search-container`}>
        <span className="search-icon"></span>
        <input
          className="search-input"
          type="text"
          placeholder={props.placeholder || ""}
          onChange={onSearch}
          value={searchKey}
          onKeyPress={handleKeyPress}
          onFocus={() => toggleInputFocus(true)}
          onBlur={() => toggleInputFocus(false)}
        />
        {searchKey && !props.hideClear && (
          <span
            className="clear-search"
            onClick={clearSearch}
          ></span>
        )}
      </div>
    </div>
  );
};
