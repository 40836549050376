import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./SymptomTracker.scss";

export default function SymptomTracker(props) {
  const [symptomsData, setSymptomsData] = useState(null);
  const { data } = props;
  useEffect(() => {
    if (data) {
      setSymptomsData(data);
    }
  }, [data]);
  return (
    <div className="symptom-tracker-widget">
      <div className="widget-header">
        <div className="widget-title">
          {props.type === "anxiety" ? "Anxiety Symptoms" : "Depression Symptoms"}
        </div>
      </div>
      <div className="widget-content">
        <div className="symptom-score-chart">
          <GradientSVG intensity={symptomsData?.intensityText?.toLowerCase()} />
          <CircularProgressbarWithChildren
            strokeWidth={6}
            value={symptomsData?.score || 0}
            className="symptom-circle"
            minValue={0}
            maxValue={props.type === "anxiety" ? 21 : 27}
            styles={{
              path: { stroke: `url(#symptom-score-gradient)`, height: "100%" },
              trail: {
                stroke: "#F0F3F7",
              },
            }}
          >
            <div className="symptom-circle-text-container">
              <div className="symptom-value">
                {symptomsData?.score || 0}/{props.type === "anxiety" ? 21 : 27}
              </div>
              <div className={`symptom-intensity ${symptomsData?.intensityText?.toLowerCase()}`}>
                {symptomsData?.intensityText || ""}
              </div>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <div className="symptom-parameter-list">
          <div className="parameters-header">
            <div className="header-item">Parameter</div>
            <div className="header-item">Detected</div>
            <div className="header-item">Intensity</div>
          </div>
          <div className="parameter-list">
            {symptomsData?.parameters?.length > 0 &&
              symptomsData.parameters.map(parameter => {
                return (
                  <div
                    className="parameter-item-container"
                    key={`${props.type}-${parameter.title}`}
                  >
                    <div className="parameter-item">{parameter.title}</div>
                    <div className="parameter-item">{!!parameter.detected ? "Yes" : "No"}</div>
                    <div
                      className={`parameter-item intensity ${
                        !!parameter.intensity
                          ? parameter.intensity === 1
                            ? "low"
                            : parameter.intensity === 2
                            ? "medium"
                            : "high"
                          : ""
                      }`}
                    >
                      {!!parameter.intensity
                        ? parameter.intensity === 1
                          ? "Low"
                          : parameter.intensity === 2
                          ? "Medium"
                          : "High"
                        : "-"}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

function GradientSVG(props) {
  const idCSS = "symptom-score-gradient";
  const gradientTransform = `rotate(90)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS}>
          {props.intensity?.includes("severe") && (
            <>
              <stop stopColor="#FF5F05" />
              <stop
                offset="1"
                stopColor="#FFE600"
              />
            </>
          )}
          {props.intensity?.includes("moderate") && (
            <>
              <stop stopColor="#FF5F05" />
              <stop
                offset="1"
                stopColor="#FFE600"
              />
            </>
          )}
          {props.intensity?.includes("mild") && (
            <>
              <stop stopColor="#FF5F05" />
              <stop
                offset="0.779653"
                stopColor="#FFE600"
              />
              <stop
                offset="1"
                stopColor="#64C833"
              />
            </>
          )}
          {props.intensity?.includes("low") && (
            <>
              <stop
                offset="0.160485"
                stopColor="#FFE600"
              />
              <stop
                offset="1"
                stopColor="#44C13C"
              />
            </>
          )}
        </linearGradient>
      </defs>
    </svg>
  );
}
