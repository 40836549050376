import React from "react";
import { RichTextEditor } from "@/components/RichTextEditor";
import { getLocalStorage } from "@/services/utils";
import { marked } from "marked";
import "./ThemesConflicts.scss";

let storedGroupNotesData = getLocalStorage("groupNotesData");

const convertMarkdownToHtml = text => {
  return marked.parse(text);
};

function ThemesConflicts(props) {
  // const groupNotesData = storedGroupNotesData?.[props.sessionId] || {};
  const groupNotesData = props.soapNotesJson?.groupExtendedNotes || {};
  const themesData = groupNotesData?.themesAndTopics
    ? convertMarkdownToHtml(groupNotesData.themesAndTopics) || groupNotesData.themesAndTopics
    : "";
  const conflictsData = groupNotesData?.conflictsOrChallenges
    ? convertMarkdownToHtml(groupNotesData.conflictsOrChallenges) ||
      groupNotesData.conflictsOrChallenges
    : "";
  return (
    <div className="themes-conflicts-container">
      <div className="themes-conflicts-widget">
        <div className="widget-header">
          <div className="widget-title">Themes & Topics</div>
        </div>
        <div className="widget-content">
          <RichTextEditor
            value={themesData}
            readOnly={true}
          />
        </div>
      </div>
      <div className="themes-conflicts-widget">
        <div className="widget-header">
          <div className="widget-title">Conflicts or Challenges</div>
        </div>
        <div className="widget-content">
          <RichTextEditor
            value={conflictsData}
            readOnly={true}
          />
        </div>
      </div>
    </div>
  );
}

export default ThemesConflicts;
