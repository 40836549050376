import React from "react";
import "./Loader.scss";

function Loader(props) {
  if (props.loading) {
    return (
      <div className="loader-container">
        <div className="loader-icon"></div>
        {props.loadingMsg && <div className="loading-msg">{props.loadingMsg}</div>}
      </div>
    );
  }
  return <>{props.children || ""}</>;
}

function OverlayLoader(props) {
  if (props.loading) {
    return (
      <div className="overlay-loader-container">
        <Loader loading={props.loading} />
        {props.children || ""}
      </div>
    );
  }
  return <>{props.children || ""}</>;
}

export { Loader, OverlayLoader };
