import React, { useState } from "react";
import moment from "moment-timezone";
import { Modal, Form } from "react-bootstrap";
import "./MentalWellnessScore.scss";
import { SecondaryButton } from "@/components";

const getPhq9Severity = score => {
  let severity = "";
  if (score >= 0 && score <= 4) {
    severity = "low";
  } else if (score >= 5 && score <= 9) {
    severity = "low-medium";
  } else if (score >= 10 && score <= 14) {
    severity = "medium";
  } else if (score >= 15 && score <= 27) {
    severity = "high";
  }
  return severity;
};

const getGad7Severity = score => {
  let severity = "";
  if (score >= 0 && score <= 4) {
    severity = "low";
  } else if (score >= 5 && score <= 9) {
    severity = "low-medium";
  } else if (score >= 10 && score <= 14) {
    severity = "medium";
  } else if (score >= 15 && score <= 21) {
    severity = "high";
  }
  return severity;
};

const answers = ["Not at all", "Several Days", "More than half days", "Nearly every day"];

function MentalWellnessScore(props) {
  const { session, patientMentalWellnessData, groupMentalWellnessData } = props;
  const [showPhqModal, setShowPhqModal] = useState(false);
  const [showGadModal, setShowGadModal] = useState(false);
  let showScore = true;
  const sessionCategory = session?.sessionCategory || "";
  const patientId = session?.patientId || "";
  const groupId = session?.groupId || "";
  const patientMentalData = patientMentalWellnessData?.[patientId] || null;
  showScore = sessionCategory === "INDIVIDUAL";
  showScore = !!patientMentalData && (patientMentalData.PHQ9 || patientMentalData.GAD7);
  const sessionStartTime = session.startTime
    ? moment(session.startTime).tz(props.preferredTimezone?.name || "America/Los_Angeles")
    : null;
  showScore = !!sessionStartTime;
  const phqFormSubmissionTime = patientMentalData?.PHQ9?.submittedAt
    ? moment(patientMentalData.PHQ9.submittedAt).tz(
        props.preferredTimezone?.name || "America/Los_Angeles"
      )
    : null;
  const gadFormSubmissionTime = patientMentalData?.GAD7?.submittedAt
    ? moment(patientMentalData.GAD7.submittedAt).tz(
        props.preferredTimezone?.name || "America/Los_Angeles"
      )
    : null;
  let sessionSubmissionSameDay = false;
  if (sessionStartTime) {
    if (phqFormSubmissionTime) {
      sessionSubmissionSameDay =
        sessionStartTime.year() === phqFormSubmissionTime.year() &&
        sessionStartTime.month() === phqFormSubmissionTime.month() &&
        sessionStartTime.date() === phqFormSubmissionTime.date();
    }
    if (!sessionSubmissionSameDay) {
      if (gadFormSubmissionTime) {
        sessionSubmissionSameDay =
          sessionStartTime.year() === gadFormSubmissionTime.year() &&
          sessionStartTime.month() === gadFormSubmissionTime.month() &&
          sessionStartTime.date() === gadFormSubmissionTime.date();
      }
    }
  }
  showScore = !!sessionSubmissionSameDay;
  if (!showScore) {
    return <></>;
  }
  return (
    <div className="mental-wellness-score-container">
      {patientMentalData.PHQ9 && (
        <div
          className={`wellness-score-item ${getPhq9Severity(patientMentalData.PHQ9?.score)}`}
          onClick={() => setShowPhqModal(true)}
        >
          PHQ 9: {patientMentalData.PHQ9?.score}/27
        </div>
      )}
      {patientMentalData.GAD7 && (
        <div
          className={`wellness-score-item ${getGad7Severity(patientMentalData.GAD7?.score)}`}
          onClick={() => setShowGadModal(true)}
        >
          GAD 7: {patientMentalData.GAD7?.score}/21
        </div>
      )}
      <PHQ9DataModal
        data={patientMentalData.PHQ9}
        modalOpen={showPhqModal}
        toggleModal={setShowPhqModal}
        preferredTimezone={props.preferredTimezone}
        phq={true}
      />
      <PHQ9DataModal
        data={patientMentalData.GAD7}
        modalOpen={showGadModal}
        toggleModal={setShowGadModal}
        preferredTimezone={props.preferredTimezone}
      />
    </div>
  );
}

export default MentalWellnessScore;

const PHQ9DataModal = props => {
  const { data, phq } = props;
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="mental-wellness-data-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{phq ? "PHQ 9" : "GAD 7"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="score-container">
          <div className="score">
            Score: {data?.score}/{phq ? "27" : "21"}
          </div>
          <div className="submitted-at">
            Submitted on{" "}
            {data?.submittedAt
              ? `${moment(data.submittedAt)
                  .tz(props.preferredTimezone?.name || "America/Los_Angeles")
                  .format("MMMM DD, YYYY | h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`
              : ""}
          </div>
        </div>
        <div className="survey-form-title">Over the last 2 weeks, how often have you...</div>
        <div className="survey-form-question-list">
          <Form className="survey-form">
            {Object.keys(data?.questionAnswerMap || {})?.map((question, index) => {
              return (
                <Form.Group
                  className="survey-form-question-container"
                  controlId={question}
                  key={index}
                >
                  <Form.Label className="survey-form-question">
                    {index + 1}. {question}
                  </Form.Label>
                  <div className="survey-radio-list">
                    {answers.map((answer, index) => {
                      return (
                        <Form.Check
                          type="radio"
                          label={answer}
                          name={question}
                          id={`${question}-${answer}`}
                          key={index}
                          checked={data?.questionAnswerMap?.[question] === answer}
                          className="survey-radio-button"
                          readOnly={true}
                        />
                      );
                    })}
                  </div>
                </Form.Group>
              );
            })}
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton onClick={() => props.toggleModal(false)}>Close</SecondaryButton>
      </Modal.Footer>
    </Modal>
  );
};
