import React, { useEffect } from "react";
import NotesIcon from "@/assets/tasks/notes.svg";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import "./Tasks.scss";
import { Loader } from "@/components";
import session from "@/Routes/Session/sessionModule";

function Tasks(props) {
  const taskItems =
    props.tasks?.data
      ?.filter(task => props.tasks.tasksReady.includes(task?.id))
      ?.sort((session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)) ||
    [];
  useEffect(() => {
    props.getAllTaskStatus();
  }, []);
  useEffect(() => {
    let refreshInterval = setInterval(() => {
      const pendingItems = props.tasks.data.filter(
        task => !props.tasks.tasksReady.includes(task?.id)
      );
      if (pendingItems?.length > 0) {
        props.getAllTaskStatus({ dontShowLoader: true });
      }
    }, 30000);
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);
  return (
    <div className="dashboard-widget tasks-widget">
      <div className="widget-header">
        <div className="widget-title">
          Pending Tasks{" "}
          <div
            className="refersh-btn"
            onClick={() => props.getAllTaskStatus()}
          >
            Refresh Tasks
          </div>
        </div>
      </div>
      <div className="widget-content">
        <Loader loading={props.tasks.loading}>
          {taskItems.length > 0 ? (
            <div className="tasks-list">
              {taskItems.map(session => {
                const sessionTime = `${moment(session.startTime)
                  .tz(props.preferredTimezone?.name || "America/Los_Angeles")
                  .format("MMMM DD")}, ${moment(session.startTime)
                  .tz(props.preferredTimezone?.name || "America/Los_Angeles")
                  .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"} | ${
                  session.durationInMinutes
                } mins`;
                const isExternalSpeakerPhone = session.sessionName?.includes("Phone session");
                return (
                  <div
                    className="task-item"
                    key={session.id}
                  >
                    <div className="task-item-top">
                      <div className="task-icon notes">
                        <img
                          src={NotesIcon}
                          alt=""
                        />
                      </div>
                      <div className="task-text">Save & Approve Notes for the Session</div>
                    </div>
                    <div className="task-details">
                      <div className="task-session-time">{sessionTime}</div>
                      <div className="task-session-name">{session.sessionName}</div>
                      <div className="taskreview-notes-link">
                        <Link
                          className="task-session-details-link"
                          to={`/session-details/${
                            session.sessionCategory === "GROUP" ? "group" : "individual"
                          }/${session.modeOfDelivery === "VIRTUAL" ? "virtual" : "in-person"}/${
                            session.id
                          }/${
                            session.sessionCategory === "GROUP"
                              ? session.groupId
                              : session.patientId
                          }${isExternalSpeakerPhone ? "?externalSpeakerPhone=true" : ""}`}
                        >
                          Review Notes
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-tasks-found">No Pending Tasks</div>
          )}
        </Loader>
      </div>
    </div>
  );
}

export default Tasks;
