import AddGroupModal from "./AddGroupModal";
import { connect } from "react-redux";
import { addGroup, getGroups } from "../../groupsModule";
import { getPatients } from "@/Routes/Patients/patientsModule";
import { getOrgUsers } from "@/Routes/Users/usersModule";

const mapStateToProps = state => {
  return {
    patients: state.patients.patients,
    orgUsers: state.orgUsers.users,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPatients: options => dispatch(getPatients(options)),
    getOrgUsers: options => dispatch(getOrgUsers(options)),
    addGroup: options => dispatch(addGroup(options)),
    getGroups: options => dispatch(getGroups(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupModal);
