import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { SecondaryButton, Loader, PrimaryButton } from "@/components";
import SymptomTracker from "./components/SymptomTracker";
import "./BhPredictions.scss";

const getIntensityColor = (intensity, key) => {
  // let color = "#74dd74";
  let color = "low";
  let intensitySmallCase = intensity?.toLowerCase() || "";
  if (intensitySmallCase.includes("severe") || intensitySmallCase.includes("present")) {
    // color = "#FF5F05";
    color = "severe";
  } else if (
    intensitySmallCase.includes("moderate") ||
    (key === "emotional_distress" && intensitySmallCase.includes("high"))
  ) {
    // color = "#FF5F05";
    color = "high";
  } else if (
    intensitySmallCase.includes("mild") ||
    (key === "emotional_distress" && intensitySmallCase.includes("medium"))
  ) {
    // color = "#64C833";
    color = "medium";
  } else if (
    intensitySmallCase.includes("low") ||
    (key === "emotional_distress" && intensitySmallCase.includes("low"))
  ) {
    // color = "44C13C";
    color = "low";
  }
  return color;
};

function BhPredictions(props) {
  const [predictionsData, setPredictionsData] = useState("");
  const [generatePredictionsLoading, setGeneratePredictionsLoading] = useState(false);
  useEffect(() => {
    if (props.data) {
      let data = props.data;
      if (data && Object.keys(data).length > 0) {
        let anxietyParameters = data?.["gad-7"] || {};
        let depressionParameters = data?.["phq-9"] || {};
        anxietyParameters = Object.keys(anxietyParameters).map(parameter => {
          return {
            title: parameter,
            detected: anxietyParameters[parameter] > 0,
            intensity: anxietyParameters[parameter],
          };
        });
        depressionParameters = Object.keys(depressionParameters).map(parameter => {
          return {
            title: parameter,
            detected: depressionParameters[parameter] > 0,
            intensity: depressionParameters[parameter],
          };
        });
        let anxietyScore = anxietyParameters.reduce((result, i) => {
          return i.intensity + result;
        }, 0);
        let depressionScore = depressionParameters.reduce((result, i) => {
          return i.intensity + result;
        }, 0);
        let anxietyIntensityText =
          anxietyScore >= 15
            ? "Severe"
            : anxietyScore >= 10
            ? "Moderate"
            : anxietyScore >= 5
            ? "Mild"
            : "Low";
        let depressionIntensityText =
          depressionScore >= 20
            ? "Severe"
            : depressionScore >= 15
            ? "Moderately severe"
            : depressionScore >= 10
            ? "Moderate"
            : depressionScore >= 5
            ? "Mild"
            : "Low";
        let anxietyData = {
          parameters: anxietyParameters,
          score: anxietyScore,
          intensityText: anxietyIntensityText,
          cardColor: getIntensityColor(anxietyIntensityText),
        };
        let depressionData = {
          parameters: depressionParameters,
          score: depressionScore,
          intensityText: depressionIntensityText,
          cardColor: getIntensityColor(depressionIntensityText),
        };
        let emotionalDistress = {
          intensityText: data?.emotional_distress || "-",
          cardColor: getIntensityColor(data?.emotional_distress, "emotional_distress"),
        };
        let suicideRisk = {
          intensityText: data?.suicide_risk || "",
          cardColor: getIntensityColor(data?.suicide_risk),
        };
        let predictData = {
          anxiety: anxietyData,
          depression: depressionData,
          emotionalDistress,
          suicideRisk,
        };
        setPredictionsData(predictData);
      }
    }
  }, [props.data]);
  const generatePredictions = async () => {
    let sessionId = props.sessionId;
    let groupId = props?.groupId;
    let patientId = props?.patientId;
    let memberData = props.sessionData;
    let memberId = memberData?.patientId;
    let memberName = memberData?.patientFirstName;
    let memberEmail = memberData?.patientEmail || "";
    let sessionType = props.sessionType;
    let notesId = props.notesId;
    if (sessionId) {
      let transcriptData = props.transcriptData || [];
      let userTranscript = "";
      transcriptData.forEach(transcript => {
        let transcriptUserId = transcript.memberId;
        if (transcriptUserId) {
          if (
            transcript.memberId === memberId ||
            transcript.memberId?.toLowerCase() === memberEmail ||
            transcript.memberName?.toLowerCase() === "patient"
          ) {
            userTranscript += " " + transcript.transcriptText;
          }
        } else {
          if (memberName.toLowerCase().includes(transcript.memberName.toLowerCase())) {
            userTranscript += " " + transcript.transcriptText;
          }
        }
      });
      let payload = {
        transcript: userTranscript,
      };
      setGeneratePredictionsLoading(true);
      await props.generateBhPredictions({
        sessionId,
        groupId,
        patientId,
        payload,
        notesId,
      });
      setGeneratePredictionsLoading(false);
    }
  };
  const savePredictions = () => {
    let sessionId = props.sessionId;
    let groupId = props?.groupId;
    let patientId = props?.patientId;
    let notesId = props.notesId;
    props.saveBhPredictions({
      groupId,
      patientId,
      sessionId,
      bhPredictions: predictionsData,
      notesId,
    });
  };
  let LoaderComponent = generatePredictionsLoading ? ProgressLoader : Loader;
  return (
    <LoaderComponent loading={props.loading}>
      <div className="bh-predictions-container">
        {props.data ? (
          <div className="session-notes-predictions-container">
            <div className="prediction-cards-widget">
              <div className="widget-header">
                <div className="widget-title">Alerts</div>
              </div>
              <div className="prediction-cards-list">
                <div className={`prediction-card ${predictionsData?.emotionalDistress?.cardColor}`}>
                  {predictionsData?.emotionalDistress?.intensityText} Emotional Distress
                </div>
                <div className={`prediction-card ${predictionsData?.depression?.cardColor}`}>
                  {predictionsData?.depression?.intensityText} Depression Symptoms
                </div>
                <div className={`prediction-card ${predictionsData?.anxiety?.cardColor}`}>
                  {predictionsData?.anxiety?.intensityText} Anxiety Symptoms
                </div>
                <div className={`prediction-card ${predictionsData?.suicideRisk?.cardColor}`}>
                  Suicide Risk {predictionsData?.suicideRisk?.intensityText || "Not Present"}
                </div>
              </div>
            </div>

            <SymptomTracker
              data={predictionsData?.anxiety}
              type="anxiety"
            />
            <SymptomTracker
              data={predictionsData?.depression}
              type="depression"
            />
          </div>
        ) : (
          <div className="generate-soap-notes-container">
            <PrimaryButton onClick={generatePredictions}>
              Generate Behavioural Health Predictions
            </PrimaryButton>
          </div>
        )}
      </div>
    </LoaderComponent>
  );
}

export default BhPredictions;

const ProgressLoader = props => {
  return (
    <div className="progress-loader">
      <ProgressBar
        animated
        now={100}
      />
      <div className="loading-msg">Generating Predictions</div>
    </div>
  );
};
