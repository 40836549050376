import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { rawPost } from "@/services/api";
import { PrimaryButton } from "@/components";

import PasswordIcon from "@/assets/password.svg";
import PasswordHideIcon from "@/assets/password-hide.svg";

import "./ResetPassword.scss";

const ResetPassword = props => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [showEmailSent, setShowEmailSent] = useState(false);
  const [resetPasswordPage, setResetPasswordPage] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Reset Password";
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlEmail = urlParams.get("email");
    const urlToken = urlParams.get("token");
    if (urlEmail) {
      setEmail(urlEmail);
    }
    if (urlToken) {
      setToken(urlToken);
    }
    if (urlEmail && urlToken) {
      setResetPasswordPage(true);
    }
  }, []);

  const validateForm = () => {
    let valid = true;
    if (validateEmail()) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email");
      valid = false;
    }
    if (resetPasswordPage) {
      if (!email || !token || !password || !confirmPassword) {
        valid = false;
      }
    } else {
      if (!email) {
        valid = false;
      }
    }
    return valid;
  };
  const isButtonDisabled = () => {
    let disabled = false;
    if (resetPasswordPage) {
      if (!email || !password || !token || !confirmPassword || emailError || passwordError) {
        disabled = true;
      }
    } else {
      if (!email || emailError) {
        disabled = true;
      }
    }
    return disabled;
  };
  const validateEmail = () => {
    return email
      ? String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      : true;
  };
  const onEmailSubmit = async () => {
    let url = `registration/practitioner/forget-password`;
    let payload = {
      email,
    };
    setLoading(true);
    setLoginError("");
    try {
      let response = await rawPost(url, payload);
      setLoading(false);
      if (response?.data) {
        setShowEmailSent(true);
      } else {
        setLoginError("User not found");
      }
    } catch (error) {
      setLoading(false);
      setLoginError("User not found");
    }
  };
  const onResetPasswordSubmit = async () => {
    let url = `registration/practitioner/reset-password`;
    let payload = {
      email,
      password,
      confirmationToken: token,
    };
    setLoading(true);
    setLoginError("");
    try {
      let response = await rawPost(url, payload);
      setLoading(false);
      navigate("/login", { replace: true });
    } catch (error) {
      console.log(error);
      setLoginError("Invalid URL, please check the reset password link from the email");
      setLoading(false);
    }
  };
  if (!resetPasswordPage) {
    return (
      <div className="reset-password-page">
        <div className="form-wrapper">
          <div className="form-container">
            <div className="form-title">Trouble with logging in?</div>
            <div className="form-sub-title">
              Enter your email address and we'll send you a link to get back into your account.
            </div>
            <Form className="custom-form">
              <Form.Group
                className="custom-input-field"
                controlId="register-email-input"
              >
                <Form.Control
                  type="email"
                  placeholder="Email ID"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  isInvalid={!!emailError}
                  onBlur={validateForm}
                  disabled={!!loading}
                />
                {emailError && (
                  <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
                )}
              </Form.Group>
              {loginError && <div className="login-error">{loginError}</div>}
              {showEmailSent && (
                <div className="send-invite-link-success">
                  We've sent an email to {email} with a link to reset your password.
                </div>
              )}
              <PrimaryButton
                className="form-submit-btn"
                disabled={!!isButtonDisabled() || !!loading}
                onClick={onEmailSubmit}
              >
                Send Password Reset Link
              </PrimaryButton>
            </Form>
            <div className="sign-in-prompt">
              <Link
                to={"/login"}
                className="colored-link"
              >
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="reset-password-page">
        <div className="form-wrapper">
          <div className="form-container">
            <div className="form-title">Set a new password</div>
            <Form className="custom-form">
              <Form.Group
                className="custom-input-field"
                controlId="register-email-input"
              >
                <Form.Control
                  type="email"
                  placeholder="Email ID"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  isInvalid={!!emailError}
                  onBlur={validateForm}
                  disabled={true}
                  readOnly
                />
                <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="custom-input-field with-password"
                controlId="register-password-input"
              >
                <Form.Control
                  type={hidePassword ? "password" : "text"}
                  placeholder="Create Password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  onBlur={validateForm}
                  disabled={!!loading}
                />
                <img
                  src={hidePassword ? PasswordHideIcon : PasswordIcon}
                  alt=""
                  className="password-hide-icon"
                  onClick={() => setHidePassword(hidePassword => !hidePassword)}
                />
              </Form.Group>
              <Form.Group
                className="custom-input-field with-password"
                controlId="register-confirm-password-input"
              >
                <Form.Control
                  type={hidePassword ? "password" : "text"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  isInvalid={!!passwordError}
                  onBlur={validateForm}
                  disabled={!!loading}
                />
                <img
                  src={hidePassword ? PasswordHideIcon : PasswordIcon}
                  alt=""
                  className="password-hide-icon"
                  onClick={() => setHidePassword(hidePassword => !hidePassword)}
                />
                <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
              </Form.Group>
              {!!loginError && <div className="signup-error">{loginError}</div>}
              <PrimaryButton
                className="form-submit-btn"
                disabled={!!isButtonDisabled() || !!loading}
                onClick={onResetPasswordSubmit}
              >
                {!loading ? "Submit" : "Submitting..."}
              </PrimaryButton>
            </Form>
            <div className="sign-in-prompt">
              <Link
                to={"/login"}
                className="colored-link"
              >
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ResetPassword;
