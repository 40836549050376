import OrgRoleLayout from "./OrgRoleLayout";
import { connect } from "react-redux";
import { selectUserRole } from "@/store/modules/userModule";

const mapStateToProps = state => {
  return {
    userData: state.user.userData,
    userRoles: state.user.userRoles,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectUserRole: selectedRole => dispatch(selectUserRole(selectedRole)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgRoleLayout);
